import React from 'react';

import Modal from '../../../../components/Modal/Modal';

interface PopupProps {
  closeAction: () => void;
}

const Popup: React.FC<PopupProps> = (props) => {
  const { closeAction } = props;

  return (
    <Modal onAction={closeAction}>
      <div className="text-primary center">
        <p>A valid FEIN/SSN is required to purchase coverage.</p>
      </div>
    </Modal>
  );
};

export default Popup;
