import 'react-phone-input-2/lib/style.css';
import './Customer.css';

import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import Input from '../Input/Input';
import { CustomerData } from './CustomerTypes';

interface CustomerProps {
  value?: CustomerData;
  updateCustomer: (value: CustomerData) => void;
}

const Customer: React.FC<CustomerProps> = (props) => {
  const { value, updateCustomer } = props;

  const defaultFirst = value?.firstName || '';
  const defaultLast = value?.lastName || '';
  const defaultEmail = value?.email || '';
  const defaultPhoneNumber = value?.phoneNumber || '';
  const defaultCustomer = {
    firstName: defaultFirst,
    lastName: defaultLast,
    email: defaultEmail,
    phoneNumber: defaultPhoneNumber
  };

  const [customer, setCustomer] = useState(defaultCustomer);

  const updateNewCustomer = (newCustomer: CustomerData) => {
    setCustomer(newCustomer);
    updateCustomer(newCustomer);
  };

  return (
    <div className="Customer" data-testid="Customer">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 place-content-center">
        <Input
          placeHolder="First Name"
          value={customer.firstName}
          onChange={(newValue) => {
            const newCustomer = {
              ...customer,
              firstName: newValue
            };
            updateNewCustomer(newCustomer);
          }}
        />
        <Input
          placeHolder="Last Name"
          value={customer.lastName}
          onChange={(newValue) => {
            const newCustomer = {
              ...customer,
              lastName: newValue
            };
            updateNewCustomer(newCustomer);
          }}
        />
        <Input
          placeHolder="Email"
          value={customer.email}
          onChange={(newValue) => {
            const newCustomer = {
              ...customer,
              email: newValue
            };
            updateNewCustomer(newCustomer);
          }}
          type="email"
        />
        <PhoneInput
          value={customer.phoneNumber}
          onlyCountries={['ca', 'us']}
          country={'us'}
          buttonClass="phone-number-button bg-secondary px-2 mt-4 rounded-tl-2xl rounded-bl-2xl"
          containerClass="input-field-container"
          onChange={(e) => {
            const newCustomer = {
              ...customer,
              phoneNumber: e
            };
            updateNewCustomer(newCustomer);
          }}
        />
      </div>
    </div>
  );
};

export default Customer;
