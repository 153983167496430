/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './Quote.css';

import {
  VersionControllerClient,
  WorkflowVersion
} from '@foxden/version-controller-client';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { RatingQuotingClient } from '../../backend-client/ratingQuotingBackend';
import Loading from '../../components/Loading/Loading';
import {
  AmtrustEligibleQuoteResponse,
  AmtrustIneligibleQuoteResponse,
  GetQuoteErrorType,
  GetQuoteExpired,
  MunichEligibleQuoteResponse,
  MunichIneligibleQuoteResponse,
  Quote as QuoteType,
  QuoteInfo,
  QuoteResponse
} from '../../generated/rating-quoting-graphql';
import getEnv from '../../utils/getEnv';
import { Context, ContextValue } from '../../utils/store';
import Quote from './Quote';

interface QuoteParams {
  applicationId: string;
}

interface Option {
  text: string;
  value: string;
}

export interface CoveragesDataInput {
  name: string;
  chooseCoverage: boolean;
  finalPremium: number;
  limits: Record<string, number>;
  limitsOptions?: Record<string, Array<Option>>;
}

const { REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL } = getEnv();

export const QuotePage: React.FC = () => {
  const context: ContextValue | null = useContext(Context);

  const { applicationId } = useParams<QuoteParams>();

  const [versionData, setVersionData] = useState<WorkflowVersion>();
  const [versionDataError, setVersionDataError] = useState();

  const [
    quoteDataFromRatingQuoting,
    setQuoteDataFromRatingQuoting
  ] = useState<QuoteResponse>();
  const [
    quoteDataFromRatingQuotingError,
    setQuoteDataFromRatingQuotingError
  ] = useState();

  useEffect(() => {
    const versionControllerClient = new VersionControllerClient(
      REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
    );
    versionControllerClient
      .getVersionFromApplication(applicationId)
      .then((data) => {
        setVersionData(data);
      })
      .catch((err) => {
        setVersionDataError(err);
      });
  }, [applicationId]);

  useEffect(() => {
    if (versionData) {
      const ratingQuotingClient = new RatingQuotingClient(
        versionData.quoteBackendVersion
      );
      ratingQuotingClient
        .getQuote(applicationId)
        .then((data) => setQuoteDataFromRatingQuoting(data))
        .catch((err) => setQuoteDataFromRatingQuotingError(err));
    }
  }, [applicationId, versionData]);

  if (quoteDataFromRatingQuotingError || versionDataError) {
    return (
      <div className="m-auto mt-20 error-bubble text-mobileFont md:text-base">
        Sorry! It is not you, it is us. We will get in touch with you to
        retrieve your quote.
      </div>
    );
  }

  if (!versionData) {
    return <Loading />;
  }

  if (!quoteDataFromRatingQuoting) {
    return <Loading />;
  }

  const quotes = quoteDataFromRatingQuoting!;

  let amtrustQuote:
    | AmtrustEligibleQuoteResponse
    | AmtrustIneligibleQuoteResponse
    | undefined = undefined;
  let munichQuote:
    | MunichEligibleQuoteResponse
    | MunichIneligibleQuoteResponse
    | undefined = undefined;
  let returnToQuoteExpired: GetQuoteExpired | undefined = undefined;

  let quoteInfo: QuoteInfo | undefined = undefined;

  if (quotes.__typename === 'GetQuoteOk') {
    quoteInfo = quotes.quoteInfo;
    quotes.quotes.forEach((quoteResponse: QuoteType) => {
      switch (quoteResponse.__typename) {
        case 'AmtrustEligibleQuoteResponse': // fall-through
        case 'AmtrustIneligibleQuoteResponse':
          amtrustQuote = quoteResponse;
          break;
        case 'MunichEligibleQuoteResponse': // fall-through
        case 'MunichIneligibleQuoteResponse':
          munichQuote = quoteResponse;
          break;
        default:
          throw new Error('Unreachable - unknown quote response');
      }
    });
  } else if (quotes.__typename === 'GetQuoteExpired') {
    returnToQuoteExpired = quotes;
  } else if (
    quotes.__typename === 'GetQuoteError' &&
    quotes.error === GetQuoteErrorType.DeclinedQuote
  ) {
    return <Redirect to="/complete" />;
  } else {
    throw new Error('GetQuote returned error');
  }

  if (context && quoteInfo) {
    const { setProgress, setHeaderTitle } = context;
    setHeaderTitle(quoteInfo?.companyName);
    setProgress(100);
  }

  return (
    <Quote
      munichQuote={munichQuote}
      amtrustQuote={amtrustQuote}
      applicationId={applicationId}
      returnToQuoteExpired={returnToQuoteExpired}
      quoteInfo={quoteInfo}
      versionData={versionData}
    />
  );
};

export default QuotePage;
