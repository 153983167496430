import React, { Fragment } from 'react';

import {
  AmtrustEligibleQuoteResponse,
  AmtrustIneligibleQuoteResponse
} from '../../../../generated/graphql';

interface AmtrustSummaryProps {
  amtrustSummaryInput?:
    | AmtrustEligibleQuoteResponse
    | AmtrustIneligibleQuoteResponse;
  amTrustFullFlow: boolean;
}

export const getAmtrustSummary: React.FC<AmtrustSummaryProps> = (props) => {
  const { amtrustSummaryInput, amTrustFullFlow } = props;

  const amtrustSummary =
    amTrustFullFlow &&
    amtrustSummaryInput?.__typename === 'AmtrustEligibleQuoteResponse' ? (
      <div>
        <p className="mt-2 mb-1 text-base md:text-lg ">
          Amtrust Quote Number - {amtrustSummaryInput.amtrustquoteId}
        </p>
        <Fragment>
          <h2 className="text-navy mb-1 text-base md:text-lg ">
            <b>Worker Compensation</b>
          </h2>
          <div className="mb-2">
            <div className="flex justify-between">
              <b className="text-navy  ml-2 text-base md:text-lg ">
                &bull; Worker Compensation
              </b>
              <span className="text-lg mx-2 ml-0">
                {amtrustSummaryInput.premium === 0 ? (
                  <Fragment>No Coverage</Fragment>
                ) : (
                  <Fragment>
                    <b className="text-xl font-black">
                      ${amtrustSummaryInput.premium.toLocaleString('en')}
                    </b>
                    /year
                  </Fragment>
                )}
              </span>
            </div>

            <p className="text-navy text-sm md:text-base ml-5">
              <Fragment>{`Overall Illness: $${amtrustSummaryInput.limit.overallIllness.toLocaleString(
                'en'
              )}`}</Fragment>
            </p>
            <p className="text-navy text-sm md:text-base ml-5">
              <Fragment>{`Per Accident: $${amtrustSummaryInput.limit.perAccident.toLocaleString(
                'en'
              )}`}</Fragment>
            </p>
            <p className="text-navy text-sm md:text-base ml-5">
              <Fragment>{`Per Employee Illness: $${amtrustSummaryInput.limit.perEmployeeIllness.toLocaleString(
                'en'
              )}`}</Fragment>
            </p>
            <button
              className="text-secondary py-1.5 px-2 text-base underline"
              onClick={() => {
                window.alert(amtrustSummaryInput.premium);
                return 1;
              }}
            >
              <b>Remove</b>
            </button>
          </div>
        </Fragment>
      </div>
    ) : null;
  return amtrustSummary;
};
