interface ExceptionConfig {
  percentageName: string;
  workExclusionList: string;
  convertedISO: string;
}

export const exceptionRules: Record<string, ExceptionConfig[]> = {
  '98305': [
    {
      percentageName: 'exteriorWorkPercentage',
      workExclusionList: '69-66-29-55-60-73-49-88',
      convertedISO: '98304'
    }
  ],
  '96816': [
    {
      percentageName: 'residentialWorkPercentage',
      workExclusionList: '69-8',
      convertedISO: '47367'
    },
    {
      percentageName: 'exteriorWorkPercentage',
      workExclusionList: '69-29-55-58-59-73-88',
      convertedISO: '91523'
    }
  ],
  '98483': [
    {
      percentageName: 'commercialWorkPercentage',
      workExclusionList: '69-86-66-57-78-48-77-49',
      convertedISO: '98432'
    }
  ]
};
