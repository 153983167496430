//TODO: move to foxden-version-controller-client or new repo
import { GraphQLClient } from 'graphql-request';

import { UpdateCoverageInput } from '../generated/graphql';
import {
  getSdk,
  QuoteResponse,
  ResetCoverageRecommendationInput,
  Sdk,
  UpdateCoverageOutput
} from '../generated/rating-quoting-graphql';
import getEnv from '../utils/getEnv';

export class RatingQuotingClient {
  sdk: Sdk;

  constructor(versionNumber: string) {
    const graphQLClient = new GraphQLClient(
      this.getRatingQuotingURL(versionNumber)
    );
    this.sdk = getSdk(graphQLClient);
  }

  /**
   * Returns a SDK of rating-quoting backend.
   * @returns a sdk that has all APIs of rating-quoting backend.
   */
  public getSDK(): Sdk {
    return this.sdk;
  }

  /**
   * getQuote
   * @param applicationId: the objectId of the application to get the workflowVersion of
   * @returns a Promise for a QuoteResponse object
   */
  public async getQuote(applicationId: string): Promise<QuoteResponse> {
    const result = await this.sdk.getQuote({ applicationId });
    return result.getQuote;
  }

  /**
   * generateQuotePdf
   * @param quoteId: the objectId of latest quote
   * @returns a Promise for pdf Buffer
   */
  public async generateQuotePdf(quoteId: string): Promise<string> {
    const result = await this.sdk.generateQuotePdf({ quoteId });
    return result.generateQuotePdf;
  }

  /**
   * resetCoverageRecommendation
   * @param input: the coverage input of the quote which needed to be reset.
   * @returns a Promise for a UpdateCoverageOutput object
   */
  public async resetCoverageRecommendation(
    input: ResetCoverageRecommendationInput
  ): Promise<UpdateCoverageOutput> {
    const result = await this.sdk.resetCoverageRecommendation({ input });
    return result.resetCoverageRecommendation;
  }

  /**
   * updateCoverage
   * @param input: the coverage input of the quote which needed to be updated.
   * @returns a Promise for a QuoteResponse object
   */
  public async updateCoverage(
    input: UpdateCoverageInput
  ): Promise<UpdateCoverageOutput> {
    const result = await this.sdk.updateCoverage({ input });
    return result.updateCoverage;
  }

  private getRatingQuotingURL(versionNumber: string): string {
    const { REACT_APP_RATING_QUOTING_GRAPHQL_URL } = getEnv();

    // regex to replace version number in url
    const regex = /\#\{[^}]+\}/g;
    return REACT_APP_RATING_QUOTING_GRAPHQL_URL.replace(regex, versionNumber);
  }
}
