import { assign, omit } from 'lodash';
import React, { useState } from 'react';

import { formatCADCentsOptional } from '../../../../utils/currencyFormat';
import { limitsLabels } from './CoveragesDetails';

interface AdditionalCoveragesProps {
  limits: Record<string, number>;
  carrierPartner: string;
}

export const AdditionalCoverages: React.FC<AdditionalCoveragesProps> = ({
  carrierPartner,
  limits
}) => {
  // TODO: Move some of these hardcoded CGL limit information to the backend
  const additionalCGLLimitsStart = {
    productAggregateLimit: limits['aggregateLimit'],
    personalAdvertisingInjury: limits['occurrenceLimit']
  };

  const additionalCGLLimitsEnd = {
    productAggregateLimit: limits['aggregateLimit'],
    personalAdvertisingInjury: limits['occurrenceLimit'],
    blanketAdditionalInsured: 'INCLUDED',
    blanketWaiverOfSubrogation: 'INCLUDED',
    ...(carrierPartner === 'StateNational' && {
      primaryAndNonContributory: 'INCLUDED'
    })
  };

  const duplicateLimits = ['aggregateLimit', 'occurrenceLimit', 'deductible'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredAdditionalLimitsObject: Record<string, any> = assign(
    additionalCGLLimitsStart,
    assign(omit(limits, duplicateLimits), additionalCGLLimitsEnd)
  );

  const [displayAdditionalCoverage, setDisplayAdditionalCoverage] = useState(
    false
  );
  return (
    <div>
      <div
        className="inline text-secondary font-semibold cursor-pointer border-b border-secondary"
        onClick={() => setDisplayAdditionalCoverage(!displayAdditionalCoverage)}
      >
        See More Coverage Information{' '}
        {displayAdditionalCoverage ? (
          <svg
            className="fill-secondary inline h-5 mb-1 MuiSelect-iconOpen"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
        ) : (
          <svg
            className="fill-secondary inline h-5 mb-1"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
        )}
      </div>
      {displayAdditionalCoverage
        ? Object.keys(filteredAdditionalLimitsObject).map((limit, index) => (
            <div
              key={index}
              className="mt-1 w-full grid grid-cols-2 md:grid-cols-3"
            >
              <div className="font-medium">
                {filteredAdditionalLimitsObject[limit] !== 'INCLUDED'
                  ? formatCADCentsOptional.format(
                      filteredAdditionalLimitsObject[limit]
                    )
                  : filteredAdditionalLimitsObject[limit]}
              </div>
              <div className="font-semibold md:col-span-2">
                {limitsLabels[limit]}
              </div>
            </div>
          ))
        : null}
    </div>
  );
};
