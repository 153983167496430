import React from 'react';
import * as Survey from 'survey-core';

import Input from './Input';
import { InputQuestionType } from './InputTypes';

const customInputWidget = {
  name: 'custominput',
  title: 'Custom Input',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'custominput';
  },
  activatedByChanged: (): void => {
    Survey.Serializer.addProperties('question', [
      {
        name: 'useSeparator:boolean',
        default: true,
        category: 'Custom Input'
      },
      {
        name: 'allowNegative:boolean',
        default: false,
        category: 'Custom Input'
      },
      {
        name: 'useDollarSignPrefix:boolean',
        default: false,
        category: 'Custom Input'
      }
    ]);
    Survey.JsonObject.metaData.addClass('custominput', [], undefined, 'text');
  },
  isDefaultRender: false,
  htmlTemplate: '<div><span><input /></span></div>',
  render: (question: InputQuestionType): React.ReactElement<typeof Input> => {
    const onChange = (newValue: string) => {
      question.value = newValue;
    };
    const {
      leftLabel,
      placeHolder,
      name,
      inputType,
      value,
      helperText,
      useSeparator,
      allowNegative,
      useDollarSignPrefix,
      readOnly,
      description
    } = question;
    return (
      <Input
        leftLabel={leftLabel}
        description={description}
        placeHolder={placeHolder}
        name={name}
        onChange={onChange}
        type={inputType}
        value={value}
        helperText={helperText}
        useSeparator={useSeparator}
        allowNegative={allowNegative}
        useDollarSignPrefix={useDollarSignPrefix}
        readOnly={readOnly}
      />
    );
  }
};

export default customInputWidget;
