import { CustomWidgetCollection, settings } from 'survey-core';

import addressWidget from '../../components/Address';
import coverageOptionWidget from '../../components/CoverageOption';
import customerWidget from '../../components/Customer';
import datePickerWidget from '../../components/DatePicker';
import customInputWidget from '../../components/Input';
import searchSelecttWidget from '../../components/SearchSelect';
import customSelectInputWidget from '../../components/SelectInput';
import customToggleButtonWidget from '../../components/ToggleButton';

// set case sensitivity as true
settings.comparator.caseSensitive = true;

// Register our widget in singleton custom widget collection
CustomWidgetCollection.Instance.addCustomWidget(
  customInputWidget,
  'customtype'
);
CustomWidgetCollection.Instance.addCustomWidget(
  customSelectInputWidget,
  'customtype'
);
CustomWidgetCollection.Instance.addCustomWidget(addressWidget, 'customtype');
CustomWidgetCollection.Instance.addCustomWidget(customerWidget, 'customtype');
CustomWidgetCollection.Instance.addCustomWidget(
  coverageOptionWidget,
  'customtype'
);
CustomWidgetCollection.Instance.addCustomWidget(
  customToggleButtonWidget,
  'customtype'
);
CustomWidgetCollection.Instance.addCustomWidget(
  searchSelecttWidget,
  'customtype'
);
CustomWidgetCollection.Instance.addCustomWidget(datePickerWidget, 'customtype');
