import './Summary.css';

import React from 'react';

import {
  AmtrustEligibleQuoteResponse,
  AmtrustIneligibleQuoteResponse,
  MunichEligibleQuoteResponse,
  MunichIneligibleQuoteResponse
} from '../../../../generated/graphql';
import { countDecimals } from '../../../../utils/countDecimals';
import {
  formatCADCentsOptional,
  formatCADCentsOptionalWithTwoDecimals
} from '../../../../utils/currencyFormat';
import { useFlags } from '../../../../utils/useFlags';
import { getAmtrustSummary } from './getAmtrustSummary';
import { getMunichSummary } from './getMunichSummary';

interface SummaryProps {
  munichSummaryInput?:
    | MunichEligibleQuoteResponse
    | MunichIneligibleQuoteResponse;
  amtrustSummaryInput?:
    | AmtrustEligibleQuoteResponse
    | AmtrustIneligibleQuoteResponse;
  yearlyPremium: number;
  totalPayable: number;
  isEndorsement: boolean;
  carrierPartner?: string;
}

// Keep the comment out code for future use
const Summary: React.FC<SummaryProps> = (props) => {
  const {
    amtrustSummaryInput,
    munichSummaryInput,
    yearlyPremium,
    totalPayable,
    isEndorsement,
    carrierPartner
  } = props;

  const { amTrustFullFlow } = useFlags();

  if (!amTrustFullFlow && !munichSummaryInput) {
    throw Error('Munich coverages must be selected'); //TODO for now
  }

  const taxMsg = (() => {
    if (munichSummaryInput?.__typename === 'MunichEligibleQuoteResponse') {
      const { region } = munichSummaryInput;

      return region === 'Canada' || isEndorsement
        ? 'Taxes and Fees are not included'
        : 'Including Taxes and Fees';
    }
    return 'Including Taxes and Fees';
  })();

  const munichSummary = getMunichSummary({
    munichSummaryInput,
    isEndorsement,
    carrierPartner
  });

  const amtrustSummary = getAmtrustSummary({
    amtrustSummaryInput,
    amTrustFullFlow
  });

  // assume yearlyPremium and totalPayable have at most 2 decimal places
  const total = (() => {
    const finalTotal =
      munichSummaryInput?.region === 'Canada' ? yearlyPremium : totalPayable;
    return countDecimals(finalTotal) > 0
      ? formatCADCentsOptionalWithTwoDecimals.format(finalTotal)
      : formatCADCentsOptional.format(finalTotal);
  })();

  return (
    <div className="summary-container lg:max-w-xs text-left border-b-2 border-gray-400 md:border-0">
      <p className="text-xl md:text-2xl ">
        <b>Summary:</b>
      </p>
      <dl className="summary-details overflow-hidden md:overflow-auto max-h-full border-b-2 border-gray-400 border-dashed md:border-solid">
        {amtrustSummary}
        {munichSummary}
      </dl>
      <p className="mt-4">
        <b className="text-lg md:text-2xl">Total:</b>
        <span className="float-right px-4 text-lg md:text-lg ">
          <b className="font-black text-2xl">{total}</b>
          /year
        </span>
      </p>
      <p className="inline-block w-full text-xs text-right font-semibold mb-2">
        {taxMsg}
      </p>
    </div>
  );
};

export default Summary;
