import React from 'react';
import * as Survey from 'survey-core';

import CustomDatePicker from './DatePicker';
import { DatePickerTypes } from './DatePickerTypes';

const datePickerWidget = {
  name: 'datepicker',
  title: 'Date Picker',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'datepicker';
  },
  activatedByChanged: (): void => {
    Survey.Serializer.addProperties('question', [
      {
        name: 'minDate:dropdown',
        choices: ['yesterday', 'today', 'tomorrow'],
        category: 'Date Picker'
      },
      {
        name: 'maxDate:dropdown',
        choices: ['yesterday', 'today', 'tomorrow'],
        category: 'Date Picker'
      }
    ]);
    Survey.JsonObject.metaData.addClass('datepicker', [], undefined, 'text');
  },
  isDefaultRender: false,
  htmlTemplate: '<div><span><input /></span></div>',
  render: (
    question: DatePickerTypes
  ): React.ReactElement<typeof CustomDatePicker> => {
    const onChange = (newValue: Date | null) => {
      question.value = newValue;
    };
    const {
      leftLabel,
      rightLabel,
      name,
      value,
      minDate,
      maxDate,
      helperText,
      readOnly
    } = question;
    return (
      <CustomDatePicker
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        name={name}
        onChange={onChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        helperText={helperText}
        readOnly={readOnly}
      />
    );
  }
};

export default datePickerWidget;
