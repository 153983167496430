import './DatePicker.css';

import DateFnsUtils from '@date-io/date-fns';
import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { addDays, subDays } from 'date-fns';
import React, { useState } from 'react';

import { DateOption } from './DatePickerTypes';

type IDateMapper = Record<DateOption, Date>;

const getDateMapper = (): IDateMapper => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const tomorrow = addDays(today, 1);

  return {
    today,
    yesterday,
    tomorrow
  };
};

const materialTheme = createTheme({
  palette: {
    primary: { main: '#005683' }
  }
});

interface DatePickerProps {
  leftLabel?: string;
  rightLabel?: string;
  minDate?: DateOption | Date;
  maxDate?: DateOption | Date;
  helperText?: string;
  readOnly?: boolean;
  value?: Date;
  name: string;
  inputClass?: string;
  onChange: (value: Date | null) => void;
  id?: string;
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  leftLabel,
  rightLabel,
  minDate,
  maxDate,
  helperText,
  readOnly,
  value,
  name,
  inputClass,
  onChange,
  id
}) => {
  let min;
  if (minDate instanceof Date) {
    min = minDate;
  } else {
    min = minDate ? getDateMapper()[minDate] : undefined;
  }
  let max;
  if (maxDate instanceof Date) {
    max = maxDate;
  } else {
    max = maxDate ? getDateMapper()[maxDate] : undefined;
  }

  // selected date
  const [selectedDate, setSelectedDate] = useState(value || null);

  const [openPicker, setOpenPicker] = useState(false);

  return (
    <div
      className="DatePicker input-field-container flex flex-col overflow-hidden"
      data-testid="DatePicker"
    >
      <div className="mb-2">
        {leftLabel || ''}
        {helperText ? (
          <Tooltip
            title={helperText}
            arrow
            classes={{
              arrow: 'text-tertiary',
              tooltip: 'text-sm bg-tertiary'
            }}
          >
            <div className="help-button">?</div>
          </Tooltip>
        ) : null}
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            id={id}
            className={`input-field ${inputClass ? inputClass : ''}`}
            name={name}
            value={selectedDate}
            minDate={min}
            maxDate={max}
            format="yyyy-MM-dd"
            onChange={(date) => {
              setSelectedDate(date);
              onChange(date);
            }}
            readOnly={readOnly}
            open={openPicker}
            onClick={() => setOpenPicker(true)}
            onClose={() => setOpenPicker(false)}
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
      {rightLabel || ''}
    </div>
  );
};

export default CustomDatePicker;
