import './Premium.css';

import React, { useState } from 'react';

import { UserPaymentPlanOptionByQuoteId } from '../../../../generated/payment-graphql';
import {
  formatCADCentsOptional,
  formatCADCentsOptionalWithTwoDecimals
} from '../../../../utils/currencyFormat';
import Boolean from './PremiumSwitch';

interface PremiumInput {
  isEndorsement: boolean;
  yearlyPremium: number;
  paymentOptionData?: Array<UserPaymentPlanOptionByQuoteId>;
}

const getMaxMonthlyPaymentOptionObject = (
  paymentOptionList: UserPaymentPlanOptionByQuoteId[]
) => {
  const maxPaymentOptionObject = paymentOptionList?.reduce(
    (maxPaymentOptionObject, paymentOption) => {
      if (!maxPaymentOptionObject) {
        return paymentOption;
      }
      return paymentOption.numberOfInstallments >
        maxPaymentOptionObject.numberOfInstallments
        ? paymentOption
        : maxPaymentOptionObject;
    }
  );
  // Do not return yearly option
  if (maxPaymentOptionObject?.numberOfInstallments === 0) {
    return undefined;
  }
  return maxPaymentOptionObject;
};

const showYearlyPremium = (yearlyPremium: number, isEndorsement: boolean) => (
  <b className="font-arial text-6xl text-quartary sm:text-5xl pt-2">
    {formatCADCentsOptional.format(yearlyPremium)}
    <p className="text-xl sm:text-sm font-medium">
      {isEndorsement ? 'Premium Change' : 'Annual Premium'}
    </p>
  </b>
);

const showMonthlyPremium = (maxObj: UserPaymentPlanOptionByQuoteId) => {
  const monthlyPremium = maxObj.installmentAmount;
  const dueAmount = maxObj.downPayment;
  const monthInstall = maxObj.numberOfInstallments;
  const monthlyPremiumDisplayValue = formatCADCentsOptionalWithTwoDecimals.format(
    monthlyPremium
  );
  const [
    monthlyPremiumInteger,
    monthlyPremiumDecimals
  ] = monthlyPremiumDisplayValue.split('.');
  return (
    <b className="text-quartary text-5xl md:text-6xl ">
      <span className="font-arial">{monthlyPremiumInteger}</span>
      <sup className="font-arial text-3xl md:text-4xl font-medium">{`.${monthlyPremiumDecimals}`}</sup>
      <p className="text-sm md:text-xl">Per Month</p>
      <p className="mt-2 text-primary text-xs md:text-sm font-medium">
        {monthInstall + ' monthly installments'}
      </p>
      <p className="text-primary text-xs md:text-sm font-medium">
        {'Due now: ' + formatCADCentsOptionalWithTwoDecimals.format(dueAmount)}
      </p>
    </b>
  );
};

const Premium: React.FC<PremiumInput> = (props) => {
  const { yearlyPremium, isEndorsement, paymentOptionData } = props;

  const maxObj =
    paymentOptionData && getMaxMonthlyPaymentOptionObject(paymentOptionData);

  const [isMonth, setPeriod] = useState<boolean>(true);

  return (
    <div className="premium-container w-full m-auto p-auto">
      <div className="relative h-full">
        <div className="mt-2">
          {maxObj && !isEndorsement && (
            <>
              <b className={isMonth ? 'text-quartary' : 'text-faded'}>
                Monthly
              </b>
              <Boolean
                id="TogglePaymentPeriod"
                onChange={() => {
                  setPeriod(!isMonth);
                }}
                value={!isMonth}
              ></Boolean>
              <b className={!isMonth ? 'text-quartary' : 'text-faded'}>
                Yearly
              </b>
              <br />
            </>
          )}
          {maxObj && isMonth && !isEndorsement
            ? showMonthlyPremium(maxObj)
            : showYearlyPremium(yearlyPremium, isEndorsement)}
        </div>
      </div>
    </div>
  );
};

export default Premium;
