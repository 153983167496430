import {
  Model,
  PanelModel,
  Question,
  QuestionHtmlModel,
  QuestionRadiogroupModel,
  SurveyModel
} from 'survey-core';

import { APOLLO_CLIENT } from '../../../apolloClient';
import {
  Rule,
  TryGetWcQuestionsDocument,
  TryGetWcQuestionsQuery,
  TryGetWcQuestionsQueryVariables,
  TryGetWcQuestionsStatus,
  useStartWcMutation
} from '../../../generated/graphql';
import { US } from '../../../utils/address/province';
import { Flags, useFlags } from '../../../utils/useFlags';
import { getAmtrustOfficerPanel, getDyanmicQuestionPanel } from '../util';
import { toAmtrustBusinessType } from './toAmtrustBusinessType';

type WcHelperFn = () => Promise<void>;

export function useInjectWcQuestions(
  model: SurveyModel,
  applicationId: string
): WcHelperFn {
  const { amTrustFullFlow } = useFlags();
  const [startWc] = useStartWcMutation();

  // set up a closure

  return async () => {
    const { currentPage } = model;

    if (currentPage.name !== 'AmtrustWC') {
      return;
    }

    const hasErrors = currentPage.hasErrors();

    if (hasErrors) {
      return;
    }

    const usState = model.getVariable('UsState');

    if (!US.isShortName(usState)) {
      // note: you can't get the AmtrustWC page if the lead isn't filling a US application
      throw new Error('Unreachable - UsState should be set');
    }

    if (!amTrustFullFlow) {
      // Set a random value for SSN since it won't be asked in this case
      model.setValue('Amtrust_SSN_US_EN', '123456789');
    }

    const ssn = model.getQuestionByName('Amtrust_SSN_US_EN');

    const { value: Amtrust_SSN_US_EN } = ssn;

    if (
      typeof Amtrust_SSN_US_EN !== 'string' &&
      Amtrust_SSN_US_EN !== undefined
    ) {
      throw new Error('ssn should be a string or undefined');
    }

    const startWcResponse = await startWc({
      variables: { applicationObjectId: applicationId, ssn: Amtrust_SSN_US_EN }
    });

    const wcSessionObjectId = startWcResponse.data?.startWc;

    if (!wcSessionObjectId) {
      // don't inject anything
      return;
    }

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    // this interval relies on the fact that TryGetWcQuestions is a simple database read which returns immediately.
    const pollingInterval = 2000; // ms

    // we just kick-started the request, so wait for some time before the first poll request.
    sleep(pollingInterval);

    const wcDyanmicQuestionPage = model.getPageByName(
      'AmtrustWcAdditionalQuestions'
    );

    const brokerflowPage = model.getPageByName(
      'BrokerWorkersCompensation_0001'
    );

    // Remove isExcluded question if already seen on AmtrustWC page
    const isExcludedQuestion = brokerflowPage.getQuestionByName(
      'Amtrust_PersonalServices_0001_OwnerIncluded_WORLD_EN'
    );

    isExcludedQuestion.visible = false;

    const setupBrokerFlow = () => {
      wcDyanmicQuestionPage.visible = false;
      brokerflowPage.visible = true;
    };

    const maxTries = 30;
    for (let i = 0; i < maxTries; i++) {
      const {
        data: {
          tryGetWcQuestions: { isDecline, status, questions }
        }
      } = await APOLLO_CLIENT.query<
        TryGetWcQuestionsQuery,
        TryGetWcQuestionsQueryVariables
      >({
        fetchPolicy: 'network-only',
        query: TryGetWcQuestionsDocument,
        variables: {
          applicationObjectId: applicationId,
          wcSessionObjectId
        }
      });

      switch (status) {
        case TryGetWcQuestionsStatus.Error:
          setupBrokerFlow();
          return; // this is an "end" state; finish the retrying attempts
        case TryGetWcQuestionsStatus.NotFound:
          await sleep(pollingInterval);
          continue;
        case TryGetWcQuestionsStatus.Found:
          {
            model.setVariable('isBindOnlineAmtrust', !isDecline);

            wcDyanmicQuestionPage.questions.forEach((question: Question) =>
              wcDyanmicQuestionPage.removeQuestion(question)
            );

            const addedJson = {
              questions: questions.map(({ id, question: title }) => {
                return {
                  name: `Amtrust_Question_${id}`,
                  title,
                  type: 'customboolean',
                  titleLocation: 'hidden',
                  hideNumber: true,
                  defaultValue: 'false',
                  isRequired: true
                };
              })
            };

            const newQuestions = new Model(addedJson).getAllQuestions();

            const wcDynamicQuestionPanel = getDyanmicQuestionPanel(model);

            newQuestions.forEach((question) =>
              wcDynamicQuestionPanel.addQuestion(question)
            );
          }
          return; // this is an "end" state; finish the retrying attempts
        default:
          throw new Error('Unreachable - unknown status');
      }
    }

    // max retry attempts reached; move on to the broker flow
    setupBrokerFlow();
  };
}

export function trySetAmtrustBusinessAddressDefaults(
  model: SurveyModel,
  { amTrustMaster }: Flags
): WcHelperFn {
  return async () => {
    if (!amTrustMaster) {
      return;
    }

    const usState = model.getVariable('UsState');

    if (!US.isShortName(usState)) {
      // not a US application, so don't try to inject address suggestions for Amtrust (i.e. for US apps)
      return;
    }

    const amTrustOfficers = getAmtrustOfficerPanel(model);

    const workLocation = amTrustOfficers.template.getQuestionByName(
      'workLocation'
    );

    if (!(workLocation instanceof QuestionRadiogroupModel)) {
      throw new Error(
        "Unreachable - workLocation question isn't a radio groupd"
      );
    }

    if (workLocation.choices.length > 0) {
      return;
    }

    const { street, province, postalCode, city } = model.getQuestionByName(
      'BusinessInformation_100_BusinessAddress_WORLD_EN'
    ).value;

    const defaultAddress = [street, city, province, postalCode].join(', ');

    workLocation.choices = [
      {
        text: defaultAddress,
        value: defaultAddress
      },
      { text: 'Other', value: 'Other' }
    ];

    workLocation.defaultValue = defaultAddress;

    const employeePayrollPanel = model.getPanelByName('employeePayrollPanel');
    if (!(employeePayrollPanel instanceof PanelModel)) {
      throw new Error(
        'Unreachable - employeePayrollPanel is not based on a panel'
      );
    }

    const employeeAddress = employeePayrollPanel.getQuestionByName(
      'employeeAddress'
    );
    if (!(employeeAddress instanceof QuestionHtmlModel)) {
      throw new Error(
        'Unreachable - employeePayrollPanel is not based on an html'
      );
    }
    employeeAddress.html = defaultAddress;
  };
}

export function trySetOwnerOfficerDefaults(
  model: SurveyModel,
  { amTrustMaster }: Flags
): WcHelperFn {
  return async () => {
    if (!amTrustMaster) {
      return;
    }

    const businessType = model.getQuestionByName(
      'BusinessInformation_100_BusinessType_WORLD_EN'
    ).value;

    const amtrustOfficer = toAmtrustBusinessType({ businessType });

    const rules: Rule[] = model.getVariable('rules');

    if (!rules) {
      // Amtrust API is down
      return;
    }

    const filteredRules = rules.filter(
      ({ officer }) => officer === amtrustOfficer
    );

    if (filteredRules.length !== 1) {
      throw new Error(
        `Only 1 rule should have been found for ${amtrustOfficer}`
      );
    }

    const rule = filteredRules[0];

    const {
      canChooseToIncludeExclude,
      isIncludedByDefault,
      limits,
      note
    } = rule;

    const isContractor = model.getVariable('isContractor');

    const businessIndustry = !!isContractor ? 'Construction' : 'Other';

    const filteredLimits = (() => {
      const filtered = limits.filter(
        ({ industry }) => industry === businessIndustry
      );

      if (filtered.length !== 0) {
        return filtered;
      }

      const filteredOther = limits.filter(
        ({ industry }) => industry === 'Other'
      );

      return filteredOther;
    })();

    if (filteredLimits.length !== 1) {
      throw new Error(`Only 1 limit should have been found for Other`);
    }

    const limit = filteredLimits[0];

    const { min, max } = limit;

    model.setVariable('officerMin', min);
    model.setVariable('officerMax', max);
    model.setVariable('canChooseToIncludeExclude', canChooseToIncludeExclude);
    model.setVariable('note', note);

    const amTrustOfficers = getAmtrustOfficerPanel(model);

    const isExcluded = amTrustOfficers.template.getQuestionByName('isExcluded');

    // This sets the default value for new owner/officers
    isExcluded.defaultValue = !isIncludedByDefault;

    const { currentPage } = model;

    if (currentPage.name !== 'BusinessInformation_100') {
      return;
    }

    const panels = amTrustOfficers.panels;
    for (const panel of panels) {
      const isExcluded = panel.getQuestionByName('isExcluded');

      // This sets the value for all owner/officers already created
      isExcluded.value = !isIncludedByDefault;
    }
  };
}
