import { Choice } from '../components/SearchSelect/SearchSelectTypes';
import nonFoxdenProfessions from './config/nonFoxdenProfessions.json';

export const filterNonFoxdenProfessions = (
  professionChoices: Choice[]
): Choice[] => {
  const professionMapper = new Map(
    nonFoxdenProfessions.map((value) => [value.profession.trim(), value.remove])
  );
  const newChoices = professionChoices.filter(
    (choice) => !professionMapper.get(choice.text.trim())
  );

  return newChoices;
};
