import { addDays, parseISO } from 'date-fns';
import { Model } from 'survey-core';

import { DatePickerTypes } from '../components/DatePicker/DatePickerTypes';
/**
 * Restrict the min and max date for endorsement effective date
 * @param  {Model} model
 * @param  {string} policyStartDateStr
 * @param  {string} policyExpiryDateStr
 */
export default function setMinMaxDateForEffectiveDate(
  model: Model,
  policyStartDateStr?: string | null,
  policyExpiryDateStr?: string | null,
  transactionType?: string | null,
  allowDatePickerToPickDateUpToSixtyDays?: boolean
): void {
  const effectiveDateQuestion = model.getQuestionByName(
    'BusinessInformation_100_EffectiveDate_WORLD_EN'
  ) as DatePickerTypes;
  if (effectiveDateQuestion) {
    const DATE_RANGE = 60;
    // general case for effective date
    let minDate = new Date();
    let maxDate = allowDatePickerToPickDateUpToSixtyDays
      ? addDays(minDate, 60)
      : addDays(minDate, 30);
    // for endorsement
    if (
      policyStartDateStr &&
      policyExpiryDateStr &&
      (transactionType === 'Endorsement' || transactionType === undefined) // undefined will be for old policies
    ) {
      // maxDate should still be 60 days for endorsements
      maxDate = addDays(minDate, DATE_RANGE);
      const policyStartDate = parseISO(policyStartDateStr);
      const policyExpiryDate = parseISO(policyExpiryDateStr);
      if (policyStartDate > minDate) {
        minDate = policyStartDate;
      }
      if (policyExpiryDate < maxDate) {
        maxDate = policyExpiryDate;
      }
    } else if (policyExpiryDateStr && transactionType === 'Renewal') {
      const policyExpiryDate = parseISO(policyExpiryDateStr);
      minDate = policyExpiryDate;
      maxDate = policyExpiryDate;
      effectiveDateQuestion.value = policyExpiryDate;
    }
    effectiveDateQuestion.minDate = minDate;
    effectiveDateQuestion.maxDate = maxDate;
  }
}
