import './CoverageOption.css';

import { Checkbox, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { CoverageOptionData } from './CoverageOptionTypes';

interface CoverageOptionProps {
  coverageTitle?: string;
  coverageDescription?: string;
  value?: CoverageOptionData;
  onChange: (value: CoverageOptionData) => void;
  helperText?: string;
  readOnly?: boolean;
}

// TODO: Remove slider, it's not needed anymore
// TODO: update question type from coverageOption to checkbox instead, result value should be boolean instead of object
const CoverageOption: React.FC<CoverageOptionProps> = (props) => {
  const {
    coverageTitle,
    coverageDescription,
    value,
    onChange,
    helperText,
    readOnly
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div
      className={`CheckBox flex border-4 ${
        readOnly
          ? 'border-faded'
          : value?.chooseCoverage
          ? 'border-secondary cursor-pointer'
          : 'border-faded hover:border-secondary cursor-pointer'
      } hover:bg-gray-100 rounded-lg py-6 px-4 md:py-8 md:px-6`}
      data-testid="CoverageOption"
      onClick={() => {
        if (!readOnly) {
          onChange({
            chooseCoverage: !value?.chooseCoverage,
            coverageLimit: value?.coverageLimit || ''
          });
        }
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>
              <Checkbox
                className="p-0"
                disabled={readOnly}
                checked={!!value?.chooseCoverage}
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
                style={
                  readOnly
                    ? { color: '#878787' }
                    : value?.chooseCoverage
                    ? { color: '#1ea2ae' }
                    : { color: '#878787' }
                }
              />
            </td>
            <td>
              <div className="flex flex-col pl-4 md:pl-6">
                <span className="text-m md:text-2xl font-semibold md:font-bold mb-2">
                  {coverageTitle}
                  {helperText ? (
                    <span className="inline-block">
                      <div
                        className="help-button cursor-pointer"
                        onClick={() => setModalOpen(true)}
                      >
                        ?
                      </div>
                      <Modal
                        className="flex"
                        open={isModalOpen}
                        onClose={handleModalClose}
                      >
                        <div className="coverage-option-tips overflow-y-auto m-auto h-4/5 w-4/5 md:w-1/2 bg-white text-left p-8 sm:w-full">
                          {ReactHtmlParser(helperText)}
                        </div>
                      </Modal>
                    </span>
                  ) : null}
                </span>
                <span
                  className={`${
                    showDescription && 'hidden'
                  } md:hidden text-secondary cursor-pointer font-semibold`}
                  onClick={() => setShowDescription(!showDescription)}
                >
                  Read more..
                </span>
                <span
                  className={`${
                    showDescription ? 'block' : 'hidden'
                  } md:block text-base`}
                >
                  {coverageDescription}
                </span>
                <span
                  className={`${
                    !showDescription && 'hidden'
                  } md:hidden text-secondary cursor-pointer font-semibold`}
                  onClick={() => setShowDescription(!showDescription)}
                >
                  Collapse Text
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CoverageOption;
