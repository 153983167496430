import { Modal } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { omit } from 'lodash';
import React, { Fragment, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import SelectInput from '../../../../components/QuotePageSelectInput/SelectInput';
import { UpdateCoverageInput } from '../../../../generated/graphql';
import { CoveragesDataInput } from '../..';
import { AdditionalCoverages } from './AdditionalCoverages';
import {
  endorsementDefaultValues,
  endorsementNames,
  getCoveragesDetails,
  getCoveragesDetailsIneligible,
  limitsLabels
} from './CoveragesDetails';
import PopupContent from './PopupContent';

interface MunichCoverageProps {
  coverageData: CoveragesDataInput;
  onUpdateCoverage: (input: UpdateCoverageInput) => void;
  region: string;
  quoteId: string;
  applicationId: string;
  carrierPartner: string;
}

export const MunichCoverageDetail: React.FC<MunichCoverageProps> = (props) => {
  const {
    coverageData,
    onUpdateCoverage,
    region,
    quoteId,
    applicationId,
    carrierPartner
  } = props;
  const [showPopup, setShowPopup] = useState(false);

  const {
    name,
    chooseCoverage,
    limits,
    limitsOptions: backendLimitsOptions
  } = coverageData;

  // Removes cglAdditionalLimits to put into the AdditionalCoverages dropdown
  const cglAdditionalLimits = [
    'medicalPaymentsLimit',
    'tenantLegalLiabilityLimit',
    'premisesRentedToYouLimit'
  ];

  const unusedLimits = ['deductible'];

  const filteredLimitsObject: Record<string, number> = omit(
    limits,
    cglAdditionalLimits
  );

  // Ensures that Aggregate Limit is always ordered first
  const filteredLimitsArray = Object.keys(filteredLimitsObject).sort((a, b) => {
    if (a === 'aggregateLimit') {
      return -1;
    }
    if (b === 'aggregateLimit') {
      return 1;
    }
    return 0;
  });

  // Remove extra limits not used in rating for updating coverage limits
  const updateCoverageLimitsInput: Record<string, number> = omit(
    filteredLimitsObject,
    cglAdditionalLimits.concat(unusedLimits)
  );

  const coverageDetails = getCoveragesDetails(region, carrierPartner)[name];
  if (!coverageDetails) {
    return null;
  }
  const { limitsOptions: frontendLimitsOptions } = coverageDetails;
  const limitsOptions = backendLimitsOptions || frontendLimitsOptions; // backendLimitsOptions has higher prority

  return (
    <div className="coverage grid grid-cols-1 md:grid-cols-8 mb-5">
      <div className="col-span-1 grid grid-cols-4">
        <input
          type="checkbox"
          data-name={`${name}_checkbox`}
          className="flex justify-center items-center w-10 h-10 mt-2 col-span-1"
          checked={chooseCoverage}
          disabled={coverageDetails.disable}
          onChange={() => {
            // check if limit available for endorsement, if not, take the default value instead
            let newLimits = updateCoverageLimitsInput;
            if (endorsementNames.includes(name)) {
              const { coverageLimit: limit } = updateCoverageLimitsInput;
              if (!limit) {
                newLimits = {
                  coverageLimit: endorsementDefaultValues[name]
                };
              }
            }

            const coverage = {
              name,
              chooseCoverage: !chooseCoverage,
              limits: newLimits
            };
            onUpdateCoverage({
              munichInput: {
                quoteId,
                applicationId,
                region,
                coverage
              }
            });
          }}
        />
        <p className="col-span-3 md:hidden text-navy font-extrabold text-xl">
          {coverageDetails.title}
        </p>
      </div>
      <img
        src={coverageDetails.img}
        className="col-span-1 hidden md:block mxr-4 h-14"
      />
      <div className="col-span-1 md:col-span-6 pl-2 md:pl-0">
        <p>
          <b className="hidden md:block text-navy font-extrabold text-base md:text-2xl">
            {coverageDetails.title}
          </b>
        </p>
        <span className="coverage-description text-gray-700 mt-2 mb-2 text-xs md:text-base inline-block">
          {coverageDetails.subTitle &&
            ReactHtmlParser(coverageDetails.subTitle)}{' '}
          {coverageDetails.popupContent ? (
            <p className="text-secondary text-sm md:text-base underline pointer">
              <a className="cursor-pointer" onClick={() => setShowPopup(true)}>
                <b>{coverageDetails.popupTitle}</b>
              </a>
              <Modal
                className="flex"
                open={showPopup}
                onClose={() => {
                  setShowPopup(false);
                }}
              >
                <PopupContent content={coverageDetails.popupContent} />
              </Modal>
            </p>
          ) : null}
        </span>
        {limitsOptions ? (
          <Fragment>
            {filteredLimitsArray.map((limitName) => {
              const limitValue = filteredLimitsObject[limitName];
              const limitLabel =
                name === ('miscellaneousEO' || 'contractorsEO') &&
                limitName === 'occurrenceLimit'
                  ? 'Each Wrongful Act'
                  : limitsLabels[limitName];
              return (
                <Fragment key={limitName}>
                  <div
                    data-name={`${name}_${limitName}`}
                    className="grid grid-cols-2 md:grid-cols-3 limit text-sm md:text-base mb-2"
                  >
                    {limitName !== 'deductible' ? (
                      <SelectInput
                        name={`${name}_${limitName}`}
                        onChange={(newValue) => {
                          const newLimitValue = parseInt(newValue);
                          const newLimit = {} as Record<string, number>;
                          // when they change any of cgl limit, value should assign to another limit as well
                          if (
                            (name === 'cgl' && region === 'Canada') ||
                            name === 'limitedPollution'
                          ) {
                            newLimit.aggregateLimit = newLimitValue;
                            newLimit.occurrenceLimit = newLimitValue;
                          } else {
                            newLimit[limitName] = newLimitValue;
                          }
                          const coverage = {
                            name,
                            chooseCoverage: true, // if we change limit, make the chooseCoverage = true
                            limits: {
                              ...updateCoverageLimitsInput,
                              ...newLimit
                            }
                          };
                          onUpdateCoverage({
                            munichInput: {
                              quoteId,
                              applicationId,
                              region,
                              coverage
                            }
                          });
                        }}
                        options={limitsOptions[limitName]}
                        value={
                          limitValue || limitValue === 0
                            ? limitValue.toString()
                            : undefined
                        }
                        containerClass="w-28 md:w-32 border-primary font-raleway text-primary text-mainFont sm:text-mobileFont sm:w-smallMobile"
                        valueClass="not-italic font-medium"
                      />
                    ) : null}{' '}
                    {limitName === 'deductible' ? (
                      <p className="font-medium">${limitValue}</p>
                    ) : null}
                    <span className="font-semibold md:col-span-2">
                      {limitLabel}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        ) : null}
        {name === 'cgl' ? (
          <AdditionalCoverages
            limits={limits}
            carrierPartner={carrierPartner}
          />
        ) : null}
      </div>
    </div>
  );
};

interface IneligibleMunichQuoteProps {
  selectedCoverage: string;
  region: string;
}

const coverageMap = new Map([
  ['Coverage_Broker_901_CGL_WORLD_EN', 'cgl'],
  ['Coverage_Broker_901_E&O_WORLD_EN', 'miscellaneousEO'],
  ['Coverage_Broker_901_D&O_WORLD_EN', 'DO']
]);

export const IneligibleMunichQuote: React.FC<IneligibleMunichQuoteProps> = (
  props
) => {
  const { selectedCoverage, region } = props;

  const name = coverageMap.get(selectedCoverage);

  if (!name) {
    return null;
  }

  const coverageDetails = getCoveragesDetailsIneligible(region)[name];
  if (!coverageDetails) {
    return null;
  }

  const { img, title, subTitle } = coverageDetails;

  return (
    <div className="coverage flex flex-row mb-5">
      <CancelIcon className="cancel-icon mt-4 mx-3" />
      <img src={img} className="w-14 mr-6 ml-4 h-14 sm:w-8" />
      <div>
        <p>
          <b className="text-2xl font-black sm:text-base">{title}</b>
        </p>
        <p className="text-gray-700 text-base mt-2 mb-2 sm:text-xs">
          {subTitle ? ReactHtmlParser(subTitle) : undefined}
        </p>
        <p className="text-gray-700 font-bold text-base mt-2 mb-2 sm:text-xs">
          A licensed Foxquilt agent will reach out to you shortly regarding your
          interest in <span className="text-primary">{title}</span>.
        </p>
      </div>
    </div>
  );
};
