/* eslint-disable @typescript-eslint/no-explicit-any */
import lodash from 'lodash';
import { FunctionFactory } from 'survey-core';
import { z } from 'zod';

import { Canada, US } from '../../utils/address/province';
import CommonMapper from '../../utils/config/commonWorkExclusionProfessionMapper.json';
import StateNationalMapper from '../../utils/config/statenationalWorkExclusionProfessionMapper.json';
import workExclusionList from '../../utils/config/workExclusionList.json';
import { exceptionRules } from '../../utils/config/workExclusionSpecialCases';
import mergeArraysWithoutDuplicates from '../../utils/mergeArraysWithoutDuplicates';

interface Percentage {
  percentage: number;
}

// custom expression

// sum up all percentages for question professionsPercentage
function percentagesSum(params: any[]) {
  const percentageMapper = params[0];
  if (!percentageMapper) return 0;
  const percentageList = Object.values(percentageMapper) as Percentage[];
  return lodash.sumBy(percentageList, 'percentage');
}

// check if the array overlay
interface OtherProfession {
  otherProfession: string;
}
function otherProfessionsOverlap(params: any[]) {
  const otherProfessions = params[0];
  const professionArray = params[1];
  if (!otherProfessions || !professionArray) return false;
  const professions = [] as string[];
  otherProfessions.forEach((p: OtherProfession) => {
    professions.push(p.otherProfession);
  });
  let isOverlap = false;
  professionArray.forEach((p: string) => {
    if (professions.includes(p)) {
      isOverlap = true;
      return;
    }
  });
  return isOverlap;
}

// Custom function for Attestations Questions to set quoteFlage
function attestationsIfSelectedAll(this: any, params: any[]) {
  const questionName = params[0];
  const questionInstance = this.survey.getQuestionByName(questionName);
  return questionInstance.isAllSelected;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isVisible(this: any, params: any) {
  if (!params && params.length !== 1) {
    return false;
  }
  const questionName = params[0];
  const questionInstance = this.survey.getQuestionByName(questionName);
  return questionInstance.isVisible;
}

FunctionFactory.Instance.register('isVisible', isVisible);

// Custom function to generate the workExclusion on Page3
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function workExclusionVariableText(this: any, params: any[]) {
  // arguments
  const professionList = params[0];
  const residentialWorkPercentage = params[1];
  const exteriorWorkPercentage = params[2];
  const commercialWorkPercentage = params[3];
  const jsonVersionNumber = params[4];
  const address = this.survey.getValue(
    'BusinessInformation_100_BusinessAddress_WORLD_EN'
  );
  const carrierPartner = this.survey.getVariable('carrierPartner');
  const province = address?.province;

  if (!professionList) return '';
  if (!jsonVersionNumber) {
    throw Error('json version should not be empty');
  }
  const region = jsonVersionNumber.split('_')[0];
  const country = region.includes('-') ? region.split('-')[0] : region;
  if (province && !US.isShortName(province) && !Canada.isShortName(province)) {
    throw Error('Invalid province/state value');
  }

  const workPercentages: Record<string, number> = {
    residentialWorkPercentage,
    exteriorWorkPercentage,
    commercialWorkPercentage
  };

  const professionMapper: Record<string, Record<string, string>> =
    carrierPartner === 'StateNational' ? StateNationalMapper : CommonMapper;

  const workExclusionIndicesList: string[] = [];
  professionList.forEach((profession: string) => {
    // check if iso match
    const iso = profession.split('_')[4].substring(0, 5);
    if (exceptionRules[iso]) {
      const data = exceptionRules[iso];
      data.forEach((d) => {
        const { percentageName, workExclusionList } = d;
        // check if the corresponding percentage > 0 and matches the exception rule
        if (workPercentages[percentageName] > 0) {
          workExclusionIndicesList.push(workExclusionList);
        }
      });
    }
  });
  professionList.forEach((profession: string) => {
    const workExclusionIndexData = professionMapper[profession];
    if (!workExclusionIndexData) return;
    // Configuration priority: check province/state > country > general
    let configureOnStateLevel = false;
    if (province) {
      const dataKeys = Object.keys(workExclusionIndexData);
      dataKeys.forEach((keyName) => {
        if (keyName.includes(province)) {
          configureOnStateLevel = true;
          workExclusionIndicesList.push(workExclusionIndexData[keyName]);
        }
      });
    }
    if (!configureOnStateLevel) {
      workExclusionIndicesList.push(
        workExclusionIndexData[country] ||
          workExclusionIndexData['Words_for_work_exclusion']
      );
    }
  });
  const indicesList = workExclusionIndicesList.map(
    (indices: string) => indices.split('-') || []
  );
  const cleanedindicesList = mergeArraysWithoutDuplicates(indicesList);
  const result = cleanedindicesList
    .map((index) => +index)
    .reduce((acc, index, arrayIdx) => {
      if (arrayIdx === 0) return `<li>${workExclusionList[index].text}</li>`;
      return `${acc}<li>${workExclusionList[index].text}</li>`;
    }, '');
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function arrayContains(this: any, params: any[]) {
  const questionValue = params[0];
  const searchKey = params[1];
  if (
    !questionValue ||
    !searchKey ||
    !Array.isArray(questionValue) ||
    !Array.isArray(searchKey)
  )
    return false;

  let isContain = false;
  questionValue.forEach((data: string) => {
    searchKey.forEach((d: string) => {
      if (data.includes(d)) {
        isContain = true;
      }
    });
  });
  return isContain;
}

const ownershipSchema = z
  .array(
    z
      .array(
        z.object({
          duties: z
            .array(
              z.object({
                ownershipPercent: z.number()
              })
            )
            .nonempty()
        })
      )
      .nonempty()
  )
  .length(1);

function doOwnershipPercentagesAddTo100(params: unknown[]): boolean {
  const parseResult = ownershipSchema.safeParse(params);
  if (!parseResult.success) {
    return false;
  }
  const dynamicPanelsArr = parseResult.data[0];

  const percentSum = dynamicPanelsArr.reduce((sum, panel) => {
    const ownershipPercent = panel.duties[0].ownershipPercent;
    return sum + ownershipPercent;
  }, 0);

  return percentSum === 100;
}

export const schema = z.object({
  isStateSupported: z.boolean(),
  isEndorsement: z.boolean()
});

function isAmtrustCoverageVisible(this: any): boolean {
  const isStateSupportedParse = this.survey.getVariable('isStateSupported');
  const isEndorsementParse = this.survey.getVariable('isEndorsement');

  const parsedResult = schema.safeParse({
    isStateSupported: isStateSupportedParse,
    isEndorsement: isEndorsementParse
  });

  if (!parsedResult.success) {
    return false;
  }

  const { isEndorsement, isStateSupported } = parsedResult.data;

  return isStateSupported && !isEndorsement;
}

FunctionFactory.Instance.register('percentagesSum', percentagesSum);
FunctionFactory.Instance.register(
  'otherProfessionsOverlap',
  otherProfessionsOverlap
);
FunctionFactory.Instance.register(
  'attestationsIfSelectedAll',
  attestationsIfSelectedAll
);

FunctionFactory.Instance.register(
  'workExclusionVariableText',
  workExclusionVariableText
);
FunctionFactory.Instance.register('arrayContains', arrayContains);

FunctionFactory.Instance.register(
  'doOwnershipPercentagesAddTo100',
  doOwnershipPercentagesAddTo100
);

FunctionFactory.Instance.register(
  'isAmtrustCoverageVisible',
  isAmtrustCoverageVisible
);
