import { IQuestion } from 'survey-core';

export enum CustomWidth {
  SuperSmall = 'superSmall',
  Smaller = 'smaller',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  SuperLarge = 'superLarge',
  Full = 'full'
}

export interface Choices {
  text: string;
  value: string;
}

export interface SelectInputQuestionType extends IQuestion {
  leftLabel?: string;
  rightLabel?: string;
  customWidth?: CustomWidth;
  choices: Array<Choices>;
  helperText?: string;
}
