/* eslint-disable prettier/prettier */
import QueryString from 'qs';
export interface UnderwritingUrlData {
  foxquiltBrokerId?: string;
  foxquiltAgencyId?: string;
  brokerCode?: string;
  agencyBrokerId?: string;
  agencyId?: string;
  partnercode?: string;
  effectiveDate?: string;
  country?: string;
  province?: string;
  state?: string;
  endorsement?: string;
  renewal?: string;
}
export default async function (): Promise<UnderwritingUrlData> {
  const parsedQueryString = QueryString.parse(location.search, {
    ignoreQueryPrefix: true
  });
  return parsedQueryString;
}
