import './Loading.css';

import React from 'react';

import Logo from '../../images/logo-brand.png';

const Loading: React.FC = () => (
  <div className="grid place-items-center h-full">
    <div className="Loading LoadingContainer flex" data-testid="Loading">
      <img className="logo m-auto" src={Logo} alt="logo" />
    </div>
  </div>
);

export default Loading;
