import './Modal.css';

import React from 'react';

interface ModalProps {
  closeAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeName?: string;
  actionName?: string;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { closeAction, onAction, actionName, closeName, children } = props;
  return (
    <div className="modal" data-testid="Modal">
      <div className="modal-content text-mainFont center bg-white border-2 border-white">
        {children}
        {closeAction ? (
          <button
            className="bg-tertiary text-base uppercase pointer font-bold  mt-6 px-6 py-3 font-raleway sm:text-mobileFont rounded text-white mr-4"
            onClick={closeAction}
          >
            {closeName || 'Close'}
          </button>
        ) : null}
        {onAction ? (
          <button
            className="bg-tertiary  text-base uppercase pointer font-bold  mt-6 px-6 py-3 font-raleway sm:text-mobileFont rounded text-white"
            onClick={onAction}
          >
            {actionName || 'Proceed'}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
