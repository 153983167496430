import { AbbrevProvince } from '../graphql/forms/CanadaProvinceResolver';
import { Province } from '../graphql/forms/CanadaProvinceResolver';
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** This is a string type with the constrain that the values can be one of: AB,BC,MB,NB,NF,NT,NS,NU,ON,PE,QC,SK,YT */
  AbbrevCanadaProvince: AbbrevProvince;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Answers: Record<string, unknown>;
  /** This is a string type with the constrain that the values can be one of: Alberta,British Columbia,Manitoba,New Brunswick,Newfoundland and Labrador,Northwest Territories,Nova Scotia,Nunavut,Ontario,Prince Edward Island,Quebec,Saskatchewan,Yukon */
  CanadaProvince: Province;
  /** This is a string type with the constrain that the values can be one of: Canada/Eastern,Canada/Atlantic,Canada/Newfoundland,Canada/Central,Canada/Mountain,Canada/Pacific */
  CanadaTimeZone: string;
  ConvertedAnswers: any;
  /** An Date formatted into ISO Date representation (date-fns' formatISO with the date representation). This scalar should be used for returning a localized string to the frontend. */
  Date: string;
  /** An Date with Time formatted into ISO Date representation. */
  DateTime: string;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: Record<string, unknown>;
  NonNegativeFloat: number;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: string;
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: string;
  URL: string;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string;
};


export type AdditionalInsured = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AdditionalInsuredAddress>;
  email?: Maybe<Scalars['String']>;
};

export type AdditionalInsuredAddress = {
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  postalCode: Scalars['PostalCode'];
  address2?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['CanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type AmtrustEligibleQuoteResponse = {
  __typename?: 'AmtrustEligibleQuoteResponse';
  amtrustquoteId: Scalars['NonNegativeInt'];
  premium: Scalars['Float'];
  limit: WcAvailableLimit;
  availableLimit: Array<WcAvailableLimit>;
  feinIsValid: Scalars['Boolean'];
  proposalDownloadLink?: Maybe<Scalars['URL']>;
};

/** You use __typename to discriminate the types, so no data here other than GraphQL mandates one */
export type AmtrustIneligibleQuoteResponse = {
  __typename?: 'AmtrustIneligibleQuoteResponse';
  /** this should always be undefined */
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type AmtrustResetCoverageRecommendationInput = {
  quoteId: Scalars['NonNegativeInt'];
};

export type AmtrustUpdateCoverageInput = {
  wcLimit: WcAvailableLimitInput;
  updatedFein?: Maybe<Scalars['String']>;
  wcQuoteId: Scalars['NonNegativeFloat'];
};

export type AmtrustUpdateCoverageOutput = {
  __typename?: 'AmtrustUpdateCoverageOutput';
  premium: Scalars['NonNegativeFloat'];
  feinIsValid?: Maybe<Scalars['Boolean']>;
  wcLimit?: Maybe<Scalars['String']>;
};


export type AnswersInput = {
  applicationId: Scalars['ObjectID'];
  answers: Scalars['Answers'];
  reasonFlag?: Maybe<Scalars['String']>;
};

export type AnswersWithIdInput = {
  applicationId: Scalars['ObjectID'];
  answers: Scalars['Answers'];
  isBrokerFlow: Scalars['Boolean'];
  reasonFlag?: Maybe<Scalars['String']>;
};

export type ApplicationBlocked = {
  __typename?: 'ApplicationBlocked';
  applicationId: Scalars['ObjectID'];
};

export type ApplicationFailure = {
  __typename?: 'ApplicationFailure';
  error: Scalars['String'];
};

export type ApplicationResponse = ApplicationSuccess | ApplicationFailure;

export enum ApplicationResultKind {
  /** This means to decline quote */
  DeclineBusiness = 'DeclineBusiness',
  /** This means go to broker flow anyway */
  SendToBroker = 'SendToBroker',
  /** This means forward to get a quote */
  OfferQuote = 'OfferQuote',
  /** This means error happen */
  Error = 'Error'
}

export type ApplicationSuccess = {
  __typename?: 'ApplicationSuccess';
  applicationId: Scalars['ObjectID'];
};

export type BusinessAddressChange = {
  __typename?: 'BusinessAddressChange';
  newAddress: UnderwritingAddress;
};

export type CalChangedQuoteResult = {
  __typename?: 'CalChangedQuoteResult';
  changes: Array<EndorsementChange>;
  /**
   * There's only a quote result when changes would introduce change in premiums (i.e. isInvolvePayment).
   * If isInvolvePayment = false then there's no "quote" calculations.
   */
  quote?: Maybe<Scalars['JSONObject']>;
  /** You can get this in the untyped answer set. Use this if/when the answer set is still untyped. */
  startDate: Scalars['Date'];
};

export enum CanadaChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CanadaCoverageLimit = {
  __typename?: 'CanadaCoverageLimit';
  coverage: CanadaCoverageLimitType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

/**
 * This type is mostly a copy of ChildCoverageType. However, it's needed since some child coverage has
 * multiple limits, like E&O which needs to distinguish between occurrence & aggregate limits.
 * Most limit names use the same one as the coverage name since they only have 1 limit.
 */
export enum CanadaCoverageLimitType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions (Aggregate) */
  EoAggregate = 'EOAggregate',
  /** Errors & Omissions (Occurrence) */
  EoOccurrence = 'EOOccurrence',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Unscheduled Business Personal PropertyP */
  UnscheduledBpp = 'UnscheduledBPP',
  /** Hardware / Software */
  Hwsw = 'HWSW',
  /** Lock Key Business Items */
  LockKeyBusinessItems = 'LockKeyBusinessItems',
  /** Unscheduled Contractors */
  UnscheduledContractors = 'UnscheduledContractors',
  /** Unscheduled hardware/software */
  UnscheduledHwsw = 'UnscheduledHWSW',
  /** Unscheduled Small Tools */
  UnscheduledSmallTools = 'UnscheduledSmallTools',
  /** Lock Key Contractors */
  LockKeyContractors = 'LockKeyContractors',
  /** Photo Video Endorsement */
  PhotoVideoEndor = 'PhotoVideoEndor',
  /** Physical Loss */
  PhysicalLoss = 'PhysicalLoss',
  /** Valuable Papers */
  ValuablePapers = 'ValuablePapers'
}

export type CanadaCoverageUpdateChange = {
  __typename?: 'CanadaCoverageUpdateChange';
  coverage: CanadaParentCoverageType;
  action: EndorsementCoverageAction;
  limits: Array<CanadaCoverageLimit>;
};

export enum CanadaParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Inland Marine */
  Im = 'IM'
}



export enum CancellationTrigger {
  Carrier = 'carrier',
  Client = 'client'
}

/** Update CompletedApplicationResponse types in anther ticket */
export type CompletedApplicationResponse = {
  __typename?: 'CompletedApplicationResponse';
  applicationId?: Maybe<Scalars['ObjectID']>;
  errors: Array<Scalars['String']>;
  kind: ApplicationResultKind;
};


export type CoverageInput = {
  name: Scalars['String'];
  chooseCoverage: Scalars['Boolean'];
  limits: Scalars['JSONObject'];
};

export type CoveragesData = {
  __typename?: 'CoveragesData';
  name: Scalars['String'];
  chooseCoverage: Scalars['Boolean'];
  finalPremium: Scalars['Int'];
  limits: Scalars['JSONObject'];
};

/** Currencies that are supported by Foxden. */
export enum Currency {
  Cad = 'CAD',
  Usd = 'USD'
}

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CustomerInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};



export type EndorsementChange = BusinessAddressChange | CanadaCoverageUpdateChange | MailingAddressChange | NamedInsuredChange | OhioCoverageUpdateChange;

export enum EndorsementCoverageAction {
  Add = 'Add',
  Remove = 'Remove',
  Update = 'Update'
}

/** TODO: Remove it when renewal is ready */
export type EndorsementQuerystringData = {
  __typename?: 'EndorsementQuerystringData';
  policyFoxdenId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationTrigger?: Maybe<Scalars['String']>;
};

export type FetchQuoteResponse = {
  __typename?: 'FetchQuoteResponse';
  quoteId: Scalars['ObjectID'];
  quoteNumber: Scalars['String'];
  premium: Scalars['Float'];
  carrierName: Scalars['String'];
};

export type GetJsoNbyApplicationIdResponse = {
  __typename?: 'GetJSONbyApplicationIdResponse';
  json: Scalars['JSONObject'];
  professionList: Array<Scalars['String']>;
  customerInfo: CustomerInfo;
  state?: Maybe<Scalars['String']>;
  previousAnswers?: Maybe<Scalars['ConvertedAnswers']>;
  policyStartDateStr?: Maybe<Scalars['String']>;
  policyExpiryDateStr?: Maybe<Scalars['String']>;
  wcData?: Maybe<GetJsonWcResponse>;
  transactionType?: Maybe<Scalars['String']>;
  carrierPartner: Scalars['String'];
  displayWcQuestions: Scalars['Boolean'];
};

export type GetJsonWcError = {
  __typename?: 'GetJsonWcError';
  message: Scalars['String'];
};

export type GetJsonWcResponse = GetJsonWcSuccess | GetJsonWcError | GetJsonWcUnavailable;

export type GetJsonWcSuccess = {
  __typename?: 'GetJsonWcSuccess';
  rules: Array<Rule>;
  classCodes: Array<WcProfession>;
  isContractor: Scalars['Boolean'];
};

/** You should expect an empty object with __typename; GraphQL doesn't allow empty object so I need to define something */
export type GetJsonWcUnavailable = {
  __typename?: 'GetJsonWcUnavailable';
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type GetQuoteError = {
  __typename?: 'GetQuoteError';
  error?: Maybe<GetQuoteErrorType>;
};

export enum GetQuoteErrorType {
  NotFound = 'NotFound',
  RequestNotRegistered = 'RequestNotRegistered',
  DeclinedQuote = 'DeclinedQuote'
}

export type GetQuoteExpired = {
  __typename?: 'GetQuoteExpired';
  isQuoteExpired?: Maybe<Scalars['Boolean']>;
  isEffectiveDateExpired?: Maybe<Scalars['Boolean']>;
  quoteExpireDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type GetQuoteOk = {
  __typename?: 'GetQuoteOk';
  quotes: Array<Quote>;
  quoteInfo: QuoteInfo;
};

export type GetRatingResultsResponse = {
  __typename?: 'GetRatingResultsResponse';
  glPremium: Scalars['Int'];
  imPremium: Scalars['Int'];
  finalPremium: Scalars['Int'];
};

export enum IdType {
  Claim = 'Claim',
  Invoice = 'Invoice',
  Policy = 'Policy',
  Quote = 'Quote',
  Transaction = 'Transaction'
}


export type Limit = {
  __typename?: 'Limit';
  industry: Scalars['String'];
  min: Scalars['NonNegativeInt'];
  max: Scalars['NonNegativeInt'];
};

export type MailingAddressChange = {
  __typename?: 'MailingAddressChange';
  newAddress: UnderwritingAddress;
};

export type MunichEligibleQuoteResponse = {
  __typename?: 'MunichEligibleQuoteResponse';
  quoteId: Scalars['ObjectID'];
  premium: Scalars['Float'];
  quoteNumber: Scalars['String'];
  coveragesData: Array<CoveragesData>;
  region: Scalars['String'];
  showSendQuoteButton: Scalars['Boolean'];
};

export type MunichIneligibleQuoteResponse = {
  __typename?: 'MunichIneligibleQuoteResponse';
  selectedCoverages: Array<Scalars['String']>;
  region: Scalars['String'];
};

export type MunichResetCoverageRecommendationInput = {
  quoteId: Scalars['ObjectID'];
  applicationId: Scalars['ObjectID'];
  region: Scalars['String'];
};

export type MunichUpdateCoverageInput = {
  quoteId: Scalars['ObjectID'];
  applicationId: Scalars['ObjectID'];
  region: Scalars['String'];
  coverage: CoverageInput;
};

export type MunichUpdateCoverageOutput = {
  __typename?: 'MunichUpdateCoverageOutput';
  quoteId: Scalars['ObjectID'];
  finalPremium: Scalars['Int'];
  coveragesData: Array<CoveragesData>;
};

export type Mutation = {
  __typename?: 'Mutation';
  noOp?: Maybe<Scalars['Boolean']>;
  startWc?: Maybe<Scalars['ObjectID']>;
  createApplication: ApplicationResponse;
  updateApplication: UpdateApplicationResponse;
  completeApplication: CompletedApplicationResponse;
  cancelPolicy: Scalars['String'];
  checkApplicationDateValid: Scalars['Boolean'];
  updateEffectiveDate: Scalars['Boolean'];
  updateCoverage: UpdateCoverageOutput;
  resetCoverageRecommendation: UpdateCoverageOutput;
  sendQuoteLink: Scalars['Boolean'];
  updateApplicationAnswersAndQuote: UpdateApplicationAnswersAndQuoteResponse;
};


export type MutationStartWcArgs = {
  applicationObjectId: Scalars['ObjectID'];
  ssn?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicationArgs = {
  answersInfo: CreateApplicationAnswersInput;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  hubspotTracker?: Maybe<Scalars['String']>;
  policyFoxdenId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationTrigger?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  effectiveDateUTC?: Maybe<Scalars['String']>;
  transactionDateUTC?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  provinceOrState: Scalars['String'];
};


export type MutationUpdateApplicationArgs = {
  answersInfo: AnswersInput;
  hubspotTracker?: Maybe<Scalars['String']>;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  foxquiltBrokerId?: Maybe<Scalars['String']>;
  foxquiltAgencyId?: Maybe<Scalars['String']>;
  brokerCode?: Maybe<Scalars['String']>;
  agencyBrokerId?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['String']>;
  partnercode?: Maybe<Scalars['String']>;
};


export type MutationCompleteApplicationArgs = {
  answersInfo: AnswersWithIdInput;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
};


export type MutationCancelPolicyArgs = {
  quoteId: Scalars['ObjectID'];
};


export type MutationCheckApplicationDateValidArgs = {
  applicationId: Scalars['ObjectID'];
};


export type MutationUpdateEffectiveDateArgs = {
  applicationId: Scalars['ObjectID'];
  newEffectiveDate: Scalars['String'];
};


export type MutationUpdateCoverageArgs = {
  input: UpdateCoverageInput;
};


export type MutationResetCoverageRecommendationArgs = {
  input: ResetCoverageRecommendationInput;
};


export type MutationSendQuoteLinkArgs = {
  applicationId: Scalars['ObjectID'];
  primaryEmail: Scalars['String'];
  secondaryEmails: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateApplicationAnswersAndQuoteArgs = {
  applicationId: Scalars['ObjectID'];
  newEffectiveDate: Scalars['String'];
  newEmail: Scalars['String'];
  newPhoneNumber: Scalars['String'];
  newCompanyName: Scalars['String'];
  newDBAName?: Maybe<Scalars['String']>;
  additionalInsuredInfoList?: Maybe<Array<AdditionalInsured>>;
};

export type NamedInsuredChange = {
  __typename?: 'NamedInsuredChange';
  namedInsured: Scalars['String'];
};




export enum OhioChildCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL',
  /** Errors & Omissions */
  Eo = 'EO',
  /** Limited Pollution */
  LimitedPollution = 'LimitedPollution',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft',
  /** Terrorism Risk Insurance Act (TRIA) for GL */
  Triagl = 'TRIAGL'
}

export type OhioCoverageLimit = {
  __typename?: 'OhioCoverageLimit';
  coverage: OhioCoverageLimitType;
  oldLimit?: Maybe<Scalars['NonNegativeFloat']>;
  newLimit?: Maybe<Scalars['NonNegativeFloat']>;
};

/**
 * This type is mostly a copy of ChildCoverageType. However, it's needed since some child coverage has
 * multiple limits, like E&O which needs to distinguish between occurrence & aggregate limits.
 * Most limit names use the same one as the coverage name since they only have 1 limit.
 */
export enum OhioCoverageLimitType {
  /** Commercial General Liability (Aggregate) */
  CglAggregate = 'CGLAggregate',
  /** Commercial General Liability (Aggregate) */
  CglOccurrence = 'CGLOccurrence',
  /** Miscellaneous Errors & Omissions (Aggregate) */
  EoAggregate = 'EOAggregate',
  /** Miscellaneous Errors & Omissions (Occurrence) */
  EoOccurrence = 'EOOccurrence',
  /** Terrorism Risk Insurance Act (TRIA) for GL */
  Triagl = 'TRIAGL',
  /** Limited Pollution (Aggregate) */
  LimitedPollutionAggregate = 'LimitedPollutionAggregate',
  /** Limited Pollution (Occurrence) */
  LimitedPollutionOccurrence = 'LimitedPollutionOccurrence',
  /** Unmanned Aircraft */
  UnmannedAircraft = 'UnmannedAircraft'
}

export type OhioCoverageUpdateChange = {
  __typename?: 'OhioCoverageUpdateChange';
  coverage: OhioParentCoverageType;
  action: EndorsementCoverageAction;
  limits: Array<OhioCoverageLimit>;
};

export enum OhioParentCoverageType {
  /** Commercial General Liability */
  Cgl = 'CGL'
}

export type PolicyDates = {
  policyEffectiveDate: Scalars['Date'];
  policyExpirationDate: Scalars['Date'];
  transactionEffectiveDate: Scalars['Date'];
  transactionExpirationDate: Scalars['Date'];
};

export type PolicyDatesForRegionInput = {
  gl: PolicyDates;
  im: PolicyDates;
  region: Scalars['String'];
};

export type PolicyOptionsQueryStringData = {
  __typename?: 'PolicyOptionsQueryStringData';
  policyFoxdenId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationTrigger?: Maybe<Scalars['String']>;
};


export type Query = {
  __typename?: 'Query';
  noOp?: Maybe<Scalars['Boolean']>;
  tryGetWcQuestions: TryGetWcQuestionsReponse;
  sendCancellationEmail: Scalars['Boolean'];
  isEndorsementOrCancellation: IsEndorsementOrCancellationtResponse;
  sendEndorsementEmail: Scalars['Boolean'];
  /** TODO: Remove it when renewal is ready */
  getEndorsementQuerystringData: EndorsementQuerystringData;
  getPolicyOptionsQueryStringData: PolicyOptionsQueryStringData;
  getQuote: QuoteResponse;
  fetchQuoteForCancellation: Array<FetchQuoteResponse>;
  getRatingResults: GetRatingResultsResponse;
  getApplicationSummary: Scalars['String'];
  /**
   * Note: we want to calculate the unspent portion of the policy and refund to customers.
   * In other words, the need to calculate for the interval (curExpiryDate, prevExpiryDate);
   * whereas the interval (coverageStartDate, curExpiryDate) is the portion which is spent --
   * coverageStartDate comes from prevAnswers.
   */
  calCancellationQuote: Scalars['JSONObject'];
  /** Use this one to calculate premiums for endorsements. */
  calChangedQuote: CalChangedQuoteResult;
  /** Check if a person or the company they belong to are on the sactions list */
  checkSanctions: SanctionStatus;
  getJSONbyApplicationId: GetJsoNbyApplicationIdResponse;
  getFirstJSON: GetFirstJsonResponse;
  testQuoteEligibility: QuoteFlagResponse;
};


export type QueryTryGetWcQuestionsArgs = {
  applicationObjectId: Scalars['ObjectID'];
  wcSessionObjectId: Scalars['ObjectID'];
};


export type QuerySendCancellationEmailArgs = {
  quoteId: Scalars['String'];
};


export type QueryIsEndorsementOrCancellationArgs = {
  applicationId: Scalars['String'];
};


export type QuerySendEndorsementEmailArgs = {
  quoteId: Scalars['String'];
};


export type QueryGetEndorsementQuerystringDataArgs = {
  ipAddress: Scalars['String'];
  encryptedData?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyOptionsQueryStringDataArgs = {
  ipAddress: Scalars['String'];
  encryptedData?: Maybe<Scalars['String']>;
};


export type QueryGetQuoteArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryFetchQuoteForCancellationArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryGetRatingResultsArgs = {
  answers: Scalars['ConvertedAnswers'];
  policyDatesForRegion: PolicyDatesForRegionInput;
  quoteVersion: Scalars['String'];
};


export type QueryGetApplicationSummaryArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryCalCancellationQuoteArgs = {
  originalStartDate: Scalars['Date'];
  curExpiryDate: Scalars['Date'];
  prevAnswers: Scalars['Answers'];
  prevExpiryDate: Scalars['Date'];
  region: Scalars['String'];
};


export type QueryCalChangedQuoteArgs = {
  originalStartDate: Scalars['Date'];
  prevAnswers: Scalars['Answers'];
  prevExpiryDate: Scalars['Date'];
  prevStartDate: Scalars['Date'];
  curAnswers: Scalars['Answers'];
  region: Scalars['String'];
};


export type QueryCheckSanctionsArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  companyName: Scalars['String'];
};


export type QueryGetJsoNbyApplicationIdArgs = {
  applicationId: Scalars['ObjectID'];
};


export type QueryGetFirstJsonArgs = {
  policyFoxdenId?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  provinceOrState?: Maybe<Scalars['String']>;
};


export type QueryTestQuoteEligibilityArgs = {
  answers: Scalars['Answers'];
  jsonFileName: Scalars['String'];
  country: Scalars['String'];
};

export type Quote = MunichEligibleQuoteResponse | MunichIneligibleQuoteResponse | AmtrustIneligibleQuoteResponse | AmtrustEligibleQuoteResponse;

export type QuoteFlagResponse = {
  __typename?: 'QuoteFlagResponse';
  BusinessInformation_Broker: Scalars['Boolean'];
  BusinessInformation_MunichProduct: Scalars['Boolean'];
  Munich_9219_QuoteFlag: Scalars['Boolean'];
  declineQuote: Scalars['Boolean'];
  declineQuoteReason: Scalars['String'];
};

export type QuoteInfo = {
  __typename?: 'QuoteInfo';
  companyName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  coverageStartDate: Scalars['String'];
  quoteExpireDate: Scalars['String'];
  transactionType: Scalars['String'];
  carrierPartner: Scalars['String'];
};

export type QuoteResponse = GetQuoteOk | GetQuoteError | GetQuoteExpired;

export type ResetCoverageRecommendationInput = {
  munichInput?: Maybe<MunichResetCoverageRecommendationInput>;
  amtrustInput?: Maybe<AmtrustResetCoverageRecommendationInput>;
};

export type Rule = {
  __typename?: 'Rule';
  officer: Scalars['String'];
  limits: Array<Limit>;
  isIncludedByDefault: Scalars['Boolean'];
  canChooseToIncludeExclude: Scalars['Boolean'];
  note: Scalars['String'];
};

export enum SanctionStatus {
  SanctionFoundForPerson = 'SanctionFoundForPerson',
  SanctionFoundForCompany = 'SanctionFoundForCompany',
  SanctionNotFound = 'SanctionNotFound'
}

export type TryGetWcQuestionsReponse = {
  __typename?: 'TryGetWcQuestionsReponse';
  /** True if the quote cannot be binded by our partners. */
  isDecline: Scalars['Boolean'];
  /** Client has to keep polling if the status is 'NotFound' */
  status: TryGetWcQuestionsStatus;
  questions: Array<WcQuestion>;
};

export enum TryGetWcQuestionsStatus {
  Error = 'Error',
  Found = 'Found',
  NotFound = 'NotFound'
}



export type UnderwritingAddress = {
  __typename?: 'UnderwritingAddress';
  street: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['AbbrevCanadaProvince'];
  postalCode: Scalars['PostalCode'];
};

export type UpdateApplicationAnswersAndQuoteResponse = {
  __typename?: 'UpdateApplicationAnswersAndQuoteResponse';
  isUpdateSuccessful: Scalars['Boolean'];
  newQuoteId?: Maybe<Scalars['ObjectID']>;
};

export type UpdateApplicationResponse = ApplicationSuccess | ApplicationFailure | ApplicationBlocked;

export type UpdateCoverageInput = {
  munichInput?: Maybe<MunichUpdateCoverageInput>;
  amtrustInput?: Maybe<AmtrustUpdateCoverageInput>;
};

export type UpdateCoverageOutput = {
  __typename?: 'UpdateCoverageOutput';
  amtrustOutput?: Maybe<AmtrustUpdateCoverageOutput>;
  munichOutput?: Maybe<MunichUpdateCoverageOutput>;
};

/** This is an enum of most of the 2-letter abbreviations of US States. Some states are missing at this time but it's good enough for our purposes. */
export enum UsState {
  Ar = 'AR',
  Az = 'AZ',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Ga = 'GA',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wi = 'WI',
  Wy = 'WY'
}

export type WcAvailableLimit = {
  __typename?: 'WcAvailableLimit';
  perAccident: Scalars['NonNegativeInt'];
  overallIllness: Scalars['NonNegativeInt'];
  perEmployeeIllness: Scalars['NonNegativeInt'];
};

export type WcAvailableLimitInput = {
  perAccident: Scalars['NonNegativeInt'];
  overallIllness: Scalars['NonNegativeInt'];
  perEmployeeIllness: Scalars['NonNegativeInt'];
};

export type WcProfession = {
  __typename?: 'WcProfession';
  id: WcProfessionId;
  description: Scalars['String'];
};

export type WcProfessionId = {
  __typename?: 'WcProfessionId';
  classCode: Scalars['String'];
  descriptionCode: Scalars['String'];
};

export type WcProfessionIdInput = {
  classCode: Scalars['String'];
  descriptionCode: Scalars['String'];
};

export type WcQuestion = {
  __typename?: 'WcQuestion';
  id: Scalars['NonNegativeInt'];
  question: Scalars['String'];
};

export type CreateApplicationAnswersInput = {
  BusinessInformation_100_Country_WORLD_EN: Scalars['String'];
  BusinessInformation_100_EffectiveDate_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_Province_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_State_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_PrimaryProfession_WORLD_EN: Scalars['String'];
  primaryProfessionLabel: Scalars['String'];
  BusinessInformation_100_CustomerInfo_WORLD_EN: CustomerInfoInput;
  BusinessInformation_100_SecondaryProfessions_WORLD_EN?: Maybe<Array<Scalars['String']>>;
  secondaryProfessionsLabels?: Maybe<Array<Scalars['String']>>;
  BusinessInformation_100_OtherProfessionCheck_WORLD_EN?: Maybe<Scalars['Boolean']>;
  BusinessInformation_100_SecondaryProfessionAdditional_WORLD_EN?: Maybe<Array<Scalars['JSONObject']>>;
  otherProfessionsLabels?: Maybe<Array<Scalars['String']>>;
  BusinessInformation_100_ProfessionsPercentage_WORLD_EN?: Maybe<Scalars['JSONObject']>;
  BusinessInformation_100_PartnerCode_WORLD_EN?: Maybe<Scalars['String']>;
  BusinessInformation_100_BrokerCode_WORLD_EN?: Maybe<Scalars['String']>;
  foxquiltAgencyId?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['String']>;
  foxquiltBrokerId?: Maybe<Scalars['String']>;
  agencyBrokerId?: Maybe<Scalars['String']>;
};

export type GetFirstJsonResponse = {
  __typename?: 'getFirstJSONResponse';
  json: Scalars['JSONObject'];
  previousAnswers?: Maybe<Scalars['ConvertedAnswers']>;
  policyStartDateStr?: Maybe<Scalars['String']>;
  policyExpiryDateStr?: Maybe<Scalars['String']>;
  isStateActive?: Maybe<Scalars['Boolean']>;
};

export type IsEndorsementOrCancellationtResponse = {
  __typename?: 'isEndorsementOrCancellationtResponse';
  isEndorsement: Scalars['Boolean'];
  isCancellation: Scalars['String'];
};

export type CheckApplicationDateValidMutationVariables = Exact<{
  applicationId: Scalars['ObjectID'];
}>;


export type CheckApplicationDateValidMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkApplicationDateValid'>
);

export type CompleteApplicationMutationVariables = Exact<{
  answersInfo: AnswersWithIdInput;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
}>;


export type CompleteApplicationMutation = (
  { __typename?: 'Mutation' }
  & { completeApplication: (
    { __typename?: 'CompletedApplicationResponse' }
    & Pick<CompletedApplicationResponse, 'applicationId' | 'errors' | 'kind'>
  ) }
);

export type CreateApplicationMutationVariables = Exact<{
  answersInfo: CreateApplicationAnswersInput;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  hubspotTracker?: Maybe<Scalars['String']>;
  policyFoxdenId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationTrigger?: Maybe<Scalars['String']>;
  effectiveDateUTC?: Maybe<Scalars['String']>;
  transactionDateUTC?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  country: Scalars['String'];
  provinceOrState: Scalars['String'];
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createApplication: (
    { __typename?: 'ApplicationSuccess' }
    & Pick<ApplicationSuccess, 'applicationId'>
  ) | (
    { __typename?: 'ApplicationFailure' }
    & Pick<ApplicationFailure, 'error'>
  ) }
);

export type GetFirstJsonQueryVariables = Exact<{
  policyFoxdenId?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  provinceOrState?: Maybe<Scalars['String']>;
}>;


export type GetFirstJsonQuery = (
  { __typename?: 'Query' }
  & { getFirstJSON: (
    { __typename?: 'getFirstJSONResponse' }
    & Pick<GetFirstJsonResponse, 'json' | 'previousAnswers' | 'policyStartDateStr' | 'policyExpiryDateStr' | 'isStateActive'>
  ) }
);

export type GetJsoNbyApplicationIdQueryVariables = Exact<{
  applicationId: Scalars['ObjectID'];
}>;


export type GetJsoNbyApplicationIdQuery = (
  { __typename?: 'Query' }
  & { getJSONbyApplicationId: (
    { __typename?: 'GetJSONbyApplicationIdResponse' }
    & Pick<GetJsoNbyApplicationIdResponse, 'json' | 'professionList' | 'state' | 'previousAnswers' | 'policyStartDateStr' | 'policyExpiryDateStr' | 'transactionType' | 'carrierPartner' | 'displayWcQuestions'>
    & { customerInfo: (
      { __typename?: 'CustomerInfo' }
      & Pick<CustomerInfo, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
    ), wcData?: Maybe<(
      { __typename?: 'GetJsonWcSuccess' }
      & Pick<GetJsonWcSuccess, 'isContractor'>
      & { classCodes: Array<(
        { __typename?: 'WcProfession' }
        & Pick<WcProfession, 'description'>
        & { id: (
          { __typename?: 'WcProfessionId' }
          & Pick<WcProfessionId, 'classCode' | 'descriptionCode'>
        ) }
      )>, rules: Array<(
        { __typename?: 'Rule' }
        & Pick<Rule, 'officer' | 'isIncludedByDefault' | 'canChooseToIncludeExclude' | 'note'>
        & { limits: Array<(
          { __typename?: 'Limit' }
          & Pick<Limit, 'industry' | 'min' | 'max'>
        )> }
      )> }
    ) | (
      { __typename?: 'GetJsonWcError' }
      & Pick<GetJsonWcError, 'message'>
    ) | { __typename?: 'GetJsonWcUnavailable' }> }
  ) }
);

export type GetEndorsementQuerystringDataQueryVariables = Exact<{
  ipAddress: Scalars['String'];
  encryptedData?: Maybe<Scalars['String']>;
}>;


export type GetEndorsementQuerystringDataQuery = (
  { __typename?: 'Query' }
  & { getEndorsementQuerystringData: (
    { __typename?: 'EndorsementQuerystringData' }
    & Pick<EndorsementQuerystringData, 'policyFoxdenId' | 'transactionType' | 'cancellationReason' | 'cancellationTrigger'>
  ) }
);

export type GetPolicyOptionsQueryStringDataQueryVariables = Exact<{
  ipAddress: Scalars['String'];
  encryptedData?: Maybe<Scalars['String']>;
}>;


export type GetPolicyOptionsQueryStringDataQuery = (
  { __typename?: 'Query' }
  & { getPolicyOptionsQueryStringData: (
    { __typename?: 'PolicyOptionsQueryStringData' }
    & Pick<PolicyOptionsQueryStringData, 'policyFoxdenId' | 'cancellationReason' | 'cancellationTrigger'>
  ) }
);

export type GetQuoteQueryVariables = Exact<{
  applicationId: Scalars['ObjectID'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { getQuote: (
    { __typename: 'GetQuoteOk' }
    & { quotes: Array<(
      { __typename: 'MunichEligibleQuoteResponse' }
      & Pick<MunichEligibleQuoteResponse, 'premium' | 'quoteId' | 'quoteNumber' | 'region' | 'showSendQuoteButton'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name'>
      )> }
    ) | (
      { __typename: 'MunichIneligibleQuoteResponse' }
      & Pick<MunichIneligibleQuoteResponse, 'selectedCoverages' | 'region'>
    ) | { __typename: 'AmtrustIneligibleQuoteResponse' } | (
      { __typename: 'AmtrustEligibleQuoteResponse' }
      & Pick<AmtrustEligibleQuoteResponse, 'proposalDownloadLink' | 'premium' | 'feinIsValid' | 'amtrustquoteId'>
      & { limit: (
        { __typename?: 'WcAvailableLimit' }
        & Pick<WcAvailableLimit, 'overallIllness' | 'perAccident' | 'perEmployeeIllness'>
      ), availableLimit: Array<(
        { __typename?: 'WcAvailableLimit' }
        & Pick<WcAvailableLimit, 'overallIllness' | 'perAccident' | 'perEmployeeIllness'>
      )> }
    )>, quoteInfo: (
      { __typename?: 'QuoteInfo' }
      & Pick<QuoteInfo, 'companyName' | 'email' | 'phoneNumber' | 'coverageStartDate' | 'quoteExpireDate' | 'transactionType' | 'carrierPartner'>
    ) }
  ) | (
    { __typename: 'GetQuoteError' }
    & Pick<GetQuoteError, 'error'>
  ) | (
    { __typename: 'GetQuoteExpired' }
    & Pick<GetQuoteExpired, 'isQuoteExpired' | 'isEffectiveDateExpired' | 'quoteExpireDate' | 'country'>
  ) }
);

export type IsEndorsementOrCancellationQueryVariables = Exact<{
  applicationId: Scalars['String'];
}>;


export type IsEndorsementOrCancellationQuery = (
  { __typename?: 'Query' }
  & { isEndorsementOrCancellation: (
    { __typename?: 'isEndorsementOrCancellationtResponse' }
    & Pick<IsEndorsementOrCancellationtResponse, 'isEndorsement' | 'isCancellation'>
  ) }
);

export type ResetCoverageRecommendationMutationVariables = Exact<{
  input: ResetCoverageRecommendationInput;
}>;


export type ResetCoverageRecommendationMutation = (
  { __typename?: 'Mutation' }
  & { resetCoverageRecommendation: (
    { __typename?: 'UpdateCoverageOutput' }
    & { amtrustOutput?: Maybe<(
      { __typename?: 'AmtrustUpdateCoverageOutput' }
      & Pick<AmtrustUpdateCoverageOutput, 'premium' | 'feinIsValid' | 'wcLimit'>
    )>, munichOutput?: Maybe<(
      { __typename?: 'MunichUpdateCoverageOutput' }
      & Pick<MunichUpdateCoverageOutput, 'finalPremium' | 'quoteId'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name'>
      )> }
    )> }
  ) }
);

export type SendCancellationEmailQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type SendCancellationEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendCancellationEmail'>
);

export type SendEndorsementEmailQueryVariables = Exact<{
  quoteId: Scalars['String'];
}>;


export type SendEndorsementEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendEndorsementEmail'>
);

export type SendQuoteLinkMutationVariables = Exact<{
  applicationId: Scalars['ObjectID'];
  secondaryEmails: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  primaryEmail: Scalars['String'];
}>;


export type SendQuoteLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendQuoteLink'>
);

export type StartWcMutationVariables = Exact<{
  applicationObjectId: Scalars['ObjectID'];
  ssn?: Maybe<Scalars['String']>;
}>;


export type StartWcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startWc'>
);

export type TryGetWcQuestionsQueryVariables = Exact<{
  applicationObjectId: Scalars['ObjectID'];
  wcSessionObjectId: Scalars['ObjectID'];
}>;


export type TryGetWcQuestionsQuery = (
  { __typename?: 'Query' }
  & { tryGetWcQuestions: (
    { __typename?: 'TryGetWcQuestionsReponse' }
    & Pick<TryGetWcQuestionsReponse, 'isDecline' | 'status'>
    & { questions: Array<(
      { __typename?: 'WcQuestion' }
      & Pick<WcQuestion, 'id' | 'question'>
    )> }
  ) }
);

export type UpdateApplicationMutationVariables = Exact<{
  answersInfo: AnswersInput;
  hubspotTracker?: Maybe<Scalars['String']>;
  pageName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  foxquiltBrokerId?: Maybe<Scalars['String']>;
  foxquiltAgencyId?: Maybe<Scalars['String']>;
  brokerCode?: Maybe<Scalars['String']>;
  agencyBrokerId?: Maybe<Scalars['String']>;
  agencyId?: Maybe<Scalars['String']>;
  partnercode?: Maybe<Scalars['String']>;
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { updateApplication: (
    { __typename?: 'ApplicationSuccess' }
    & Pick<ApplicationSuccess, 'applicationId'>
  ) | (
    { __typename?: 'ApplicationFailure' }
    & Pick<ApplicationFailure, 'error'>
  ) | { __typename?: 'ApplicationBlocked' } }
);

export type UpdateApplicationAnswersAndQuoteMutationVariables = Exact<{
  applicationId: Scalars['ObjectID'];
  newEffectiveDate: Scalars['String'];
  newEmail: Scalars['String'];
  newPhoneNumber: Scalars['String'];
  newCompanyName: Scalars['String'];
  newDBAName?: Maybe<Scalars['String']>;
  additionalInsuredInfoList?: Maybe<Array<AdditionalInsured> | AdditionalInsured>;
}>;


export type UpdateApplicationAnswersAndQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicationAnswersAndQuote: (
    { __typename?: 'UpdateApplicationAnswersAndQuoteResponse' }
    & Pick<UpdateApplicationAnswersAndQuoteResponse, 'isUpdateSuccessful' | 'newQuoteId'>
  ) }
);

export type UpdateCoverageMutationVariables = Exact<{
  input: UpdateCoverageInput;
}>;


export type UpdateCoverageMutation = (
  { __typename?: 'Mutation' }
  & { updateCoverage: (
    { __typename?: 'UpdateCoverageOutput' }
    & { amtrustOutput?: Maybe<(
      { __typename?: 'AmtrustUpdateCoverageOutput' }
      & Pick<AmtrustUpdateCoverageOutput, 'premium' | 'feinIsValid'>
    )>, munichOutput?: Maybe<(
      { __typename?: 'MunichUpdateCoverageOutput' }
      & Pick<MunichUpdateCoverageOutput, 'finalPremium' | 'quoteId'>
      & { coveragesData: Array<(
        { __typename?: 'CoveragesData' }
        & Pick<CoveragesData, 'chooseCoverage' | 'finalPremium' | 'limits' | 'name'>
      )> }
    )> }
  ) }
);

export type UpdateEffectiveDateMutationVariables = Exact<{
  applicationId: Scalars['ObjectID'];
  newEffectiveDate: Scalars['String'];
}>;


export type UpdateEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEffectiveDate'>
);


export const CheckApplicationDateValidDocument = gql`
    mutation checkApplicationDateValid($applicationId: ObjectID!) {
  checkApplicationDateValid(applicationId: $applicationId)
}
    `;
export type CheckApplicationDateValidMutationFn = ApolloReactCommon.MutationFunction<CheckApplicationDateValidMutation, CheckApplicationDateValidMutationVariables>;

/**
 * __useCheckApplicationDateValidMutation__
 *
 * To run a mutation, you first call `useCheckApplicationDateValidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckApplicationDateValidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkApplicationDateValidMutation, { data, loading, error }] = useCheckApplicationDateValidMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCheckApplicationDateValidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckApplicationDateValidMutation, CheckApplicationDateValidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CheckApplicationDateValidMutation, CheckApplicationDateValidMutationVariables>(CheckApplicationDateValidDocument, options);
      }
export type CheckApplicationDateValidMutationHookResult = ReturnType<typeof useCheckApplicationDateValidMutation>;
export type CheckApplicationDateValidMutationResult = ApolloReactCommon.MutationResult<CheckApplicationDateValidMutation>;
export type CheckApplicationDateValidMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckApplicationDateValidMutation, CheckApplicationDateValidMutationVariables>;
export const CompleteApplicationDocument = gql`
    mutation completeApplication($answersInfo: AnswersWithIdInput!, $pageName: String!, $groupName: String) {
  completeApplication(
    answersInfo: $answersInfo
    pageName: $pageName
    groupName: $groupName
  ) {
    applicationId
    errors
    kind
  }
}
    `;
export type CompleteApplicationMutationFn = ApolloReactCommon.MutationFunction<CompleteApplicationMutation, CompleteApplicationMutationVariables>;

/**
 * __useCompleteApplicationMutation__
 *
 * To run a mutation, you first call `useCompleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeApplicationMutation, { data, loading, error }] = useCompleteApplicationMutation({
 *   variables: {
 *      answersInfo: // value for 'answersInfo'
 *      pageName: // value for 'pageName'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useCompleteApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteApplicationMutation, CompleteApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CompleteApplicationMutation, CompleteApplicationMutationVariables>(CompleteApplicationDocument, options);
      }
export type CompleteApplicationMutationHookResult = ReturnType<typeof useCompleteApplicationMutation>;
export type CompleteApplicationMutationResult = ApolloReactCommon.MutationResult<CompleteApplicationMutation>;
export type CompleteApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteApplicationMutation, CompleteApplicationMutationVariables>;
export const CreateApplicationDocument = gql`
    mutation createApplication($answersInfo: createApplicationAnswersInput!, $pageName: String!, $groupName: String, $hubspotTracker: String, $policyFoxdenId: String, $cancellationReason: String, $cancellationTrigger: String, $effectiveDateUTC: String, $transactionDateUTC: String, $transactionType: String!, $country: String!, $provinceOrState: String!) {
  createApplication(
    answersInfo: $answersInfo
    pageName: $pageName
    groupName: $groupName
    hubspotTracker: $hubspotTracker
    policyFoxdenId: $policyFoxdenId
    cancellationReason: $cancellationReason
    cancellationTrigger: $cancellationTrigger
    effectiveDateUTC: $effectiveDateUTC
    transactionDateUTC: $transactionDateUTC
    transactionType: $transactionType
    country: $country
    provinceOrState: $provinceOrState
  ) {
    ... on ApplicationSuccess {
      applicationId
    }
    ... on ApplicationFailure {
      error
    }
  }
}
    `;
export type CreateApplicationMutationFn = ApolloReactCommon.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      answersInfo: // value for 'answersInfo'
 *      pageName: // value for 'pageName'
 *      groupName: // value for 'groupName'
 *      hubspotTracker: // value for 'hubspotTracker'
 *      policyFoxdenId: // value for 'policyFoxdenId'
 *      cancellationReason: // value for 'cancellationReason'
 *      cancellationTrigger: // value for 'cancellationTrigger'
 *      effectiveDateUTC: // value for 'effectiveDateUTC'
 *      transactionDateUTC: // value for 'transactionDateUTC'
 *      transactionType: // value for 'transactionType'
 *      country: // value for 'country'
 *      provinceOrState: // value for 'provinceOrState'
 *   },
 * });
 */
export function useCreateApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, options);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = ApolloReactCommon.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const GetFirstJsonDocument = gql`
    query getFirstJSON($policyFoxdenId: String, $effectiveDate: String, $transactionDate: String, $timezone: String, $transactionType: String!, $country: String, $provinceOrState: String) {
  getFirstJSON(
    policyFoxdenId: $policyFoxdenId
    effectiveDate: $effectiveDate
    transactionDate: $transactionDate
    timezone: $timezone
    transactionType: $transactionType
    country: $country
    provinceOrState: $provinceOrState
  ) {
    json
    previousAnswers
    policyStartDateStr
    policyExpiryDateStr
    isStateActive
  }
}
    `;

/**
 * __useGetFirstJsonQuery__
 *
 * To run a query within a React component, call `useGetFirstJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstJsonQuery({
 *   variables: {
 *      policyFoxdenId: // value for 'policyFoxdenId'
 *      effectiveDate: // value for 'effectiveDate'
 *      transactionDate: // value for 'transactionDate'
 *      timezone: // value for 'timezone'
 *      transactionType: // value for 'transactionType'
 *      country: // value for 'country'
 *      provinceOrState: // value for 'provinceOrState'
 *   },
 * });
 */
export function useGetFirstJsonQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetFirstJsonQuery, GetFirstJsonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetFirstJsonQuery, GetFirstJsonQueryVariables>(GetFirstJsonDocument, options);
      }
export function useGetFirstJsonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFirstJsonQuery, GetFirstJsonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetFirstJsonQuery, GetFirstJsonQueryVariables>(GetFirstJsonDocument, options);
        }
export type GetFirstJsonQueryHookResult = ReturnType<typeof useGetFirstJsonQuery>;
export type GetFirstJsonLazyQueryHookResult = ReturnType<typeof useGetFirstJsonLazyQuery>;
export type GetFirstJsonQueryResult = ApolloReactCommon.QueryResult<GetFirstJsonQuery, GetFirstJsonQueryVariables>;
export const GetJsoNbyApplicationIdDocument = gql`
    query getJSONbyApplicationId($applicationId: ObjectID!) {
  getJSONbyApplicationId(applicationId: $applicationId) {
    json
    professionList
    customerInfo {
      firstName
      lastName
      email
      phoneNumber
    }
    state
    previousAnswers
    policyStartDateStr
    policyExpiryDateStr
    wcData {
      ... on GetJsonWcSuccess {
        classCodes {
          id {
            classCode
            descriptionCode
          }
          description
        }
        rules {
          officer
          limits {
            industry
            min
            max
          }
          isIncludedByDefault
          canChooseToIncludeExclude
          note
        }
        isContractor
      }
      ... on GetJsonWcError {
        message
      }
    }
    transactionType
    carrierPartner
    displayWcQuestions
  }
}
    `;

/**
 * __useGetJsoNbyApplicationIdQuery__
 *
 * To run a query within a React component, call `useGetJsoNbyApplicationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJsoNbyApplicationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJsoNbyApplicationIdQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetJsoNbyApplicationIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetJsoNbyApplicationIdQuery, GetJsoNbyApplicationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetJsoNbyApplicationIdQuery, GetJsoNbyApplicationIdQueryVariables>(GetJsoNbyApplicationIdDocument, options);
      }
export function useGetJsoNbyApplicationIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJsoNbyApplicationIdQuery, GetJsoNbyApplicationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetJsoNbyApplicationIdQuery, GetJsoNbyApplicationIdQueryVariables>(GetJsoNbyApplicationIdDocument, options);
        }
export type GetJsoNbyApplicationIdQueryHookResult = ReturnType<typeof useGetJsoNbyApplicationIdQuery>;
export type GetJsoNbyApplicationIdLazyQueryHookResult = ReturnType<typeof useGetJsoNbyApplicationIdLazyQuery>;
export type GetJsoNbyApplicationIdQueryResult = ApolloReactCommon.QueryResult<GetJsoNbyApplicationIdQuery, GetJsoNbyApplicationIdQueryVariables>;
export const GetEndorsementQuerystringDataDocument = gql`
    query getEndorsementQuerystringData($ipAddress: String!, $encryptedData: String) {
  getEndorsementQuerystringData(
    ipAddress: $ipAddress
    encryptedData: $encryptedData
  ) {
    policyFoxdenId
    transactionType
    cancellationReason
    cancellationTrigger
  }
}
    `;

/**
 * __useGetEndorsementQuerystringDataQuery__
 *
 * To run a query within a React component, call `useGetEndorsementQuerystringDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndorsementQuerystringDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndorsementQuerystringDataQuery({
 *   variables: {
 *      ipAddress: // value for 'ipAddress'
 *      encryptedData: // value for 'encryptedData'
 *   },
 * });
 */
export function useGetEndorsementQuerystringDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEndorsementQuerystringDataQuery, GetEndorsementQuerystringDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEndorsementQuerystringDataQuery, GetEndorsementQuerystringDataQueryVariables>(GetEndorsementQuerystringDataDocument, options);
      }
export function useGetEndorsementQuerystringDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEndorsementQuerystringDataQuery, GetEndorsementQuerystringDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEndorsementQuerystringDataQuery, GetEndorsementQuerystringDataQueryVariables>(GetEndorsementQuerystringDataDocument, options);
        }
export type GetEndorsementQuerystringDataQueryHookResult = ReturnType<typeof useGetEndorsementQuerystringDataQuery>;
export type GetEndorsementQuerystringDataLazyQueryHookResult = ReturnType<typeof useGetEndorsementQuerystringDataLazyQuery>;
export type GetEndorsementQuerystringDataQueryResult = ApolloReactCommon.QueryResult<GetEndorsementQuerystringDataQuery, GetEndorsementQuerystringDataQueryVariables>;
export const GetPolicyOptionsQueryStringDataDocument = gql`
    query getPolicyOptionsQueryStringData($ipAddress: String!, $encryptedData: String) {
  getPolicyOptionsQueryStringData(
    ipAddress: $ipAddress
    encryptedData: $encryptedData
  ) {
    policyFoxdenId
    cancellationReason
    cancellationTrigger
  }
}
    `;

/**
 * __useGetPolicyOptionsQueryStringDataQuery__
 *
 * To run a query within a React component, call `useGetPolicyOptionsQueryStringDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyOptionsQueryStringDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyOptionsQueryStringDataQuery({
 *   variables: {
 *      ipAddress: // value for 'ipAddress'
 *      encryptedData: // value for 'encryptedData'
 *   },
 * });
 */
export function useGetPolicyOptionsQueryStringDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPolicyOptionsQueryStringDataQuery, GetPolicyOptionsQueryStringDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPolicyOptionsQueryStringDataQuery, GetPolicyOptionsQueryStringDataQueryVariables>(GetPolicyOptionsQueryStringDataDocument, options);
      }
export function useGetPolicyOptionsQueryStringDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyOptionsQueryStringDataQuery, GetPolicyOptionsQueryStringDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPolicyOptionsQueryStringDataQuery, GetPolicyOptionsQueryStringDataQueryVariables>(GetPolicyOptionsQueryStringDataDocument, options);
        }
export type GetPolicyOptionsQueryStringDataQueryHookResult = ReturnType<typeof useGetPolicyOptionsQueryStringDataQuery>;
export type GetPolicyOptionsQueryStringDataLazyQueryHookResult = ReturnType<typeof useGetPolicyOptionsQueryStringDataLazyQuery>;
export type GetPolicyOptionsQueryStringDataQueryResult = ApolloReactCommon.QueryResult<GetPolicyOptionsQueryStringDataQuery, GetPolicyOptionsQueryStringDataQueryVariables>;
export const GetQuoteDocument = gql`
    query getQuote($applicationId: ObjectID!) {
  getQuote(applicationId: $applicationId) {
    ... on GetQuoteOk {
      __typename
      quotes {
        ... on AmtrustEligibleQuoteResponse {
          __typename
          proposalDownloadLink
          premium
          feinIsValid
          amtrustquoteId
          limit {
            overallIllness
            perAccident
            perEmployeeIllness
          }
          availableLimit {
            overallIllness
            perAccident
            perEmployeeIllness
          }
        }
        ... on AmtrustIneligibleQuoteResponse {
          __typename
        }
        ... on MunichEligibleQuoteResponse {
          premium
          quoteId
          quoteNumber
          __typename
          region
          showSendQuoteButton
          coveragesData {
            chooseCoverage
            finalPremium
            limits
            name
          }
        }
        ... on MunichIneligibleQuoteResponse {
          __typename
          selectedCoverages
          region
        }
      }
      quoteInfo {
        companyName
        email
        phoneNumber
        coverageStartDate
        quoteExpireDate
        transactionType
        carrierPartner
      }
    }
    ... on GetQuoteError {
      __typename
      error
    }
    ... on GetQuoteExpired {
      __typename
      isQuoteExpired
      isEffectiveDateExpired
      quoteExpireDate
      country
    }
  }
}
    `;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = ApolloReactCommon.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export const IsEndorsementOrCancellationDocument = gql`
    query isEndorsementOrCancellation($applicationId: String!) {
  isEndorsementOrCancellation(applicationId: $applicationId) {
    isEndorsement
    isCancellation
  }
}
    `;

/**
 * __useIsEndorsementOrCancellationQuery__
 *
 * To run a query within a React component, call `useIsEndorsementOrCancellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEndorsementOrCancellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEndorsementOrCancellationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useIsEndorsementOrCancellationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IsEndorsementOrCancellationQuery, IsEndorsementOrCancellationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IsEndorsementOrCancellationQuery, IsEndorsementOrCancellationQueryVariables>(IsEndorsementOrCancellationDocument, options);
      }
export function useIsEndorsementOrCancellationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEndorsementOrCancellationQuery, IsEndorsementOrCancellationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IsEndorsementOrCancellationQuery, IsEndorsementOrCancellationQueryVariables>(IsEndorsementOrCancellationDocument, options);
        }
export type IsEndorsementOrCancellationQueryHookResult = ReturnType<typeof useIsEndorsementOrCancellationQuery>;
export type IsEndorsementOrCancellationLazyQueryHookResult = ReturnType<typeof useIsEndorsementOrCancellationLazyQuery>;
export type IsEndorsementOrCancellationQueryResult = ApolloReactCommon.QueryResult<IsEndorsementOrCancellationQuery, IsEndorsementOrCancellationQueryVariables>;
export const ResetCoverageRecommendationDocument = gql`
    mutation resetCoverageRecommendation($input: ResetCoverageRecommendationInput!) {
  resetCoverageRecommendation(input: $input) {
    amtrustOutput {
      premium
      feinIsValid
      wcLimit
    }
    munichOutput {
      coveragesData {
        chooseCoverage
        finalPremium
        limits
        name
      }
      finalPremium
      quoteId
    }
  }
}
    `;
export type ResetCoverageRecommendationMutationFn = ApolloReactCommon.MutationFunction<ResetCoverageRecommendationMutation, ResetCoverageRecommendationMutationVariables>;

/**
 * __useResetCoverageRecommendationMutation__
 *
 * To run a mutation, you first call `useResetCoverageRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCoverageRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCoverageRecommendationMutation, { data, loading, error }] = useResetCoverageRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetCoverageRecommendationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetCoverageRecommendationMutation, ResetCoverageRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetCoverageRecommendationMutation, ResetCoverageRecommendationMutationVariables>(ResetCoverageRecommendationDocument, options);
      }
export type ResetCoverageRecommendationMutationHookResult = ReturnType<typeof useResetCoverageRecommendationMutation>;
export type ResetCoverageRecommendationMutationResult = ApolloReactCommon.MutationResult<ResetCoverageRecommendationMutation>;
export type ResetCoverageRecommendationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetCoverageRecommendationMutation, ResetCoverageRecommendationMutationVariables>;
export const SendCancellationEmailDocument = gql`
    query sendCancellationEmail($quoteId: String!) {
  sendCancellationEmail(quoteId: $quoteId)
}
    `;

/**
 * __useSendCancellationEmailQuery__
 *
 * To run a query within a React component, call `useSendCancellationEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendCancellationEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendCancellationEmailQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useSendCancellationEmailQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SendCancellationEmailQuery, SendCancellationEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SendCancellationEmailQuery, SendCancellationEmailQueryVariables>(SendCancellationEmailDocument, options);
      }
export function useSendCancellationEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendCancellationEmailQuery, SendCancellationEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SendCancellationEmailQuery, SendCancellationEmailQueryVariables>(SendCancellationEmailDocument, options);
        }
export type SendCancellationEmailQueryHookResult = ReturnType<typeof useSendCancellationEmailQuery>;
export type SendCancellationEmailLazyQueryHookResult = ReturnType<typeof useSendCancellationEmailLazyQuery>;
export type SendCancellationEmailQueryResult = ApolloReactCommon.QueryResult<SendCancellationEmailQuery, SendCancellationEmailQueryVariables>;
export const SendEndorsementEmailDocument = gql`
    query sendEndorsementEmail($quoteId: String!) {
  sendEndorsementEmail(quoteId: $quoteId)
}
    `;

/**
 * __useSendEndorsementEmailQuery__
 *
 * To run a query within a React component, call `useSendEndorsementEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendEndorsementEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendEndorsementEmailQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useSendEndorsementEmailQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SendEndorsementEmailQuery, SendEndorsementEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SendEndorsementEmailQuery, SendEndorsementEmailQueryVariables>(SendEndorsementEmailDocument, options);
      }
export function useSendEndorsementEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendEndorsementEmailQuery, SendEndorsementEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SendEndorsementEmailQuery, SendEndorsementEmailQueryVariables>(SendEndorsementEmailDocument, options);
        }
export type SendEndorsementEmailQueryHookResult = ReturnType<typeof useSendEndorsementEmailQuery>;
export type SendEndorsementEmailLazyQueryHookResult = ReturnType<typeof useSendEndorsementEmailLazyQuery>;
export type SendEndorsementEmailQueryResult = ApolloReactCommon.QueryResult<SendEndorsementEmailQuery, SendEndorsementEmailQueryVariables>;
export const SendQuoteLinkDocument = gql`
    mutation sendQuoteLink($applicationId: ObjectID!, $secondaryEmails: [String]!, $primaryEmail: String!) {
  sendQuoteLink(
    applicationId: $applicationId
    secondaryEmails: $secondaryEmails
    primaryEmail: $primaryEmail
  )
}
    `;
export type SendQuoteLinkMutationFn = ApolloReactCommon.MutationFunction<SendQuoteLinkMutation, SendQuoteLinkMutationVariables>;

/**
 * __useSendQuoteLinkMutation__
 *
 * To run a mutation, you first call `useSendQuoteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQuoteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQuoteLinkMutation, { data, loading, error }] = useSendQuoteLinkMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      secondaryEmails: // value for 'secondaryEmails'
 *      primaryEmail: // value for 'primaryEmail'
 *   },
 * });
 */
export function useSendQuoteLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendQuoteLinkMutation, SendQuoteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendQuoteLinkMutation, SendQuoteLinkMutationVariables>(SendQuoteLinkDocument, options);
      }
export type SendQuoteLinkMutationHookResult = ReturnType<typeof useSendQuoteLinkMutation>;
export type SendQuoteLinkMutationResult = ApolloReactCommon.MutationResult<SendQuoteLinkMutation>;
export type SendQuoteLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<SendQuoteLinkMutation, SendQuoteLinkMutationVariables>;
export const StartWcDocument = gql`
    mutation startWc($applicationObjectId: ObjectID!, $ssn: String) {
  startWc(applicationObjectId: $applicationObjectId, ssn: $ssn)
}
    `;
export type StartWcMutationFn = ApolloReactCommon.MutationFunction<StartWcMutation, StartWcMutationVariables>;

/**
 * __useStartWcMutation__
 *
 * To run a mutation, you first call `useStartWcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartWcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startWcMutation, { data, loading, error }] = useStartWcMutation({
 *   variables: {
 *      applicationObjectId: // value for 'applicationObjectId'
 *      ssn: // value for 'ssn'
 *   },
 * });
 */
export function useStartWcMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartWcMutation, StartWcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartWcMutation, StartWcMutationVariables>(StartWcDocument, options);
      }
export type StartWcMutationHookResult = ReturnType<typeof useStartWcMutation>;
export type StartWcMutationResult = ApolloReactCommon.MutationResult<StartWcMutation>;
export type StartWcMutationOptions = ApolloReactCommon.BaseMutationOptions<StartWcMutation, StartWcMutationVariables>;
export const TryGetWcQuestionsDocument = gql`
    query tryGetWcQuestions($applicationObjectId: ObjectID!, $wcSessionObjectId: ObjectID!) {
  tryGetWcQuestions(
    applicationObjectId: $applicationObjectId
    wcSessionObjectId: $wcSessionObjectId
  ) {
    isDecline
    status
    questions {
      id
      question
    }
  }
}
    `;

/**
 * __useTryGetWcQuestionsQuery__
 *
 * To run a query within a React component, call `useTryGetWcQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTryGetWcQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTryGetWcQuestionsQuery({
 *   variables: {
 *      applicationObjectId: // value for 'applicationObjectId'
 *      wcSessionObjectId: // value for 'wcSessionObjectId'
 *   },
 * });
 */
export function useTryGetWcQuestionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TryGetWcQuestionsQuery, TryGetWcQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TryGetWcQuestionsQuery, TryGetWcQuestionsQueryVariables>(TryGetWcQuestionsDocument, options);
      }
export function useTryGetWcQuestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TryGetWcQuestionsQuery, TryGetWcQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TryGetWcQuestionsQuery, TryGetWcQuestionsQueryVariables>(TryGetWcQuestionsDocument, options);
        }
export type TryGetWcQuestionsQueryHookResult = ReturnType<typeof useTryGetWcQuestionsQuery>;
export type TryGetWcQuestionsLazyQueryHookResult = ReturnType<typeof useTryGetWcQuestionsLazyQuery>;
export type TryGetWcQuestionsQueryResult = ApolloReactCommon.QueryResult<TryGetWcQuestionsQuery, TryGetWcQuestionsQueryVariables>;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($answersInfo: AnswersInput!, $hubspotTracker: String, $pageName: String!, $groupName: String, $foxquiltBrokerId: String, $foxquiltAgencyId: String, $brokerCode: String, $agencyBrokerId: String, $agencyId: String, $partnercode: String) {
  updateApplication(
    answersInfo: $answersInfo
    hubspotTracker: $hubspotTracker
    pageName: $pageName
    foxquiltBrokerId: $foxquiltBrokerId
    foxquiltAgencyId: $foxquiltAgencyId
    brokerCode: $brokerCode
    agencyBrokerId: $agencyBrokerId
    agencyId: $agencyId
    partnercode: $partnercode
    groupName: $groupName
  ) {
    ... on ApplicationSuccess {
      applicationId
    }
    ... on ApplicationFailure {
      error
    }
  }
}
    `;
export type UpdateApplicationMutationFn = ApolloReactCommon.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      answersInfo: // value for 'answersInfo'
 *      hubspotTracker: // value for 'hubspotTracker'
 *      pageName: // value for 'pageName'
 *      groupName: // value for 'groupName'
 *      foxquiltBrokerId: // value for 'foxquiltBrokerId'
 *      foxquiltAgencyId: // value for 'foxquiltAgencyId'
 *      brokerCode: // value for 'brokerCode'
 *      agencyBrokerId: // value for 'agencyBrokerId'
 *      agencyId: // value for 'agencyId'
 *      partnercode: // value for 'partnercode'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, options);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = ApolloReactCommon.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export const UpdateApplicationAnswersAndQuoteDocument = gql`
    mutation updateApplicationAnswersAndQuote($applicationId: ObjectID!, $newEffectiveDate: String!, $newEmail: String!, $newPhoneNumber: String!, $newCompanyName: String!, $newDBAName: String, $additionalInsuredInfoList: [AdditionalInsured!]) {
  updateApplicationAnswersAndQuote(
    applicationId: $applicationId
    newEffectiveDate: $newEffectiveDate
    newEmail: $newEmail
    newPhoneNumber: $newPhoneNumber
    newCompanyName: $newCompanyName
    newDBAName: $newDBAName
    additionalInsuredInfoList: $additionalInsuredInfoList
  ) {
    isUpdateSuccessful
    newQuoteId
  }
}
    `;
export type UpdateApplicationAnswersAndQuoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApplicationAnswersAndQuoteMutation, UpdateApplicationAnswersAndQuoteMutationVariables>;

/**
 * __useUpdateApplicationAnswersAndQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationAnswersAndQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationAnswersAndQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationAnswersAndQuoteMutation, { data, loading, error }] = useUpdateApplicationAnswersAndQuoteMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      newEffectiveDate: // value for 'newEffectiveDate'
 *      newEmail: // value for 'newEmail'
 *      newPhoneNumber: // value for 'newPhoneNumber'
 *      newCompanyName: // value for 'newCompanyName'
 *      newDBAName: // value for 'newDBAName'
 *      additionalInsuredInfoList: // value for 'additionalInsuredInfoList'
 *   },
 * });
 */
export function useUpdateApplicationAnswersAndQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApplicationAnswersAndQuoteMutation, UpdateApplicationAnswersAndQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateApplicationAnswersAndQuoteMutation, UpdateApplicationAnswersAndQuoteMutationVariables>(UpdateApplicationAnswersAndQuoteDocument, options);
      }
export type UpdateApplicationAnswersAndQuoteMutationHookResult = ReturnType<typeof useUpdateApplicationAnswersAndQuoteMutation>;
export type UpdateApplicationAnswersAndQuoteMutationResult = ApolloReactCommon.MutationResult<UpdateApplicationAnswersAndQuoteMutation>;
export type UpdateApplicationAnswersAndQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApplicationAnswersAndQuoteMutation, UpdateApplicationAnswersAndQuoteMutationVariables>;
export const UpdateCoverageDocument = gql`
    mutation updateCoverage($input: UpdateCoverageInput!) {
  updateCoverage(input: $input) {
    amtrustOutput {
      premium
      feinIsValid
    }
    munichOutput {
      coveragesData {
        chooseCoverage
        finalPremium
        limits
        name
      }
      finalPremium
      quoteId
    }
  }
}
    `;
export type UpdateCoverageMutationFn = ApolloReactCommon.MutationFunction<UpdateCoverageMutation, UpdateCoverageMutationVariables>;

/**
 * __useUpdateCoverageMutation__
 *
 * To run a mutation, you first call `useUpdateCoverageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoverageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoverageMutation, { data, loading, error }] = useUpdateCoverageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoverageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCoverageMutation, UpdateCoverageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCoverageMutation, UpdateCoverageMutationVariables>(UpdateCoverageDocument, options);
      }
export type UpdateCoverageMutationHookResult = ReturnType<typeof useUpdateCoverageMutation>;
export type UpdateCoverageMutationResult = ApolloReactCommon.MutationResult<UpdateCoverageMutation>;
export type UpdateCoverageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCoverageMutation, UpdateCoverageMutationVariables>;
export const UpdateEffectiveDateDocument = gql`
    mutation updateEffectiveDate($applicationId: ObjectID!, $newEffectiveDate: String!) {
  updateEffectiveDate(
    applicationId: $applicationId
    newEffectiveDate: $newEffectiveDate
  )
}
    `;
export type UpdateEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<UpdateEffectiveDateMutation, UpdateEffectiveDateMutationVariables>;

/**
 * __useUpdateEffectiveDateMutation__
 *
 * To run a mutation, you first call `useUpdateEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEffectiveDateMutation, { data, loading, error }] = useUpdateEffectiveDateMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      newEffectiveDate: // value for 'newEffectiveDate'
 *   },
 * });
 */
export function useUpdateEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEffectiveDateMutation, UpdateEffectiveDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateEffectiveDateMutation, UpdateEffectiveDateMutationVariables>(UpdateEffectiveDateDocument, options);
      }
export type UpdateEffectiveDateMutationHookResult = ReturnType<typeof useUpdateEffectiveDateMutation>;
export type UpdateEffectiveDateMutationResult = ApolloReactCommon.MutationResult<UpdateEffectiveDateMutation>;
export type UpdateEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEffectiveDateMutation, UpdateEffectiveDateMutationVariables>;