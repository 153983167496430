import React from 'react';

const HelpText: React.FC = () => {
  return (
    <div data-testid="HelpText">
      {[
        { href: 'mailto:hello@foxquilt.com', text: 'Live Chat' },
        {
          href: 'mailto:hello@foxquilt.com',
          text: 'hello@foxquilt.com'
        },
        { href: 'tel:+1-888-878-4588', text: '1-888-878-4588' }
      ].map(({ href, text }, index) => (
        <a
          key={index}
          href={href}
          className="block mb-3 font-medium underline text-2xl text-secondary md:text-base md:text-quartary md:no-underline md:font-medium"
        >
          {text}
        </a>
      ))}
      <p className="mb-3 text-2xl md:text-base">
        <span className="md:font-normal font-medium">Monday to Friday:</span>
        <span className="font-normal">
          <br />
          8:00 AM – 8:00 PM (EST)
          <br />
          5:00 AM – 5:00 PM (PST)
        </span>
      </p>
      <p className="mb-3 text-2xl md:text-base">
        <span className="md:inline block md:font-normal font-medium">
          After hours/Weekends:{' '}
        </span>
        <span className="font-normal">
          We&apos;ll try our very best to support you during these times when
          possible
        </span>
      </p>
    </div>
  );
};

export default HelpText;
