import '../../CommonCommericalSurvey/CustomProperties';
import '../../CommonCommericalSurvey/CustomWidgets';
import '../../CommonCommericalSurvey/CommercialSurvey.css';
import '../../CommonCommericalSurvey/CustomExpression';
import '../../CommonCommericalSurvey/CustomMatrixColumn';

import { VersionControllerClient } from '@foxden/version-controller-client';
import { parseISO, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { QuestionSelectBase, SurveyModel } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { DatePickerTypes } from '../../../components/DatePicker/DatePickerTypes';
import Loading from '../../../components/Loading/Loading';
import Modal from '../../../components/Modal/Modal';
import Navigation from '../../../components/Navigation/Navigation';
import {
  EndorsementQuerystringData,
  PolicyOptionsQueryStringData,
  useCreateApplicationMutation
} from '../../../generated/graphql';
import getEnv from '../../../utils/getEnv';
import { UnderwritingUrlData } from '../../../utils/getUnderwritingUrlData';
import {
  formatDateAnswers,
  formatIsoDateString,
  getLabelFromChoicesByQuestion,
  getLabelFromChoicesByValue
} from '../../../utils/SurveyJSQuestion';
import {
  onBlurEvent,
  QuestionFields,
  scrollToElement
} from '../../CommonCommericalSurvey/CommonFunction';
import { SurveyCSS } from '../../CommonCommericalSurvey/commonVariables';
import { secondaryProfessionsMapper } from './SecondaryProfessionsMapper';

interface CommercialSurveyProps {
  model: SurveyModel;
  groupName?: string;
  brokerCode?: string;
  partnercode?: string;
  // TODO: Remove it when renewal is ready
  endorsementData?: EndorsementQuerystringData;
  queryStringData?: PolicyOptionsQueryStringData;
  urlData?: UnderwritingUrlData;
  transactionType: string;
  isStateActive?: boolean | null;
}

interface OtherProfession {
  otherProfession: string;
}

const PRIMARY_PROFESSION_NAME =
  'BusinessInformation_100_PrimaryProfession_WORLD_EN';
const SECONDARY_PROFESSIONS_NAME =
  'BusinessInformation_100_SecondaryProfessions_WORLD_EN';
const OTHER_PROFESSIONS_NAME =
  'BusinessInformation_100_SecondaryProfessionAdditional_WORLD_EN';
const EFFECTIVE_DATE_QUESTION =
  'BusinessInformation_100_EffectiveDate_WORLD_EN';
const COUNTRY_QUESTION = 'BusinessInformation_100_Country_WORLD_EN';
const PROVINCE_QUESTION = 'BusinessInformation_100_Province_WORLD_EN';
const STATE_QUESTION = 'BusinessInformation_100_State_WORLD_EN';

const VERSIONING_QUESTIONS = [
  EFFECTIVE_DATE_QUESTION,
  COUNTRY_QUESTION,
  PROVINCE_QUESTION,
  STATE_QUESTION
];

const orangeButton =
  'bg-tertiary text-base uppercase pointer font-bold  mt-6 px-6 py-3 font-raleway sm:text-mobileFont rounded text-white';

const whieteButton =
  'border-2 border-faded rounded-md bg-white uppercase pointer font-bold  mt-6 px-6 py-3 font-raleway sm:text-mobileFont rounded mr-4 text-quartary';

const { REACT_APP_ADMIN_FRONTEND_URL } = getEnv();

function formatUrl(url: string, versionNumber: string): string {
  return `${url}/${versionNumber}/`;
}

const getCountry = (model: SurveyModel): string => {
  return getLabelFromChoicesByQuestion(
    model.getQuestionByName(COUNTRY_QUESTION) as QuestionSelectBase
  ) as string;
};

const getProvince = (model: SurveyModel): string => {
  const country = getCountry(model);
  const province = getLabelFromChoicesByQuestion(
    model.getQuestionByName(PROVINCE_QUESTION) as QuestionSelectBase
  ) as string;

  const state = getLabelFromChoicesByQuestion(
    model.getQuestionByName(STATE_QUESTION) as QuestionSelectBase
  ) as string;

  return country == 'Canada' ? province : state;
};

export async function getVersionedURL(
  effectiveDate: string,
  versionControllerClient: VersionControllerClient,
  frontendUrl: string,
  urlData?: UnderwritingUrlData,
  addEffectiveDateToUrlData = false,
  country?: string,
  provinceOrState?: string
): Promise<string | null> {
  try {
    const workflowVersion = await versionControllerClient.getNewBusinessVersion(
      new Date(effectiveDate).toISOString().split('T')[0],
      new Date().toISOString().split('T')[0],
      undefined, // This field is carrierPartner, the util function will handle undefined value
      provinceOrState,
      country
    );
    const frontendVersion = workflowVersion.underwritingFrontendVersion;
    const url = new URL(formatUrl(frontendUrl, frontendVersion));
    const province = country === 'Canada' ? provinceOrState : undefined;
    const state = country !== 'Canada' ? provinceOrState : undefined;

    if (urlData || addEffectiveDateToUrlData) {
      let urlParams = { ...urlData };
      if (addEffectiveDateToUrlData) {
        urlParams = {
          ...urlData,
          effectiveDate
        };
        country ? (urlParams['country'] = country) : null;
        if (province) {
          urlParams['province'] = province;
          delete urlParams['state']; // to avoid both both province and state exist in url
        }
        if (state) {
          urlParams['state'] = state;
          delete urlParams['province']; // to avoid both both province and state exist in url
        }
      }
      url.search = new URLSearchParams(urlParams).toString();
    }
    return url.toString();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

const CommercialSurvey: React.FC<CommercialSurveyProps> = (props) => {
  const { REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL } = getEnv();
  const vcc = new VersionControllerClient(
    REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL
  );

  const history = useHistory();
  const {
    model,
    groupName,
    brokerCode,
    // TODO: Remove it when renewal is ready
    endorsementData,
    transactionType,
    queryStringData,
    partnercode,
    urlData,
    isStateActive
  } = props;
  const [errorMsg, setErrorMsg] = useState<null | string>(null);
  const [questionStartTime, setQuestionStartTime] = useState<Date | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [versionedUrl, setVersionedUrl] = useState<string | null>(null);

  const [cookies] = useCookies(['hubspotutk']);

  const hubspotTracker = cookies.hubspotutk;
  const QuestionTrackingData: QuestionFields = {
    questionId: null,
    questionAnswer: null,
    hubspotTracker: hubspotTracker
  };

  let scrolling_target: HTMLElement | null = null;

  useEffect(() => {
    if (
      !versionedUrl &&
      !errorMsg &&
      model.getQuestionByName(EFFECTIVE_DATE_QUESTION) &&
      !isProcessing &&
      !urlData?.effectiveDate
    ) {
      if (!endorsementData?.policyFoxdenId && !queryStringData) {
        updateUrl(new Date().toLocaleDateString('sv'), urlData); // first load of getFirstJson
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionedUrl, errorMsg, isProcessing, model, urlData?.effectiveDate]);

  async function updateUrl(
    effectiveDate: string,
    urlData?: UnderwritingUrlData,
    addEffectiveDateToUrlData = false,
    country?: string,
    provinceOrState?: string
  ): Promise<boolean> {
    setIsProcessing(true);
    const versionedUrl = await getVersionedURL(
      effectiveDate,
      vcc,
      window.location.origin,
      urlData,
      addEffectiveDateToUrlData,
      country,
      provinceOrState
    );
    if (versionedUrl) {
      setVersionedUrl(versionedUrl);
      if (versionedUrl !== window.location.href) {
        window.location.href = versionedUrl;
        return true;
      }
    } else {
      setErrorMsg('Error getting workflowVersion');
      return false;
    }
    setIsProcessing(false);
    return false;
  }

  const [
    createApplicationMutation,
    { loading: isCreateApplicationLoading, error: createApplicationError }
  ] = useCreateApplicationMutation();

  const doOnVersioningDataChange = async (
    effectiveDateString: string,
    country: string,
    provinceOrState: string
  ) => {
    const regionVariable = country === 'Canada' ? 'province' : 'state';
    if (
      (effectiveDateString !== urlData?.effectiveDate ||
        country !== urlData?.country ||
        provinceOrState !== urlData[regionVariable]) &&
      !endorsementData?.policyFoxdenId &&
      !queryStringData
    ) {
      updateUrl(effectiveDateString, urlData, true, country, provinceOrState);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doOnValueChanged = (survey: SurveyModel, options: any) => {
    if (
      options.name === 'BusinessInformation_100_ProfessionsPercentage_WORLD_EN'
    ) {
      onBlurEvent(QuestionTrackingData, questionStartTime);
    }
    QuestionTrackingData.questionId = options.name;
    QuestionTrackingData.questionAnswer = options.value;

    if (VERSIONING_QUESTIONS.includes(options.name) && options.value) {
      // effective date
      const effectiveDate = survey.getQuestionByName(EFFECTIVE_DATE_QUESTION)
        ?.value;
      const effectiveDateString = new Date(effectiveDate).toLocaleDateString(
        'sv'
      );
      // country
      const country = survey.getQuestionByName(COUNTRY_QUESTION)?.value;
      // province or state
      const province = survey.getQuestionByName(PROVINCE_QUESTION)?.value;
      const state = survey.getQuestionByName(STATE_QUESTION)?.value;
      const provinceOrState = province || state;

      if (effectiveDate && country && provinceOrState) {
        doOnVersioningDataChange(
          effectiveDateString,
          getCountry(survey),
          getProvince(survey)
        );
        return;
      }
    }

    if (options.name !== PRIMARY_PROFESSION_NAME) return;
    // according to what user chosen for primary profession, we go to the mapper and get the corresponding choices and then assign to secondary professions
    const primaryProfessionQuestion = survey.getQuestionByName(
      PRIMARY_PROFESSION_NAME
    );
    const primaryProfession = primaryProfessionQuestion.value;
    const primaryProfessionNamePattern = primaryProfession.split('_');
    const iso = primaryProfessionNamePattern[4].substring(0, 5);
    const secondaryProfessionsQuestion = survey.getQuestionByName(
      SECONDARY_PROFESSIONS_NAME
    ) as QuestionSelectBase;
    secondaryProfessionsQuestion.choices =
      secondaryProfessionsMapper[parseInt(iso)] || [];
    secondaryProfessionsQuestion.value = [];

    // clear percentage value
    const percentagesQuestion = model.getQuestionByName(
      'BusinessInformation_100_ProfessionsPercentage_WORLD_EN'
    );
    percentagesQuestion.value = {};
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValidateQuestion = (survey: SurveyModel, options: any) => {
    const { value } = options;
    switch (options.name) {
      case 'BusinessInformation_100_CustomerInfo_WORLD_EN':
        const { phoneNumber } = value;
        if (!phoneNumber.match(/^1\d\d\d\d\d\d\d\d\d\d$/)) {
          options.error = 'Invalid Phone Number';
        }
        break;
      case EFFECTIVE_DATE_QUESTION:
        const effectiveDateQuestion = survey.getQuestionByName(
          EFFECTIVE_DATE_QUESTION
        ) as DatePickerTypes;
        const minDateObject = effectiveDateQuestion.minDate;
        const maxDateObject = effectiveDateQuestion.maxDate;
        if (
          !(minDateObject instanceof Date) ||
          !(maxDateObject instanceof Date)
        ) {
          break;
        }
        const minDateStartDay = startOfDay(minDateObject);
        const maxDateStartDay = startOfDay(maxDateObject);
        const selectedDateStartDay = startOfDay(
          parseISO(formatIsoDateString(value))
        );

        if (
          selectedDateStartDay < minDateStartDay ||
          selectedDateStartDay > maxDateStartDay
        ) {
          options.error = 'Invalid date - please select a day in range';
        }
        break;
      default:
      // no-ops
    }
  };

  const doComplete = async () => {
    if (model.isCurrentPageHasErrors) {
      model.currentPage.focusFirstErrorQuestion();
      scrollToElement(scrolling_target);
      return;
    }
    const jsonFileName = model.getValue('fetchJSON');
    if (jsonFileName === 'noServiceCanada') {
      setErrorMsg(
        "Thank you for considering Foxquilt. Unfortunately, we don't offer insurance in your province right now, however keep checking back because we are growing, and we'll be in your province soon!"
      );
      return;
    }

    if (jsonFileName === 'noServiceUS') {
      setErrorMsg(
        'Thank you for considering Foxquilt. At this time, we are not available in this state. Please contact your Foxquilt representative for more information.'
      );
      return;
    }

    // Remove leading & trailing spaces of email
    const customerInfo = model.getValue(
      'BusinessInformation_100_CustomerInfo_WORLD_EN'
    );
    model.setValue('BusinessInformation_100_CustomerInfo_WORLD_EN', {
      ...customerInfo,
      email: customerInfo.email.trim()
    });

    const { data, currentPage } = model;
    formatDateAnswers(data);

    // get labels for professions
    const primaryProfessionQuestion = model.getQuestionByName(
      PRIMARY_PROFESSION_NAME
    ) as QuestionSelectBase;
    const primaryProfessionLabel = getLabelFromChoicesByValue(
      data[PRIMARY_PROFESSION_NAME],
      primaryProfessionQuestion
    );
    const secondaryProfessions = data[SECONDARY_PROFESSIONS_NAME];
    const secondaryProfessionsQuestion = model.getQuestionByName(
      SECONDARY_PROFESSIONS_NAME
    ) as QuestionSelectBase;
    const secondaryProfessionsLabels = secondaryProfessions
      ? secondaryProfessions.map((profession: string) =>
          getLabelFromChoicesByValue(profession, secondaryProfessionsQuestion)
        )
      : undefined;
    const otherProfessions = data[OTHER_PROFESSIONS_NAME];
    const otherProfessionsQuestion = model.getQuestionByName(
      OTHER_PROFESSIONS_NAME
    ) as QuestionSelectBase;
    const otherProfessionsLabels = otherProfessions
      ? otherProfessions.map((ele: OtherProfession) =>
          getLabelFromChoicesByValue(
            ele.otherProfession,
            otherProfessionsQuestion
          )
        )
      : undefined;

    const { data: createApplication } = await (async () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const effectiveDate = model.getQuestionByName(EFFECTIVE_DATE_QUESTION)
        ?.value;

      const effectiveDateString =
        transactionType === 'Renewal' ? effectiveDate : urlData!.effectiveDate!;
      const utcEffectiveDate = zonedTimeToUtc(
        effectiveDateString,
        timezone
      ).toString();
      const utcTransactionDate = zonedTimeToUtc(
        new Date(),
        timezone
      ).toString();

      if (!endorsementData?.policyFoxdenId && !queryStringData) {
        const urlUpdated = await updateUrl(
          urlData!.effectiveDate!,
          urlData,
          true
        ); // not understand purpose of the code, might need to update with country and province as well
        if (urlUpdated) {
          return { data: undefined };
        }
      }
      const country = getCountry(model);
      const provinceOrState = getProvince(model);

      return await createApplicationMutation({
        variables: {
          answersInfo: {
            ...data,
            primaryProfessionLabel,
            secondaryProfessionsLabels,
            otherProfessionsLabels,
            fetchJSON: undefined, // Note: don't send this hidden value to the server
            foxquiltAgencyId: urlData?.foxquiltAgencyId,
            agencyId: urlData?.agencyId,
            foxquiltBrokerId: urlData?.foxquiltBrokerId,
            agencyBrokerId: urlData?.agencyBrokerId
          },
          ...(hubspotTracker ? { hubspotTracker } : undefined),
          // TODO: Remove it when renewal is ready
          ...endorsementData,
          ...queryStringData,
          groupName,
          pageName: currentPage.name,
          effectiveDateUTC: utcEffectiveDate,
          transactionType,
          transactionDateUTC: utcTransactionDate,
          country,
          provinceOrState
        }
      });
    })();

    if (!createApplication) {
      throw new Error('createApplication should be ready');
    }
    const result = createApplication.createApplication;

    switch (result.__typename) {
      case 'ApplicationSuccess':
        model.completeLastPage();
        const { applicationId } = result;
        history.push(`/${applicationId}`, {
          country: getCountry(model),
          province: getProvince(model),
          brokerCode,
          ...urlData
        });
        break;
      case 'ApplicationFailure':
        const { error } = result;
        setErrorMsg(error);
        break;
      default:
        setErrorMsg('Uh oh, something is wrong. Please try again.');
        break;
    }
  };

  const closeErrorModal = () => {
    setErrorMsg(null);
  };

  useEffect(() => {
    const partnerCodeQuestion = model.getQuestionByName(
      'BusinessInformation_100_PartnerCode_WORLD_EN'
    );
    if (!partnercode) {
      partnerCodeQuestion.visible = false;
    } else {
      partnerCodeQuestion.value = partnercode;
    }
  }, [model, partnercode]);

  if (isCreateApplicationLoading || isProcessing) {
    return <Loading />;
  }

  if (createApplicationError || errorMsg) {
    const graphqlErrorMsg = createApplicationError
      ? 'Uh oh, something is wrong. Please try again.'
      : undefined;
    if (!isStateActive) {
      return (
        <Modal>
          <div className="text-primary">
            <p>{graphqlErrorMsg || errorMsg}</p>
            <div className="flex justify-center flex-col md:flex-row">
              <button className={whieteButton} onClick={() => history.goBack()}>
                Start New Application
              </button>
              <button
                className={whieteButton}
                onClick={() =>
                  window.location.assign(REACT_APP_ADMIN_FRONTEND_URL)
                }
              >
                Return to Foxden
              </button>
              <button
                className={orangeButton}
                onClick={() =>
                  window.location.assign(
                    'https://www.foxquilt.com/agent-broker-partner.html'
                  )
                }
              >
                Agents & Brokers Information
              </button>
            </div>
          </div>
        </Modal>
      );
    }
    return (
      <Modal onAction={closeErrorModal}>
        <div className="text-primary">
          <p>{graphqlErrorMsg || errorMsg}</p>
        </div>
      </Modal>
    );
  }

  const onScrollingElementToTop = (
    _sender: unknown,
    options: { elementId: string }
  ) => {
    scrolling_target = document.getElementById(options.elementId);
  };

  return (
    <div
      data-testid="Commercial"
      className="survey-container"
      onFocus={() => {
        setQuestionStartTime(new Date());
      }}
      onBlur={() => {
        onBlurEvent(QuestionTrackingData, questionStartTime);
      }}
    >
      <Survey
        model={model}
        css={SurveyCSS}
        onValueChanged={doOnValueChanged}
        onValidateQuestion={onValidateQuestion}
        onScrollingElementToTop={onScrollingElementToTop}
        clearIfInvisible="onHidden"
      />
      <Navigation doComplete={doComplete} />
    </div>
  );
};

export default CommercialSurvey;
