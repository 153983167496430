import React from 'react';
import * as Survey from 'survey-core';

import Customer from './Customer';
import { CustomerData } from './CustomerTypes';

const customerWidget = {
  name: 'customer',
  title: 'Customer',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'customer';
  },
  activatedByChanged: (): void => {
    Survey.JsonObject.metaData.addClass(
      'customer',
      [],
      undefined,
      'multipletext'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  render: (question: Survey.IQuestion): React.ReactElement<typeof Customer> => {
    const { value } = question;

    const updateCustomer = (newValue: CustomerData) => {
      question.value = newValue;
    };

    return <Customer value={value} updateCustomer={updateCustomer} />;
  }
};

export default customerWidget;
