import './ContactBar.css';

import React from 'react';

import CallIcon from '../../images/call.png';
import EmailIcon from '../../images/email.png';
import MeetingIcon from '../../images/meeting.png';

const MEET_URL =
  'https://meetings.hubspot.com/foxquilt-insurance/30min-discovery-call';
const PHONE_URL = 'tel:1-888-878-4588';
const EMAIL_URL = 'mailto:sales@foxquilt.com';

interface ContactBarProps {
  msg: string;
}

const ContactBar: React.FC<ContactBarProps> = (props) => {
  const { msg } = props;

  return (
    <div data-testid="ContactBar" className="ContactBar">
      <div className="policy-review w-full text-center text-mobileFont md:text-xl">
        {msg}
      </div>
      <div className="text-white text-center my-1 mx-auto w-auto text-base md:text-xl ">
        Contact Us:
      </div>
      <div className="inline-flex flex-row justify-between text-center">
        <a href={MEET_URL}>
          <img src={MeetingIcon} alt="Book an appointment" />
        </a>
        <a href={PHONE_URL}>
          <img src={CallIcon} alt="Call" />
        </a>
        <a href={EMAIL_URL}>
          <img src={EmailIcon} alt="Email" />
        </a>
      </div>
    </div>
  );
};

export default ContactBar;
