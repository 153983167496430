import React, { createContext, useState } from 'react';

export type ContextValue = {
  headerTitle: string;
  setHeaderTitle: (headerTitle: string) => void;
  progress: number;
  setProgress: (percentage: number) => void;
};

export const Context = createContext<ContextValue | null>(null);

const ContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [headerTitle, setHeaderTitle] = useState('');

  const store: ContextValue = {
    progress,
    setProgress,
    headerTitle,
    setHeaderTitle
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export default ContextProvider;
