import React, { Fragment, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { PopupContent as PopupContentType } from './CoveragesDetails';

interface PopupContentProps {
  content: PopupContentType;
}

const PopupContent: React.FC<PopupContentProps> = (props) => {
  const { content } = props;

  const [showWhatCoveredDetail, setShowWhatCoveredDetail] = useState(false);
  const [showWhatNotCoveredDetail, setShowWhatNotCoveredDetail] = useState(
    false
  );
  const [showMoreInformation, setShowMoreInformation] = useState(false);

  const {
    title,
    subTitle,
    whatCovered,
    whatNotCoverd,
    moreInformation
  } = content;
  return (
    <div className="coverage-popup container overflow-scroll m-auto bg-white text-center text-secondary p-8">
      <h1 className="text-3xl p-4">
        <b>{title}</b>
      </h1>
      <h2 className="text-base text-gray-500">
        {subTitle && ReactHtmlParser(subTitle)}
      </h2>
      {whatCovered && (
        <Fragment>
          <hr className="border-gray-300 mt-4" />
          <div className="text-left text-2xl mt-4 text-gray-500">
            <a
              className="cursor-point"
              onClick={() => setShowWhatCoveredDetail(!showWhatCoveredDetail)}
            >
              <b className="text-secondary">- What&apos;s Covered?</b>
            </a>
            {showWhatCoveredDetail ? ReactHtmlParser(whatCovered) : null}
          </div>
        </Fragment>
      )}
      {whatNotCoverd && (
        <Fragment>
          <hr className="border-gray-300 mt-4" />
          <div className="text-left text-2xl mt-4 text-gray-500">
            <a
              className="cursor-point"
              onClick={() =>
                setShowWhatNotCoveredDetail(!showWhatNotCoveredDetail)
              }
            >
              <b className="text-secondary">+ What&apos;s Not Covered?</b>
            </a>
            {showWhatNotCoveredDetail ? ReactHtmlParser(whatNotCoverd) : null}
          </div>
        </Fragment>
      )}
      {moreInformation && (
        <Fragment>
          <hr className="border-gray-300 mt-4" />
          <div className="text-left text-2xl mt-4 text-gray-500">
            <a
              className="cursor-point"
              onClick={() => setShowMoreInformation(!showMoreInformation)}
            >
              <b className="text-secondary">+ More Information</b>
            </a>
            {showMoreInformation ? ReactHtmlParser(moreInformation) : null}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PopupContent;
