import './ToggleButton.css';

import { Button, ButtonGroup, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

interface ToggleButtonProps {
  title?: string;
  onChange: (value: boolean) => void;
  helperText?: string;
  labelTrue?: string;
  labelFalse?: string;
  value?: string | boolean | number;
}
const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { title, onChange, helperText, labelTrue, labelFalse, value } = props;
  const [buttonValue, setButtonValue] = useState(value);
  return (
    <div
      className="ToggleButton flex flex-col font-bold input-field-container"
      data-testid="ToggleButton"
    >
      <div className=" mb-4">
        {title || ''}
        {helperText ? (
          <Tooltip
            title={helperText}
            arrow
            classes={{
              arrow: 'text-secondary',
              tooltip: 'text-sm bg-secondary'
            }}
            enterTouchDelay={1}
          >
            <div className="help-button">?</div>
          </Tooltip>
        ) : null}
      </div>

      <div className="uppercase font-bold">
        <ButtonGroup className="border-secondary w-full">
          <Button
            className={`toggle-button rounded-l-xl ${
              buttonValue ? 'text-secondary' : 'bg-secondary text-white'
            }`}
            onClick={() => {
              setButtonValue(false);
              onChange(false);
            }}
          >
            {labelFalse || 'No'}
          </Button>
          <Button
            className={`toggle-button rounded-r-xl ${
              buttonValue ? 'bg-secondary text-white' : 'text-secondary'
            }`}
            onClick={() => {
              setButtonValue(true);
              onChange(true);
            }}
          >
            {labelTrue || 'Yes'}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ToggleButton;
