import React from 'react';

import {
  MunichEligibleQuoteResponse,
  MunichIneligibleQuoteResponse
} from '../../../../generated/graphql';
import { CoveragesDataInput } from '../..';
import SummaryCoverage from './SummaryCoverage';

interface MunichSummaryProps {
  munichSummaryInput?:
    | MunichEligibleQuoteResponse
    | MunichIneligibleQuoteResponse;
  isEndorsement: boolean;
  carrierPartner?: string;
}

export const getMunichSummary: React.FC<MunichSummaryProps> = (props) => {
  const { munichSummaryInput, isEndorsement, carrierPartner } = props;

  const zeroPremiumText = isEndorsement ? 'No Change' : 'No Coverage';

  if (munichSummaryInput?.__typename === 'MunichEligibleQuoteResponse') {
    const { quoteNumber, coveragesData: rawCoveragesData } = munichSummaryInput;
    const coveragesData = rawCoveragesData as Array<CoveragesDataInput>;

    const munichSummary = (
      <div>
        <p className="mt-2 mb-1 text-base md:text-lg">
          Quote Number - {quoteNumber}
        </p>
        {coveragesData.map(
          ({ name, chooseCoverage, finalPremium, limits }, index) => (
            <SummaryCoverage
              key={index}
              name={name}
              munichSummaryInput={munichSummaryInput}
              chooseCoverage={chooseCoverage}
              finalPremium={finalPremium}
              limits={limits}
              zeroPremiumText={
                carrierPartner === 'StateNational' && name === 'tria'
                  ? 'Included'
                  : zeroPremiumText
              }
              carrierPartner={carrierPartner}
            />
          )
        )}
      </div>
    );
    return munichSummary;
  }
  return null;
};
