/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const SurveyCSS = {
  root: 'sv_main h-full mt-8 md:mt-16',
  container: 'sv_container h-full min-h-full',
  page: {
    description: 'custom-description'
  },
  panel: {
    container: 'sv_p_container'
  },
  row: 'sv_row mb-4 md:mb-6 lg:mb-8',
  navigation: {
    complete: 'hidden',
    prev: 'hidden',
    next: 'hidden'
  },
  error: {
    item: 'text-red-600'
  },
  boolean: {
    slider: 'sv-boolean__slider bg-white mb-2',
    switch: 'sv-boolean__switch bg-primary'
  }
};
