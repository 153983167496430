import './NotFound.css';

import React from 'react';

const NotFound: React.FC = () => (
  <div className="NotFound page-container" data-testid="NotFound">
    <p className="text-mainFont text-primary font-raleway">
      Sorry, the page you are looking for cannot be found.
    </p>
  </div>
);

export default NotFound;
