import './SelectInput.css';

import { Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

import QuestionIcon from '../../images/question-icon.png';
import { Choices, CustomWidth } from './SelectInputTypes';

interface SelectInputProps {
  leftLabel?: string;
  rightLabel?: string;
  customWidth?: CustomWidth;
  value?: string;
  name: string;
  options: Array<Choices>;
  onChange: (value: string) => void;
  helperText?: string;
  valueClass?: string;
  containerClass?: string;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    name,
    options,
    onChange,
    customWidth,
    value,
    leftLabel,
    rightLabel,
    helperText,
    valueClass,
    containerClass
  } = props;
  const defualtValue = value || '';
  const defaultContainerClass = `w-${
    customWidth ? customWidth : 'smaller'
  } border-primary font-raleway text-primary text-mainFont sm:text-mobileFont sm:w-${
    customWidth ? customWidth : 'smaller'
  }Mobile`;
  return (
    <div className="QuotePageSelectInput inline" data-testid="SelectInput">
      {leftLabel || ''}{' '}
      <FormControl
        className={`${containerClass ? containerClass : defaultContainerClass}`}
      >
        <Select
          className="quote-page-custom-select"
          onChange={(e) => {
            onChange(e.target.value as string);
          }}
          name={name}
          value={defualtValue}
        >
          {options.map((p) => (
            <MenuItem
              key={p.text}
              value={p.value}
              style={{
                paddingTop: 0,
                borderRadius: 0,
                backgroundColor: 'white',
                borderColor: '#403D39'
              }}
            >
              <em
                className={
                  valueClass
                    ? valueClass
                    : 'not-italic text-mainFont border-none font-raleway sm:text-mobileFont sm:p-0'
                }
              >
                {p.text}
              </em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {rightLabel || ''}
      {helperText ? (
        <Tooltip
          title={helperText}
          arrow
          classes={{
            arrow: 'text-tertiary',
            tooltip: 'text-sm bg-tertiary'
          }}
          enterTouchDelay={1}
        >
          <img
            className="w-4 inline ml-2 mb-4"
            src={QuestionIcon}
            alt="question-mark"
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default SelectInput;
