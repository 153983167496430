//TODO: move to foxden-version-controller-client or new repo
import { GraphQLClient } from 'graphql-request';

import {
  getSdk,
  Sdk,
  UserPaymentPlanOptionByQuoteId
} from '../generated/payment-graphql';
import getEnv from '../utils/getEnv';

export class PaymentClient {
  sdk: Sdk;

  constructor(versionNumber?: string) {
    const graphQLClient = new GraphQLClient(
      PaymentClient.getPaymentURL(versionNumber)
    );
    this.sdk = getSdk(graphQLClient);
  }

  /**
   * Returns a SDK of rating-quoting backend.
   * @returns a sdk that has all APIs of rating-quoting backend.
   */
  public getSDK(): Sdk {
    return this.sdk;
  }

  /**
   * Retrives a list of payment plan option by quote id.
   *
   * @param {string} quoteId ID of quote to get the payment plan option.
   * @returns {Array<UserPaymentPlanOptionByQuoteId>} Returns a Promise for an array
   *                                                  of payment plan option of given quote.
   */
  public async getUserPaymentPlanOptionByQuoteId(
    quoteId: string
  ): Promise<Array<UserPaymentPlanOptionByQuoteId>> {
    const result = await this.sdk.getUserPaymentPlanOptionByQuoteId({
      quoteId
    });
    return result.getUserPaymentPlanOptionByQuoteId;
  }

  static versionUrl(baseUrl: string, versionNumber?: string): string {
    // regex to replace version number in url
    const regex = /\d{4}-\d{2}-\d{2}/g;
    return versionNumber ? baseUrl.replace(regex, versionNumber) : baseUrl;
  }

  public static getPaymentURL(versionNumber?: string): string {
    const { REACT_APP_PAYMENT_GRAPHQL_URL } = getEnv();

    return PaymentClient.versionUrl(
      REACT_APP_PAYMENT_GRAPHQL_URL,
      versionNumber
    );
  }

  public static getPaymentFrontendURL(versionNumber?: string): string {
    const { REACT_APP_PAYMENT_FRONTEND_URL } = getEnv();

    return PaymentClient.versionUrl(
      REACT_APP_PAYMENT_FRONTEND_URL,
      versionNumber
    );
  }
}
