import './Error.css';

import { ApolloError } from '@apollo/client';
import React from 'react';

interface ErrorProps {
  error?: ApolloError;
  message?: string;
}

const Error: React.FC<ErrorProps> = ({ error, message }) => {
  const errorMessage =
    error?.message || message || 'Sorry, something went wrong.';

  return (
    <div className="Error page-container" data-testid="Error">
      <p className="text-center max-w-4xl text-mainFont text-primary font-raleway">
        {errorMessage} Please try again later.
      </p>
    </div>
  );
};

export default Error;
