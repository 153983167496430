import { Close } from '@material-ui/icons';
import React from 'react';

import mailPost from '../../../../images/mail-post.svg';

interface SuccessQuoteLinkEmailProps {
  handleCloseAndExitModal?: () => void;
}

const SuccessQuoteLinkEmail: React.FC<SuccessQuoteLinkEmailProps> = ({
  handleCloseAndExitModal
}) => {
  return (
    <div className="relative bg-white border-white rounded-lg h-fit-content mx-2 md:mx-0 p-4 md:p-8 self-center flex flex-col items-center max-w-xl z-50">
      <Close
        className="absolute top-1 right-1 cursor-pointer"
        onClick={handleCloseAndExitModal}
      />
      <div className="mb-2">
        <img className="h-auto w-auto" src={mailPost} />
      </div>
      <h2 className="text-2xl font-semibold text-center mb-2">Email Sent!</h2>
      <p className="mb-2">
        An email with a link to return to the quote has been sent to the
        requested email address(es).
      </p>
      <p className="mb-2">
        The email should arrive momentarily, but if not, then we recommend
        refreshing the applicable inbox and checking the spam folder
      </p>
    </div>
  );
};

export default SuccessQuoteLinkEmail;
