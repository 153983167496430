import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import * as Sentry from '@sentry/react';

import getEnv from './utils/getEnv';

const { REACT_APP_GRAPHQL_URL, REACT_APP_STAGE: stage } = getEnv();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (stage === 'local') return;

    Sentry.withScope((scope) => {
      scope.setExtra('networkErrors', networkError);
      Sentry.captureException(new Error('A graphql error occurred'));
    });
  }

  if (graphQLErrors) {
    if (stage === 'local') return;

    Sentry.withScope((scope) => {
      scope.setExtra('graphQLErrors', graphQLErrors);
      Sentry.captureException(new Error('A graphql error occurred'));
    });
  }
});

const httpLink = new HttpLink({ uri: REACT_APP_GRAPHQL_URL });

export const APOLLO_CLIENT = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache()
});
