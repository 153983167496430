import {
  PanelModel,
  QuestionPanelDynamicModel,
  SurveyModel
} from 'survey-core';

export function getAmtrustOfficerPanel(
  model: SurveyModel
): QuestionPanelDynamicModel {
  const amTrustOfficers = model.getQuestionByName('Amtrust_Officers');

  if (!(amTrustOfficers instanceof QuestionPanelDynamicModel)) {
    throw new Error(
      'Unreachable - Amtrust_Officers is not based on a dynamic panel'
    );
  }

  return amTrustOfficers;
}

export function getDyanmicQuestionPanel(model: SurveyModel): PanelModel {
  const wcDynamicQuestionPanel = model.getPanelByName(
    'Amtrust_DyanmicQuestions'
  );

  if (!(wcDynamicQuestionPanel instanceof PanelModel)) {
    throw new Error(
      'Unreachable - Amtrust_DyanmicQuestions is not based on a panel'
    );
  }

  return wcDynamicQuestionPanel;
}
