import { Serializer } from 'survey-core';

// add custom properties
Serializer.addProperties('question', [
  {
    name: 'leftLabel:string',
    default: '',
    category: 'general'
  },
  {
    name: 'rightLabel:string',
    default: '',
    category: 'general'
  },
  {
    name: 'customWidth:dropdown',
    choices: [
      'superSmall',
      'smaller',
      'small',
      'medium',
      'large',
      'superLarge',
      'full'
    ],
    default: 'medium',
    category: 'general'
  },
  {
    name: 'helperText:string',
    category: 'general'
  },
  {
    name: 'country:dropdown',
    choices: ['CA', 'US'],
    category: 'general'
  }
]);
