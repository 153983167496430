//TODO move hardcoded code
const BUSINESS_TYPE = [
  'BusinessInformation_100_BusinessType_01_WORLD_EN',
  'BusinessInformation_100_BusinessType_02_WORLD_EN',
  'BusinessInformation_100_BusinessType_03_WORLD_EN',
  'BusinessInformation_100_BusinessType_04_WORLD_EN',
  'BusinessInformation_100_BusinessType_05_WORLD_EN',
  'BusinessInformation_100_BusinessType_06_WORLD_EN',
  'BusinessInformation_100_BusinessType_07_WORLD_EN'
] as const;

export type BusinessTypeString = typeof BUSINESS_TYPE[number];

const AMTRUST_BUSINESS_TYPE = [
  'Sole Proprietor',
  'Partner',
  'LLC Member',
  'Executive Officer'
] as const;

export type AmTrustBusinessType = typeof AMTRUST_BUSINESS_TYPE[number];

const businessTypeMap = new Map<BusinessTypeString, AmTrustBusinessType>([
  ['BusinessInformation_100_BusinessType_01_WORLD_EN', 'Sole Proprietor'],
  ['BusinessInformation_100_BusinessType_02_WORLD_EN', 'Partner'],
  ['BusinessInformation_100_BusinessType_03_WORLD_EN', 'Executive Officer'],
  ['BusinessInformation_100_BusinessType_04_WORLD_EN', 'Executive Officer'],
  ['BusinessInformation_100_BusinessType_05_WORLD_EN', 'Executive Officer'],
  ['BusinessInformation_100_BusinessType_06_WORLD_EN', 'LLC Member'],
  ['BusinessInformation_100_BusinessType_07_WORLD_EN', 'Executive Officer']
]);

export function toAmtrustBusinessType({
  businessType
}: {
  businessType: BusinessTypeString;
}): AmTrustBusinessType {
  const amtrustBusinessType = businessTypeMap.get(businessType);

  if (!amtrustBusinessType) {
    throw new Error(`Cannot get amtrust business type for ${businessType}`);
  }

  return amtrustBusinessType;
}
