import React from 'react';
import * as Survey from 'survey-core';

import Address from './Address';
import { AddressData } from './AddressTypes';

interface AddressQuestion extends Survey.IQuestion {
  title: string;
  country: string;
}

const addressWidget = {
  name: 'address',
  title: 'Address',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: AddressQuestion): boolean {
    return question.getType() === 'address';
  },
  activatedByChanged: (): void => {
    Survey.JsonObject.metaData.addClass(
      'address',
      [],
      undefined,
      'multipletext'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div><span><input /></span></div>',
  render: (question: AddressQuestion): React.ReactElement<typeof Address> => {
    const { value, title, country, name: questionName } = question;
    //Province here was kept here for another approach for future reference
    let province =
      value == null || value == undefined
        ? ''
        : (value as AddressData).province ?? '';
    const onChange = (newValue: AddressData) => {
      question.value = newValue;
      province = '';
    };
    return (
      <Address
        value={value}
        onChange={onChange}
        title={title}
        country={country}
        province={province}
        preventStateOrProvinceChange={
          questionName === 'BusinessInformation_100_BusinessAddress_WORLD_EN'
        }
        filterProvince={true}
      />
    );
  }
};

export default addressWidget;
