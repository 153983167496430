import './Navigation.css';

import React from 'react';

import Button from '../Button/Button';

interface NavigationProps {
  goBack?: () => void;
  goNext?: () => void;
  doComplete?: () => void;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { goBack, goNext, doComplete } = props;

  return (
    <div
      className="Navigation grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2 lg:gap-64 px-3 lg:px-0 mx-auto justify-between"
      data-testid="Navigation"
    >
      {goBack ? (
        <Button
          className="bg-secondary border-secondary hover:bg-secondary-dark hover:border-secondary-dark"
          onClick={goBack}
          buttonText="Back"
        />
      ) : (
        <div></div>
      )}
      <Button
        className="bg-tertiary border-tertiary hover:bg-tertiary-dark hover:border-tertiary-dark"
        onClick={goNext || doComplete}
        buttonText="Next"
      />
    </div>
  );
};

export default Navigation;
