/* eslint-disable @typescript-eslint/no-explicit-any */

import './Address.css';

import { MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  Suggestion
} from 'react-places-autocomplete';

import { AddressData } from './AddressTypes';
import { boundingBoxCA, boundingBoxUS, IObjectKeys } from './GeoBoundingBox';

// Remove unlicensed provinces from the address question dropdown and will be added back later
const CA_PROVINCES: Record<string, string> = {
  'Newfoundland and Labrador': 'NL',
  'Prince Edward Island': 'PE',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  //Quebec: 'QC',
  Ontario: 'ON',
  Manitoba: 'MB',
  Saskatchewan: 'SK',
  Alberta: 'AB',
  'British Columbia': 'BC'
  //Yukon: 'YT',
  //'Northwest Territories': 'NT',
  //Nunavut: 'NU'
};

const US_PROVINCES: Record<string, string> = {
  Ohio: 'OH',
  Indiana: 'IN',
  'North Carolina': 'NC',
  Arizona: 'AZ',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Georgia: 'GA',
  Idaho: 'ID',
  Illinois: 'IL',
  Iowa: 'IA',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Michigan: 'MI',
  Minnesota: 'MN',
  Missouri: 'MO',
  Nebraska: 'NE',
  'New Hampshire': 'NH',
  'New Mexico': 'NM',
  'North Dakota': 'ND',
  Oklahoma: 'OK',
  Oregon: 'OR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Utah: 'UT',
  Virginia: 'VA',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  Arkansas: 'AR',
  Kansas: 'KS',
  Maryland: 'MD',
  'New Jersey': 'NJ',
  Pennsylvania: 'PA',
  Vermont: 'VT',
  Alabama: 'AL',
  Hawaii: 'HI',
  Massachusetts: 'MA',
  Montana: 'MT',
  Nevada: 'NV',
  Washington: 'WA',
  'West Virginia': 'WV',
  Texas: 'TX',
  'New York': 'NY',
  Florida: 'FL',
  California: 'CA',
  Alaska: 'AK',
  Mississippi: 'MS',
  'District Of Columbia': 'DC'
};

const abrevCountry: IObjectKeys = {
  US: 'US',
  CA: 'CA'
};
const longCountry: IObjectKeys = { US: 'USA', CA: 'Canada' };

const getProvinces = (country?: string) => {
  if (country === abrevCountry.CA) {
    return CA_PROVINCES;
  }
  if (country === abrevCountry.US) {
    return US_PROVINCES;
  }
  return { ...CA_PROVINCES, ...US_PROVINCES };
};
interface AddressProps {
  value?: AddressData;
  onChange: (value: AddressData) => void;
  title?: string;
  country?: string;
  province?: string;
  preventStateOrProvinceChange: boolean;
  filterProvince: boolean;
}

const loadFromSessionStorage = (): string => {
  try {
    const serializedState = sessionStorage.getItem('Province_state');
    if (serializedState === null) {
      return '';
    }
    return serializedState;
  } catch {
    return '';
  }
};

const saveToSessionStorage = (state: string): void => {
  try {
    sessionStorage.setItem('Province_state', state);
  } catch (e) {
    //console.log(e);
  }
};

const Address: React.FC<AddressProps> = (props) => {
  const {
    value,
    onChange,
    title,
    country,
    province: selectedProvinceOrState,
    preventStateOrProvinceChange,
    filterProvince
  } = props;

  let provinceFilteredBy = selectedProvinceOrState ?? '';

  if (provinceFilteredBy.length > 2) {
    saveToSessionStorage(provinceFilteredBy);
  } else {
    provinceFilteredBy = loadFromSessionStorage();
  }

  const defaultStreet = value?.street || '';
  const defaultProv = value?.province || '';
  const defaultPostalCode = value?.postalCode || '';
  const defaultCity = value?.city || '';

  const defaultAddress: AddressData = {
    street: defaultStreet,
    province: defaultProv,
    postalCode: defaultPostalCode,
    city: defaultCity
  };
  if (value?.address2) {
    defaultAddress.address2 = value.address2;
  }
  const [address, setAddress] = useState<AddressData>(defaultAddress);
  const [shouldShowOtherFields, setShouldShowOtherFields] = useState(
    !!value?.street
  );

  const provices = getProvinces(country);
  const sortedAbrevProvinces = Object.values(provices).sort();

  const postalCodePlaceholder =
    country === abrevCountry.US ? 'Zip Code' : 'Postal Code';

  const updateAddress = (newAddress: AddressData) => {
    setAddress(newAddress);
    onChange(newAddress);
  };

  const handleSelect = (addressInput: any) => {
    geocodeByAddress(addressInput)
      .then((results: any[]) => {
        const result = results[0];
        // eslint-disable-next-line camelcase
        const { address_components } = result;

        const streetNumber = address_components.filter(
          (item: { types: string | string[] }) =>
            item.types.includes('street_number')
        );
        const route = address_components.filter(
          (item: { types: string | string[] }) => item.types.includes('route')
        );
        const province2 = address_components.filter(
          (item: { types: string | string[] }) =>
            item.types.includes('administrative_area_level_1')
        );
        const postalCode2 = address_components.filter(
          (item: { types: string | string[] }) =>
            item.types.includes('postal_code')
        );

        const address2 = address_components.filter(
          (item: { types: string | string[] }) =>
            item.types.includes('subpremise')
        );

        const streetNumberLabel =
          streetNumber.length > 0 ? streetNumber[0].short_name : '';
        const routeLabel = route.length > 0 ? route[0].short_name : '';
        const provinceLabel =
          province2.length > 0 ? province2[0].short_name : '';
        const postalCodeLabel =
          postalCode2.length > 0 ? postalCode2[0].short_name : '';
        const city2 = address_components.filter(
          (item: { types: string | string[] }) =>
            item.types.includes('locality') ||
            item.types.includes('sublocality')
        );
        const address2Label =
          address2.length > 0 ? address2[0].short_name : null;

        const streetLabel = `${streetNumberLabel} ${routeLabel}`;
        const cityLabel = city2.length > 0 ? city2[0].short_name : '';

        const newAddress: AddressData = {
          street: streetLabel,
          province: provinceLabel,
          postalCode: postalCodeLabel,
          city: cityLabel
        };

        if (address2Label) {
          newAddress.address2 = address2Label;
        }

        if (!shouldShowOtherFields) {
          setShouldShowOtherFields(true);
        }
        updateAddress(newAddress);

        return;
      })
      .catch(() => {
        //no-op Sentry should already captures the exception
      });
  };

  const getDefaultBounds = (country: string, province: string) => {
    if (country == abrevCountry.CA) {
      const prov: string = CA_PROVINCES[province];
      return boundingBoxCA[prov];
    }

    if (country == abrevCountry.US) {
      const prov: string = US_PROVINCES[province];
      return boundingBoxUS[prov];
    }
  };

  const filteredSuggestions: Array<Suggestion> = new Array<Suggestion>();
  const filterSuggestions = (suggestions: ReadonlyArray<Suggestion>): void => {
    filteredSuggestions.splice(0, filteredSuggestions.length);
    suggestions.map((element: Suggestion) => {
      let abrevProvince: string;
      if (country == abrevCountry.CA) {
        abrevProvince = CA_PROVINCES[provinceFilteredBy];
      } else {
        abrevProvince = US_PROVINCES[provinceFilteredBy];
      }

      const abrevProvinceLongCountry =
        abrevProvince +
        ', ' +
        (country == abrevCountry.CA
          ? longCountry[abrevCountry.CA]
          : longCountry[abrevCountry.US]);

      if (
        element.description &&
        element.description.includes(abrevProvinceLongCountry)
      )
        filteredSuggestions.push(element);
    });
  };

  return (
    <PlacesAutocomplete
      value={address.street}
      searchOptions={{
        componentRestrictions: {
          country: country ?? abrevCountry.CA
        },
        bounds: getDefaultBounds(
          country === abrevCountry.CA ? abrevCountry.CA : abrevCountry.US,
          provinceFilteredBy
        ),
        types: ['address']
      }}
      onChange={(street2: string) => {
        const newAddress = {
          ...address,
          street: street2
        };
        updateAddress(newAddress);
      }}
      onSelect={handleSelect}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading
      }: any) => (
        <div className="input-field-container">
          <div className="mb-2">{title}</div>
          <div className="w-full">
            <input
              {...getInputProps()}
              placeholder="Street Name"
              className="input-field w-full"
              onKeyDown={(e) => {
                getInputProps().onKeyDown(e);
                if (e.key === 'Tab') {
                  handleSelect(address.street);
                }
              }}
            />
          </div>

          <div className="mb-2 text-base">
            {loading && <div>Loading...</div>}
            {filterProvince ? filterSuggestions(suggestions) : null}
            {(filterProvince ? filteredSuggestions : suggestions).map(
              (suggestion: Suggestion, index: number) => {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    key={index}
                    className="p-4 h-16 border-solid border-2 cursor-pointer border-background hover:bg-background"
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              }
            )}{' '}
          </div>
          {shouldShowOtherFields && (
            <>
              <div className="w-full mb-2">
                <input
                  placeholder="Unit/Suite #"
                  className="input-field w-full"
                  value={address.address2 || ''}
                  onChange={(e) => {
                    const address2 = e.target.value;
                    const newAddress = {
                      ...address,
                      address2
                    };
                    updateAddress(newAddress);
                  }}
                />
              </div>
              <div className="grid grid-rows-2 md:grid-rows-1 grid-cols-2 md:grid-cols-5 gap-2">
                <div className="col-span-2 md:col-span-2">
                  <input
                    name="city"
                    placeholder="City"
                    className="input-field w-full"
                    value={address.city}
                    onChange={(e) => {
                      const cityValue = e.target.value;
                      const newAddress = {
                        ...address,
                        city: cityValue
                      };
                      updateAddress(newAddress);
                    }}
                  />
                </div>
                <Select
                  placeholder="Province"
                  className={`custom-select col-span-1 mt-0 mb-2 md:col-span-1 ${
                    preventStateOrProvinceChange ? 'cursor-default' : ''
                  }`}
                  value={address.province}
                  IconComponent={() =>
                    preventStateOrProvinceChange ? null : <ExpandMore />
                  }
                  onChange={({ target }) => {
                    if (typeof target.value === 'string') {
                      updateAddress({
                        ...address,
                        province: target.value
                      });
                    }
                  }}
                  disableUnderline
                  inputProps={{
                    readOnly: preventStateOrProvinceChange,
                    className: preventStateOrProvinceChange
                      ? 'cursor-default bg-transparent'
                      : ''
                  }}
                >
                  {sortedAbrevProvinces.map((p) => {
                    return (
                      <MenuItem
                        style={{
                          paddingTop: 0,
                          borderRadius: 0,
                          backgroundColor: 'white',
                          borderColor: '#403D39'
                        }}
                        key={p}
                        value={p}
                      >
                        {p}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div className="col-span-1 md:col-span-2">
                  <input
                    placeholder={postalCodePlaceholder}
                    className="input-field w-full"
                    value={address.postalCode}
                    onChange={(e) => {
                      const postalCodeValue = e.target.value;
                      const newAddress = {
                        ...address,
                        postalCode: postalCodeValue
                      };
                      updateAddress(newAddress);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default Address;
