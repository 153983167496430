import React from 'react';

interface ButtonProps {
  onClick?: () => void;
  buttonText: string;
  className?: string;
  disabled?: boolean;
  id?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { onClick, className, buttonText, disabled, id } = props;
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={onClick}
      className={`${className} pointer border rounded-2xl py-3 text-2xl text-white font-bold uppercase shadow-lg disabled:opacity-25 disabled:cursor-not-allowed`}
    >
      {buttonText}
    </button>
  );
};

export default Button;
