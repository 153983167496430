// get this mapper from BA
interface CoveragesTitles {
  [key: string]: string;
}

export const coveragesTitles: CoveragesTitles = {
  cgl: 'Commercial General Liability (CGL)',
  miscellaneousEO: 'Professional Liability (E&O)',
  contractorsEO: 'Professional Liability (E&O)',
  limitedPollution: 'Limited Pollution Liability',
  unmannedAircraftEquipment: 'Unmanned Aircraft Equipment Liability',
  additionalInsured: 'Additional Interests (CG 20 37)',
  tria: 'Terrorism',
  mobileEquipment: 'Mobile Equipment (a.k.a Inland Marine)',
  contractorsMobileEquipment: 'Mobile Equipment (a.k.a Inland Marine)',
  unscheduledContractorsEquipment: 'Unscheduled Contractors’ Equipment',
  unscheduledHardwareAndSoftware: 'Unscheduled Hardware and Software',
  unscheduledSmallTools: 'Unscheduled Small Tools',
  contractorsLockAndKey: 'Lock and Key',
  unscheduledBPP: 'Unscheduled Business Personal Property',
  hardwareAndSoftware: 'Hardware and Software',
  lockAndKey: 'Lock and Key',
  photographyAndVideographyEndorsement:
    'Photography and Videography Endorsement',
  unmannedAircraftEndorsement: 'Physical Loss For Unmanned Aircraft',
  valuablePapersEndorsement: 'Valuable Papers Endorsement',
  installationEndorsement: 'Installation'
};
