import React, { useState } from 'react';

export const FeinInputBox: React.FC = () => {
  const [fein, setFein] = useState('');
  return (
    <div className="coverage flex flex-row mb-8">
      FEIN is not valid
      <form
        onSubmit={() => {
          window.alert(fein);
        }}
      >
        <input
          type="text"
          value={fein}
          onChange={(e) => setFein(e.target.value)}
          minLength={9}
          maxLength={9}
        />
        <button type="submit" value="Submit" className="border-2">
          update fein
        </button>
      </form>
    </div>
  );
};
