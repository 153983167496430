interface Choice {
  value: string;
  text: string;
}

// TODO: move this mapper out of code scope
export const secondaryProfessionsMapper: Record<number, Choice[]> = {
  14913: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134210_WORLD_EN',
      text: 'Door Installation'
    }
  ],
  97050: [
    {
      value: 'BusinessInformation_100_Profession_9219_9704708_WORLD_EN',
      text: 'Landscape Construction'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9427603_WORLD_EN',
      text: 'Fence Construction Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997502_WORLD_EN',
      text: 'Window Washing Services'
    }
  ],
  97047: [
    {
      value: 'BusinessInformation_100_Profession_9219_9705004_WORLD_EN',
      text: 'Lawn Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997502_WORLD_EN',
      text: 'Window Washing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9427603_WORLD_EN',
      text: 'Fence Construction Services'
    }
  ],
  98344: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9233804_WORLD_EN',
      text: 'Drywall Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9830502_WORLD_EN',
      text: 'Painters'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9974604_WORLD_EN',
      text: 'Tile Installation'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134209_WORLD_EN',
      text: 'Finish Carpentry'
    }
  ],
  94569: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9974604_WORLD_EN',
      text: 'Tile Installation'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134209_WORLD_EN',
      text: 'Finish Carpentry'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9830502_WORLD_EN',
      text: 'Painters'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9459002_WORLD_EN',
      text: 'Epoxy Surfacing & Polishing'
    }
  ],
  99746: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9456905_WORLD_EN',
      text: 'Flooring Installer'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134209_WORLD_EN',
      text: 'Finish Carpentry'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9830502_WORLD_EN',
      text: 'Painters'
    }
  ],
  92478: [
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115001_WORLD_EN',
      text: 'Appliance Repairers/Repair Services'
    }
  ],
  92338: [
    {
      value: 'BusinessInformation_100_Profession_9219_9830502_WORLD_EN',
      text: 'Painters'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134209_WORLD_EN',
      text: 'Finish Carpentry'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9456905_WORLD_EN',
      text: 'Flooring Installer'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9834402_WORLD_EN',
      text: 'Wallpaper Installation'
    }
  ],
  96053: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9512403_WORLD_EN',
      text: 'Office Furniture & Fixture Assembly and Installation'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115001_WORLD_EN',
      text: 'Appliance Repairers/Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9982602_WORLD_EN',
      text: 'Upholstery Services'
    }
  ],
  91150: [
    {
      value: 'BusinessInformation_100_Profession_9219_9115501_WORLD_EN',
      text: 'Household Appliances Installation & Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  95124: [
    {
      value: 'BusinessInformation_100_Profession_9219_9605303_WORLD_EN',
      text: 'Ikea (or similar) Furniture Assembly Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115001_WORLD_EN',
      text: 'Appliance Repairers/Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9982602_WORLD_EN',
      text: 'Upholstery Services'
    }
  ],
  99826: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9140504_WORLD_EN',
      text: 'Upholstery & Furniture Cleaning'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9681602_WORLD_EN',
      text: 'Janitorial Services'
    }
  ],
  92215: [
    {
      value: 'BusinessInformation_100_Profession_9219_9704712_WORLD_EN',
      text: 'Interlock Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9744701_WORLD_EN',
      text: 'Brick & Stone Masonry Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9896702_WORLD_EN',
      text: 'Siding Contractors'
    }
  ],
  99003: [
    {
      value: 'BusinessInformation_100_Profession_9219_9830504_WORLD_EN',
      text: 'House Paintings'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900402_WORLD_EN',
      text: 'Outdoor Lettering & Numbering Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997502_WORLD_EN',
      text: 'Window Washing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    }
  ],
  91481: [
    {
      value: 'BusinessInformation_100_Profession_9219_9744701_WORLD_EN',
      text: 'Brick & Stone Masonry Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9681602_WORLD_EN',
      text: 'Janitorial Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9896702_WORLD_EN',
      text: 'Siding Contractors'
    }
  ],
  96816: [
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152302_WORLD_EN',
      text: 'Exterior Washing & Cleaning'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9459001_WORLD_EN',
      text: 'Floor Polishing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9140501_WORLD_EN',
      text: 'Carpet Cleaning Service'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997501_WORLD_EN',
      text: 'Window Cleaning Services'
    }
  ],
  91405: [
    {
      value: 'BusinessInformation_100_Profession_9219_9982601_WORLD_EN',
      text: 'Furniture Upholstery/Reupholstery'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9681602_WORLD_EN',
      text: 'Janitorial Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  91580: [
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134202_WORLD_EN',
      text: 'Carpentry Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997501_WORLD_EN',
      text: 'Window Cleaning Services'
    }
  ],
  95625: [
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134202_WORLD_EN',
      text: 'Carpentry Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997501_WORLD_EN',
      text: 'Window Cleaning Services'
    }
  ],
  91342: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9830502_WORLD_EN',
      text: 'Painters'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9233804_WORLD_EN',
      text: 'Drywall Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9427606_WORLD_EN',
      text: 'Fence and Deck Construction'
    }
  ],
  98482: [
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    }
  ],
  91746: [
    {
      value: 'BusinessInformation_100_Profession_9219_9134217_WORLD_EN',
      text: 'Window Installation Service'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9896702_WORLD_EN',
      text: 'Siding Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9744702_WORLD_EN',
      text: 'Bricklayer'
    }
  ],
  98305: [
    {
      value: 'BusinessInformation_100_Profession_9219_9233804_WORLD_EN',
      text: 'Drywall Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900304_WORLD_EN',
      text: 'Muralists'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9834402_WORLD_EN',
      text: 'Wallpaper Installation'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    }
  ],
  95647: [
    {
      value: 'BusinessInformation_100_Profession_9219_9111102_WORLD_EN',
      text: 'Air Conditioning Installation & Repair'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  98304: [
    {
      value: 'BusinessInformation_100_Profession_9219_9233804_WORLD_EN',
      text: 'Drywall Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900304_WORLD_EN',
      text: 'Muralists'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    }
  ],
  97447: [
    {
      value: 'BusinessInformation_100_Profession_9219_9704712_WORLD_EN',
      text: 'Interlock Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9148103_WORLD_EN',
      text: 'Chimney Repair'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9974606_WORLD_EN',
      text: 'Custom Tile Installation'
    }
  ],
  91111: [
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  94276: [
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9704712_WORLD_EN',
      text: 'Interlock Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9896702_WORLD_EN',
      text: 'Siding Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  98967: [
    {
      value: 'BusinessInformation_100_Profession_9219_9427606_WORLD_EN',
      text: 'Fence and Deck Construction'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9744702_WORLD_EN',
      text: 'Bricklayer'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9830504_WORLD_EN',
      text: 'House Painting'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    }
  ],
  98993: [
    {
      value: 'BusinessInformation_100_Profession_9219_9896702_WORLD_EN',
      text: 'Siding Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900304_WORLD_EN',
      text: 'Muralists'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    }
  ],
  13590: [
    {
      value: 'BusinessInformation_100_Profession_9219_9997501_WORLD_EN',
      text: 'Window Cleaning Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9134217_WORLD_EN',
      text: 'Window Installation Service'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900401_WORLD_EN',
      text: 'Window Graphics Services'
    }
  ],
  94590: [
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9681602_WORLD_EN',
      text: 'Janitorial Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9140501_WORLD_EN',
      text: 'Carpet Cleaning Service'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    }
  ],
  99505: [
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9427606_WORLD_EN',
      text: 'Fence and Deck Construction'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9704711_WORLD_EN',
      text: 'Patio Installation'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  98483: [
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9115504_WORLD_EN',
      text: 'Appliance Repair Services'
    }
  ],
  99004: [
    {
      value: 'BusinessInformation_100_Profession_9219_9830504_WORLD_EN',
      text: 'House Painting'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9900402_WORLD_EN',
      text: 'Outdoor Lettering & Numbering Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997502_WORLD_EN',
      text: 'Window Washing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    }
  ],
  91523: [
    {
      value: 'BusinessInformation_100_Profession_9219_9830504_WORLD_EN',
      text: 'House Painting'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9427606_WORLD_EN',
      text: 'Fence and Deck Construction'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9704712_WORLD_EN',
      text: 'Interlock Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9997501_WORLD_EN',
      text: 'Window Cleaning Services'
    }
  ],
  99975: [
    {
      value: 'BusinessInformation_100_Profession_9219_9152305_WORLD_EN',
      text: 'Powerwashing Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_1359002_WORLD_EN',
      text: 'Glass Installation and Repair Services'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9705003_WORLD_EN',
      text: 'Property Maintenance'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ],
  91155: [
    {
      value: 'BusinessInformation_100_Profession_9219_9848201_WORLD_EN',
      text: 'Plumber'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9564701_WORLD_EN',
      text: 'HVAC Contractors'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9247801_WORLD_EN',
      text: 'Electrician'
    },
    {
      value: 'BusinessInformation_100_Profession_9219_9562508_WORLD_EN',
      text: 'Handyperson'
    }
  ]
};
