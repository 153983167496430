/* eslint-disable @typescript-eslint/no-explicit-any */

export interface IObjectKeys {
  [key: string]: any;
}

export const boundingBoxCA: IObjectKeys = {
  SK: { west: -109.99, south: 48.99, east: -101.36, north: 60.0 },
  AB: { west: -120.0, south: 48.99, east: -109.99, north: 60.0 },
  BC: { west: -139.06, south: 48.3, east: -114.03, north: 60.0 },
  NU: { west: -120.68, south: 51.64, east: -61.08, north: 83.11 },
  NT: { west: -136.44, south: 60.0, east: -101.98, north: 78.76 },
  YT: { west: -141.0, south: 60.0, east: -123.81, north: 69.65 },
  ON: { west: -95.16, south: 41.66, east: -74.34, north: 56.86 },
  QC: { west: -79.76, south: 44.99, east: -57.1, north: 62.59 },
  NB: { west: -69.06, south: 44.6, east: -63.77, north: 48.07 },
  NS: { west: -66.32, south: 43.42, east: -59.68, north: 47.03 },
  NL: { west: -67.8, south: 46.61, east: -52.61, north: 60.37 },
  MB: { west: -102.03, south: 48.99, east: -88.94, north: 60.0 },
  PE: { west: -64.41, south: 45.95, east: -61.97, north: 47.06 }
};

export const boundingBoxUS: IObjectKeys = {
  AL: {
    west: -88.473227,
    south: 30.223334,
    east: -84.88908,
    north: 35.008028
  },
  AK: {
    west: -179.148909,
    south: 51.214183,
    east: 179.77847,
    north: 71.365162
  },
  AS: {
    west: -171.089874,
    south: -14.548699,
    east: -168.1433,
    north: -11.046934
  },
  AZ: {
    west: -114.81651,
    south: 31.332177,
    east: -109.045223,
    north: 37.00426
  },
  AR: {
    west: -94.617919,
    south: 33.004106,
    east: -89.644395,
    north: 36.4996
  },
  CA: {
    west: -124.409591,
    south: 32.534156,
    east: -114.131211,
    north: 42.009518
  },
  CO: {
    west: -109.060253,
    south: 36.992426,
    east: -102.041524,
    north: 41.003444
  },
  MP: {
    west: 144.886331,
    south: 14.110472,
    east: 146.064818,
    north: 20.553802
  },
  CT: {
    west: -73.727775,
    south: 40.980144,
    east: -71.786994,
    north: 42.050587
  },
  DE: {
    west: -75.788658,
    south: 38.451013,
    east: -75.048939,
    north: 39.839007
  },
  DC: {
    west: -77.119759,
    south: 38.791645,
    east: -76.909395,
    north: 38.99511
  },
  FL: {
    west: -87.634938,
    south: 24.523096,
    east: -80.031362,
    north: 31.000888
  },
  GA: {
    west: -85.605165,
    south: 30.357851,
    east: -80.839729,
    north: 35.000659
  },
  GU: {
    west: 144.618068,
    south: 13.234189,
    east: 144.956712,
    north: 13.654383
  },
  HI: {
    west: -178.334698,
    south: 18.910361,
    east: -154.806773,
    north: 28.402123
  },
  ID: {
    west: -117.243027,
    south: 41.988057,
    east: -111.043564,
    north: 49.001146
  },
  IL: {
    west: -91.513079,
    south: 36.970298,
    east: -87.494756,
    north: 42.508481
  },
  IN: {
    west: -88.09776,
    south: 37.771742,
    east: -84.784579,
    north: 41.760592
  },
  IA: {
    west: -96.639704,
    south: 40.375501,
    east: -90.140061,
    north: 43.501196
  },
  KS: {
    west: -102.051744,
    south: 36.993016,
    east: -94.588413,
    north: 40.003162
  },
  KY: {
    west: -89.571509,
    south: 36.497129,
    east: -81.964971,
    north: 39.147458
  },
  LA: {
    west: -94.043147,
    south: 28.928609,
    east: -88.817017,
    north: 33.019457
  },
  ME: {
    west: -71.083924,
    south: 42.977764,
    east: -66.949895,
    north: 47.459686
  },
  MD: {
    west: -79.487651,
    south: 37.911717,
    east: -75.048939,
    north: 39.723043
  },
  MA: {
    west: -73.508142,
    south: 41.237964,
    east: -69.928393,
    north: 42.886589
  },
  MI: {
    west: -90.418136,
    south: 41.696118,
    east: -82.413474,
    north: 48.2388
  },
  MN: {
    west: -97.239209,
    south: 43.499356,
    east: -89.491739,
    north: 49.384358
  },
  MS: {
    west: -91.655009,
    south: 30.173943,
    east: -88.097888,
    north: 34.996052
  },
  MO: {
    west: -95.774704,
    south: 35.995683,
    east: -89.098843,
    north: 40.61364
  },
  MT: {
    west: -116.050003,
    south: 44.358221,
    east: -104.039138,
    north: 49.00139
  },
  NE: {
    west: -104.053514,
    south: 39.999998,
    east: -95.30829,
    north: 43.001708
  },
  NV: {
    west: -120.005746,
    south: 35.001857,
    east: -114.039648,
    north: 42.002207
  },
  NH: {
    west: -72.557247,
    south: 42.69699,
    east: -70.610621,
    north: 45.305476
  },
  NJ: {
    west: -75.559614,
    south: 38.928519,
    east: -73.893979,
    north: 41.357423
  },
  NM: {
    west: -109.050173,
    south: 31.332301,
    east: -103.001964,
    north: 37.000232
  },
  NY: {
    west: -79.762152,
    south: 40.496103,
    east: -71.856214,
    north: 45.01585
  },
  NC: {
    west: -84.321869,
    south: 33.842316,
    east: -75.460621,
    north: 36.588117
  },
  ND: {
    west: -104.0489,
    south: 45.935054,
    east: -96.554507,
    north: 49.000574
  },
  OH: {
    west: -84.820159,
    south: 38.403202,
    east: -80.518693,
    north: 41.977523
  },
  OK: {
    west: -103.002565,
    south: 33.615833,
    east: -94.430662,
    north: 37.002206
  },
  OR: {
    west: -124.566244,
    south: 41.991794,
    east: -116.463504,
    north: 46.292035
  },
  PA: { west: -80.519891, south: 39.7198, east: -74.689516, north: 42.26986 },
  PR: {
    west: -67.945404,
    south: 17.88328,
    east: -65.220703,
    north: 18.515683
  },
  RI: {
    west: -71.862772,
    south: 41.146339,
    east: -71.12057,
    north: 42.018798
  },
  SC: { west: -83.35391, south: 32.0346, east: -78.54203, north: 35.215402 },
  SD: {
    west: -104.057698,
    south: 42.479635,
    east: -96.436589,
    north: 45.94545
  },
  TN: {
    west: -90.310298,
    south: 34.982972,
    east: -81.6469,
    north: 36.678118
  },
  TX: {
    west: -106.645646,
    south: 25.837377,
    east: -93.508292,
    north: 36.500704
  },
  VI: {
    west: -65.085452,
    south: 17.673976,
    east: -64.564907,
    north: 18.412655
  },
  UT: {
    west: -114.052962,
    south: 36.997968,
    east: -109.041058,
    north: 42.001567
  },
  VT: {
    west: -73.43774,
    south: 42.726853,
    east: -71.464555,
    north: 45.016659
  },
  VA: {
    west: -83.675395,
    south: 36.540738,
    east: -75.242266,
    north: 39.466012
  },
  WA: {
    west: -124.763068,
    south: 45.543541,
    east: -116.915989,
    north: 49.002494
  },
  WV: {
    west: -82.644739,
    south: 37.201483,
    east: -77.719519,
    north: 40.638801
  },
  WI: {
    west: -92.888114,
    south: 42.491983,
    east: -86.805415,
    north: 47.080621
  },
  WY: {
    west: -111.056888,
    south: 40.994746,
    east: -104.05216,
    north: 45.005904
  }
};
