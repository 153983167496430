import { BijectiveMap, create } from '../bijectiveMap';

export const CANADA_PROVINCES = [
  ['Alberta', 'AB'],
  ['British Columbia', 'BC'],
  ['Manitoba', 'MB'],
  ['New Brunswick', 'NB'],
  ['Newfoundland and Labrador', 'NL'],
  ['Northwest Territories', 'NT'],
  ['Nova Scotia', 'NS'],
  ['Nunavut', 'NU'],
  ['Ontario', 'ON'],
  ['Prince Edward Island', 'PE'],
  ['Quebec', 'QC'],
  ['Saskatchewan', 'SK'],
  ['Yukon', 'YT']
] as const;

class Region<LongName, ShortName> {
  private map: BijectiveMap<LongName, ShortName>;

  constructor(readonly source: readonly (readonly [LongName, ShortName])[]) {
    this.map = create(source);
  }

  public isLongName = (name: unknown): name is LongName =>
    this.map.leftMap.has(name as LongName);

  public isShortName = (name: unknown): name is ShortName =>
    this.map.rightMap.has(name as ShortName);

  public getLongName = (name: ShortName): LongName => {
    const ret = this.map.rightMap.get(name);

    if (ret === undefined) {
      throw new Error('Unreachable - cannot get the long name');
    }
    return ret;
  };

  public getShortName = (name: LongName): ShortName => {
    const ret = this.map.leftMap.get(name);

    if (ret === undefined) {
      throw new Error('Unreachable - cannot get the short name');
    }
    return ret;
  };
}

export const Canada = new Region(CANADA_PROVINCES);

export type CanadaProvince = typeof CANADA_PROVINCES[number][1];

// some of them are commented to match the old codes
const US_STATES = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
] as const;

export const US = new Region(US_STATES);

export type UsState = typeof US_STATES[number][1];
