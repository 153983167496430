import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/link-error';
import * as Sentry from '@sentry/react';

import getEnv from './utils/getEnv';

export function getBackendApolloLinks(): ApolloLink {
  const { REACT_APP_STAGE: stage, REACT_APP_GRAPHQL_URL } = getEnv();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (stage === 'local') return;

      Sentry.withScope((scope) => {
        scope.setExtra('networkErrors', networkError);
        Sentry.captureException(new Error('A graphql error occurred'));
      });
    }

    if (graphQLErrors) {
      if (stage === 'local') return;

      Sentry.withScope((scope) => {
        scope.setExtra('graphQLErrors', graphQLErrors);
        Sentry.captureException(new Error('A graphql error occurred'));
      });
    }
  });

  const underwritingEndpoint = new HttpLink({ uri: REACT_APP_GRAPHQL_URL });

  return ApolloLink.from([errorLink, underwritingEndpoint]);
}
