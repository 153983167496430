export interface BijectiveMap<L, R> {
  leftMap: Map<L, R>;
  rightMap: Map<R, L>;
}

export function create<L, R>(
  source: readonly (readonly [L, R])[]
): BijectiveMap<L, R> {
  const leftMap = new Map(source);
  const rightMap = new Map(source.map(([l, r]) => [r, l]));

  const sourceLen = source.length;
  if (sourceLen !== leftMap.size || sourceLen !== rightMap.size) {
    throw new Error("Source isn't a bijection");
  }

  return {
    leftMap,
    rightMap
  };
}
