import React from 'react';
import * as Survey from 'survey-core';

import SelectInput from './SelectInput';
import { SelectInputQuestionType } from './SelectInputTypes';

const customSelectInputWidget = {
  name: 'customselect',
  title: 'Custom Select',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'customselect';
  },
  activatedByChanged: (): void => {
    Survey.JsonObject.metaData.addClass(
      'customselect',
      [],
      undefined,
      'dropdown'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div><select><option></option></select></div>',
  render: (
    question: SelectInputQuestionType
  ): React.ReactElement<typeof SelectInput> => {
    const onChange = (newValue: string) => {
      question.value = newValue;
    };
    const {
      leftLabel,
      rightLabel,
      name,
      customWidth,
      value,
      choices,
      helperText
    } = question;
    return (
      <SelectInput
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        name={name}
        onChange={onChange}
        customWidth={customWidth}
        value={value}
        options={choices}
        helperText={helperText}
      />
    );
  }
};

export default customSelectInputWidget;
