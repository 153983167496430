import './QuoteLinkPopup.css';

import { Button, ButtonGroup } from '@material-ui/core';
import { Close, Edit, Refresh } from '@material-ui/icons';
import { formatISO } from 'date-fns';
import React, { ChangeEvent, useEffect, useState } from 'react';

import ButtonInput from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Loading from '../../../../components/Loading/Loading';
import {
  AdditionalInsured,
  useSendQuoteLinkMutation,
  useUpdateApplicationAnswersAndQuoteMutation
} from '../../../../generated/graphql';
import { QuoteInfo } from '../../../../generated/rating-quoting-graphql';
import emailImage from '../../../../images/email.svg';
import { VerificationVariablesInput } from '../../QuoteTypes';
import SuccessQuoteLinkEmail from './SuccessQuoteLinkEmail';

interface QuoteLinkPopupProps {
  displayModal: boolean;
  handleClose?: () => void;
  applicationId: string;
  quoteInfo: QuoteInfo;
  additionalInsuredInfoList?: AdditionalInsured[];
  setQuoteId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const QuoteLinkPopup: React.FC<QuoteLinkPopupProps> = ({
  displayModal,
  handleClose,
  applicationId,
  quoteInfo,
  additionalInsuredInfoList,
  setQuoteId
}) => {
  const {
    companyName,
    phoneNumber,
    coverageStartDate,
    email,
    dbaName
  } = quoteInfo;

  const [currentEmail, setCurrentEmail] = useState(email);
  const [primaryEmail, setPrimaryEmail] = useState(email);
  const [warnUpdatePrimaryEmail, setWarnUpdatePrimaryEmail] = useState(true);
  const [secondaryEmails, setSecondaryEmails] = useState<string[]>([]);
  const [emailChangeResponse, setEmailChangeResponse] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const maximumEmailCount = 3;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const data = [...secondaryEmails];
    data[index] = event.target.value;
    setSecondaryEmails(data);
  };

  const addField = () => {
    setSecondaryEmails([...secondaryEmails, '']);
  };

  const removeField = (index: number) => {
    const data = [...secondaryEmails];
    data.splice(index, 1);
    setSecondaryEmails(data);
  };

  const [
    sendQuoteLinkMutation,
    { loading: isSendQuoteLinkLoading, error: sendQuoteLinkError }
  ] = useSendQuoteLinkMutation();

  const onSendQuoteLink = async (
    application: string,
    primaryEmail: string,
    secondaryEmails: string[]
  ) => {
    if (additionalInsuredInfoList) {
      await onupdateApplicationAnswersAndQuote({
        newEffectiveDate: formatISO(new Date(coverageStartDate), {
          representation: 'date'
        }),
        newEmail: primaryEmail,
        newPhoneNumber: phoneNumber,
        newCompanyName: companyName,
        newDBAName: dbaName || undefined,
        additionalInsuredInfoList
      });
    }

    sendQuoteLinkMutation({
      variables: {
        applicationId: application,
        primaryEmail,
        secondaryEmails
      }
    }).then(() => {
      setDisplaySuccess(true);
    });
  };

  const [
    updateApplicationAnswersAndQuoteMutation,
    {
      error: updateApplicationAnswersAndQuoteError,
      loading: updateApplicationAnswersAndQuoteLoading
    }
  ] = useUpdateApplicationAnswersAndQuoteMutation();

  const onupdateApplicationAnswersAndQuote = async (
    input: VerificationVariablesInput
  ) => {
    updateApplicationAnswersAndQuoteMutation({
      variables: {
        applicationId,
        ...input
      }
    }).then((value) => {
      const updateSuccess =
        value?.data?.updateApplicationAnswersAndQuote?.isUpdateSuccessful;

      if (updateSuccess) {
        setEmailChangeResponse(
          `Done! The email address in the application has been updated to "${primaryEmail}."`
        );
        setCurrentEmail(primaryEmail);
      } else {
        setErrorMessage(`"${primaryEmail}" is an invalid email.`);
      }

      const newQuoteId =
        value?.data?.updateApplicationAnswersAndQuote?.newQuoteId;
      if (newQuoteId) {
        setQuoteId(newQuoteId);
      }
    });
  };

  useEffect(() => {
    if (updateApplicationAnswersAndQuoteError || sendQuoteLinkError) {
      const message = String(
        updateApplicationAnswersAndQuoteError || sendQuoteLinkError
      );
      setErrorMessage(message);
    }
  }, [updateApplicationAnswersAndQuoteError, sendQuoteLinkError]);

  const readyToSend =
    (warnUpdatePrimaryEmail && primaryEmail === currentEmail) ||
    (!warnUpdatePrimaryEmail && !!primaryEmail);

  const validateAndSendQuoteEmail = () => {
    if (readyToSend) {
      setEmailChangeResponse('');
      setErrorMessage('');

      const allEmails = [...secondaryEmails, primaryEmail];
      const duplicateEmails = allEmails.filter(
        (email, index) => allEmails.indexOf(email) !== index
      );

      if (duplicateEmails.length > 0) {
        setErrorMessage(
          `Duplicate emails detected: ${duplicateEmails.join(', ')}`
        );
      } else {
        onSendQuoteLink(applicationId, primaryEmail, secondaryEmails);
      }
    }
  };

  const updateEmail = () => {
    setEmailChangeResponse('');
    setErrorMessage('');
    onupdateApplicationAnswersAndQuote({
      newEffectiveDate: formatISO(new Date(coverageStartDate), {
        representation: 'date'
      }),
      newEmail: primaryEmail,
      newPhoneNumber: phoneNumber,
      newCompanyName: companyName,
      newDBAName: dbaName || undefined,
      additionalInsuredInfoList
    });
  };

  const handleCloseAndExitModal = () => {
    setDisplaySuccess(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div
      className={`${
        displayModal ? '' : 'hidden '
      }fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-50 z-50`}
    >
      <div className="flex h-screen justify-center overflow-auto">
        {isSendQuoteLinkLoading ? (
          <div className="bg-white border-white rounded-lg h-fit-content p-24 self-center">
            <Loading />
          </div>
        ) : displaySuccess ? (
          <SuccessQuoteLinkEmail
            handleCloseAndExitModal={handleCloseAndExitModal}
          />
        ) : (
          <div className="relative max-w-xl flex flex-col h-fit-content bg-white border-2 border-white rounded-lg mt-16 mx-2 md:mx-0 p-4 md:p-8 text-left z-10">
            <Close
              className="absolute top-1 right-1 cursor-pointer"
              onClick={handleClose}
            />
            <div className="flex justify-center">
              <img className="h-auto w-auto" src={emailImage} />
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">
              Email Quote Link
            </h2>
            <p className="font-medium">
              Send Quote Link to (Email from Application)
            </p>
            <div className="-mt-2 mb-4 relative">
              <Input
                id="email"
                value={primaryEmail}
                onChange={setPrimaryEmail}
                testId="primary-email"
              />
              {primaryEmail === email ? (
                <Edit className="hidden md:block edit-icon absolute text-faded" />
              ) : null}
            </div>
            {emailChangeResponse ? (
              <p className="text-secondary font-semibold mb-4">
                {emailChangeResponse}
              </p>
            ) : null}
            {primaryEmail !== currentEmail &&
            warnUpdatePrimaryEmail &&
            !updateApplicationAnswersAndQuoteLoading ? (
              <p className="font-semibold mb-4">
                {`Do you want to update the email address in the application to
              "${primaryEmail}" too?`}
              </p>
            ) : (
              ''
            )}
            {primaryEmail !== currentEmail &&
            warnUpdatePrimaryEmail &&
            !updateApplicationAnswersAndQuoteLoading ? (
              <div className="ToggleButton uppercase font-bold mb-4">
                <ButtonGroup className="border-secondary w-full">
                  <Button
                    className={`toggle-button rounded-l-xl text-secondary`}
                    onClick={() => {
                      setEmailChangeResponse(
                        `Got it, no changes have been made. The email address in the application is still "${currentEmail}."`
                      );
                      setWarnUpdatePrimaryEmail(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    className={`toggle-button rounded-r-xl text-secondary`}
                    onClick={updateEmail}
                  >
                    Yes
                  </Button>
                </ButtonGroup>
              </div>
            ) : null}
            {updateApplicationAnswersAndQuoteLoading ? (
              <Refresh className="mx-auto text-secondary animate-spin mb-4" />
            ) : (
              ''
            )}
            <p className="mb-4">
              You can also add (up to 3) additional emails to send the quote
              link to. They will be CC’d on the email.
            </p>
            <p className="font-medium mb-2">Also Send Quote Link to:</p>
            {secondaryEmails.map((input, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  id={`additional-email-${index + 1}`}
                  className="input-field w-full"
                  value={input}
                  onChange={(event) => {
                    handleChange(event, index);
                  }}
                />
                <button
                  className="remove-button"
                  onClick={() => removeField(index)}
                >
                  Remove
                  <svg className="w-4 h-4" viewBox="0 0 130.28 162.98">
                    <polygon
                      className="cls-1"
                      points="130.28 24.47 0 24.47 0 7.95 41.93 7.95 48.97 0 82.29 0 88.67 7.95 130.28 7.95 130.28 24.47"
                    />
                    <path
                      className="cls-1"
                      d="M8.39,40.51V147.16A15.82,15.82,0,0,0,24.21,163h81.72a15.82,15.82,0,0,0,15.82-15.82V40.51ZM49,146.18H32.49V57.11H49Zm48.93,0H81.43V57.11H98Z"
                    />
                  </svg>
                </button>
              </div>
            ))}
            {secondaryEmails.length < maximumEmailCount ? (
              <div className="mt-2">
                <button className="add-button" onClick={addField}>
                  Add Another Email +
                </button>
              </div>
            ) : null}
            {errorMessage ? (
              <p className="text-red-600 font-semibold mt-4">{errorMessage}</p>
            ) : null}
            <ButtonInput
              buttonText="Send Email"
              onClick={validateAndSendQuoteEmail}
              className={`${readyToSend ? 'bg-tertiary' : 'bg-faded'} mt-6`}
            ></ButtonInput>
          </div>
        )}
        <div onClick={handleClose} className="fixed h-screen w-screen"></div>
      </div>
    </div>
  );
};

export default QuoteLinkPopup;
