import React from 'react';
import * as Survey from 'survey-core';

import { BooleanQuestionType } from './BooleanTypes';
import ToggleButton from './ToggleButton';

// add custom widget
const customToggleButtonWidget = {
  name: 'customboolean',
  title: 'Custom Boolean',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'customboolean';
  },
  activatedByChanged: (): void => {
    Survey.JsonObject.metaData.addClass(
      'customboolean',
      [],
      undefined,
      'boolean'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  render: (
    question: BooleanQuestionType
  ): React.ReactElement<typeof Boolean> => {
    const onChange = (newValue: boolean) => {
      question.value = newValue;
    };
    const { title, helperText, labelTrue, labelFalse, value } = question;
    return (
      <ToggleButton
        title={title}
        onChange={onChange}
        helperText={helperText}
        labelTrue={labelTrue}
        labelFalse={labelFalse}
        value={value}
      />
    );
  }
};

export default customToggleButtonWidget;
