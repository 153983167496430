import { isArray, mergeWith } from 'lodash';

import usMunichCoveragesDetails from './usMunichCoveragesDetails.json';
import usStateNationalCoveragesDetails from './usStateNationalCoveragesDetails.json';

export interface PopupContent {
  title: string;
  subTitle?: string;
  whatCovered?: string;
  whatNotCoverd?: string;
  moreInformation?: string;
}

interface Option {
  text: string;
  value: string;
}
interface CoverageDetails {
  disable: boolean;
  img: string;
  title: string;
  subTitle: string;
  popupTitle?: string;
  popupContent?: PopupContent;
  limitsOptions?: Record<string, Array<Option>>;
}

interface LimitsLabels {
  [key: string]: string;
}

export const limitsLabels: LimitsLabels = {
  occurrenceLimit: 'Occurrence Limit',
  aggregateLimit: 'Aggregate Limit',
  coverageLimit: 'Coverage Limit',
  tenantLegalLiabilityLimit: 'Tenants Legal Liability',
  premisesRentedToYouLimit: 'Damage to Premises Rented to You',
  medicalPaymentsLimit: 'Medical Expenses',
  deductible: 'Deductible',
  productAggregateLimit: 'Products/Completed Operations Aggregate Limit',
  personalAdvertisingInjury: 'Personal and Advertising Injury',
  blanketAdditionalInsured: 'Blanket Additional Insured',
  blanketWaiverOfSubrogation: 'Blanket Waiver of Subrogation',
  primaryAndNonContributory: 'Primary and Non-Contributory (CG 20 01)',
  noLimit: ''
};

export const endorsementNames = [
  'photographyAndVideographyEndorsement',
  'unmannedAircraftEndorsement',
  'valuablePapersEndorsement',
  'installationEndorsement'
];

export const endorsementDefaultValues: Record<string, number> = {
  photographyAndVideographyEndorsement: 1000,
  unmannedAircraftEndorsement: 1000,
  valuablePapersEndorsement: 1000,
  installationEndorsement: 1000
};

const usCoveragesDetailsIneligible: Record<string, CoverageDetails> = {
  ...usMunichCoveragesDetails,
  DO: {
    disable: false,
    img:
      'https://foxden-quote-page-images.s3.amazonaws.com/DEVOPS-101_attachments/foxquilt-General_liability.png',
    title: 'Management Liability (D&O)',
    subTitle:
      'Protects the personal assets of corporate directors and officers, in the event they are personally sued for actual or alleged wrongful acts in managing a company.'
  }
};

const commonCanadaCoveragesDetails: Record<
  string,
  CoverageDetails
  // eslint-disable-next-line @typescript-eslint/no-var-requires
> = require('./commonCanadaCoveragesDetails.json');

const greenlightSpecificToCanadaCoveragesDetails: Record<
  string,
  CoverageDetails
  // eslint-disable-next-line @typescript-eslint/no-var-requires
> = require('./greenlightCanadaCoveragesDetails.json');

const canadaCoveragesDetailsIneligible: Record<string, CoverageDetails> = {
  ...commonCanadaCoveragesDetails,
  DO: {
    disable: false,
    img:
      'https://foxden-quote-page-images.s3.amazonaws.com/DEVOPS-101_attachments/foxquilt-General_liability.png',
    title: 'Management Liability (D&O)',
    subTitle:
      'Protects the personal assets of corporate directors and officers, in the event they are personally sued for actual or alleged wrongful acts in managing a company.'
  }
};

export const getCoveragesDetails = (
  region: string,
  carrierPartner: string
): Record<string, CoverageDetails> => {
  if (region === 'Canada') {
    if (carrierPartner === 'Greenlight') {
      return mergeWith(
        commonCanadaCoveragesDetails,
        greenlightSpecificToCanadaCoveragesDetails,
        (a, b) => (isArray(b) ? b : undefined)
      );
    }
    return commonCanadaCoveragesDetails;
  }
  if (carrierPartner === 'StateNational') {
    return usStateNationalCoveragesDetails;
  }
  return usMunichCoveragesDetails;
};

// It looks like the "ineligible quote" case has not been implemented fully on the backend
// so this function appears like it will never be executed (this case was meant to be
// for the 2022 Amtrust implementation)
// If this is ever used it will likely need modified to use the greenlightSpecificToCanadaCoveragesDetails
// TBD: "ineligible quote" is not used in production, do some clean up if no need.
export const getCoveragesDetailsIneligible = (
  region: string
): Record<string, CoverageDetails> => {
  if (region === 'Canada') {
    return canadaCoveragesDetailsIneligible;
  }
  return usCoveragesDetailsIneligible;
};
