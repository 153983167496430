import './App.css';

import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Header from './components/Header';
import {
  CommercialPage,
  CommercialWithApplicationIdPage,
  CompletePage,
  ErrorPage,
  NotFound,
  QuotePage
} from './pages';
import getEnv from './utils/getEnv';
import ContextProvider from './utils/store';

const { REACT_APP_HUBSPOT_ACCOUNT } = getEnv();

const App: React.FC = () => {
  useEffect(() => {
    // add hubspot tracking script
    const script = document.createElement('script');
    const hubspotAccount = REACT_APP_HUBSPOT_ACCOUNT;
    script.src = `//js.hs-scripts.com/${hubspotAccount}.js`;
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);

  return (
    <ContextProvider>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        <Header />
        <Switch>
          <Route exact path="/" component={CommercialPage} />
          <Route
            exact
            path="/commercial"
            render={(props) => {
              const { search } = props.location;
              return <Redirect to={{ pathname: '/', search }} />;
            }}
          />
          <Route
            exact
            path="/application"
            render={(props) => {
              const { search } = props.location;
              return <Redirect to={{ pathname: '/', search }} />;
            }}
          />
          <Route
            exact
            path="/:applicationId([a-fA-F0-9]{24})"
            component={CommercialWithApplicationIdPage}
          />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/quote/:applicationId" component={QuotePage} />
          <Route exact path="/complete" component={CompletePage} />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default App;
