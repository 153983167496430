import dotenv from 'dotenv-flow';

import isJestRunning from './isJestRunning';

const STRING_BASE = [
  'REACT_APP_GRAPHQL_URL',
  'REACT_APP_GTM_ID',
  'REACT_APP_HUBSPOT_ACCOUNT',
  'REACT_APP_LAUNCH_DARKLY_CLIENT_KEY',
  'REACT_APP_PAYMENT_FRONTEND_URL',
  'REACT_APP_PAYMENT_GRAPHQL_URL',
  'REACT_APP_PUBLIC_URL',
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_STAGE',
  'REACT_APP_QUESTION_TRACKING_URL',
  'REACT_APP_BUY_NOW_TRACKING_URL',
  'REACT_APP_RATING_QUOTING_GRAPHQL_URL',
  'REACT_APP_VERSION_CONTROLLER_GRAPHQL_URL',
  'REACT_APP_ADMIN_FRONTEND_URL'
] as const;

type Keys = typeof STRING_BASE[number];

export type Env = { [index in Keys]: string };

if (isJestRunning) {
  // can't figure out how to use .jest.config.js since it's managed by react-scripts, so the workaround
  // for globalSetup is to instead setup .env here

  // inject enviornment variables with dotenv-flow (which takes care of .env*.local), since Jest isn't handling it
  dotenv.config({ node_env: 'development', silent: true });
}

// runtime check during bootstrap
STRING_BASE.forEach((key) => {
  const val = process.env[key];
  if (!val) {
    throw new Error(`Environment variable isn't defined, ${key}`);
  }
});

export default function (): Env {
  // same signature, but defined fields are casted to non-nullable version
  return (process.env as unknown) as Env;
}
