import './Coverages.css';

import React from 'react';

import {
  AmtrustEligibleQuoteResponse,
  AmtrustIneligibleQuoteResponse,
  MunichEligibleQuoteResponse,
  MunichIneligibleQuoteResponse,
  UpdateCoverageInput
} from '../../../../generated/graphql';
import { useFlags } from '../../../../utils/useFlags';
import { CoveragesDataInput } from '../../index';
import { AmtrustCoverages, IneligibleAmtrustQuote } from './amtrustCoverage';
import { IneligibleMunichQuote, MunichCoverageDetail } from './munichCoverage';

interface CoveragesProps {
  amtrustCoverageInput:
    | AmtrustEligibleQuoteResponse
    | AmtrustIneligibleQuoteResponse
    | undefined;
  munichCoverageInput:
    | MunichEligibleQuoteResponse
    | MunichIneligibleQuoteResponse
    | undefined;
  onUpdateCoverage: (coverage: UpdateCoverageInput) => void;
  onResetRecommendation: () => void;
  applicationId: string;
  isEndorsement: boolean;
  carrierPartner?: string;
}

const Coverages: React.FC<CoveragesProps> = (props) => {
  const flags = useFlags();
  const { amTrustFullFlow } = flags;
  const {
    amtrustCoverageInput,
    munichCoverageInput,
    onUpdateCoverage,
    applicationId,
    onResetRecommendation,
    isEndorsement,
    carrierPartner
  } = props;
  const coveragesData =
    munichCoverageInput?.__typename === 'MunichEligibleQuoteResponse'
      ? (munichCoverageInput.coveragesData as CoveragesDataInput[])
      : [];

  const selectedCoverages =
    munichCoverageInput?.__typename === 'MunichIneligibleQuoteResponse'
      ? munichCoverageInput.selectedCoverages
      : [];
  if (!amtrustCoverageInput && !munichCoverageInput) {
    throw Error('no available getQuote input');
  }

  const { eligibleAmtrustCoverages, ineligibleAmtrustCoverages } = (() => {
    if (
      amtrustCoverageInput?.__typename === 'AmtrustEligibleQuoteResponse' &&
      amTrustFullFlow
    ) {
      return {
        eligibleAmtrustCoverages: [
          <AmtrustCoverages
            key={'WorkersCompensationCoverage'}
            onUpdateCoverage={onUpdateCoverage}
            amtrustQuoteId={amtrustCoverageInput.amtrustquoteId}
            availableLimit={amtrustCoverageInput.availableLimit}
            limit={amtrustCoverageInput.limit}
            feinIsValid={amtrustCoverageInput.feinIsValid}
          />
        ],
        ineligibleAmtrustCoverages: []
      };
    }

    if (amtrustCoverageInput) {
      return {
        eligibleAmtrustCoverages: [],
        ineligibleAmtrustCoverages: [
          <IneligibleAmtrustQuote key={'WorkersCompensationCoverage'} />
        ]
      };
    }
    return {
      eligibleAmtrustCoverages: Array<JSX.Element>(),
      ineligibleAmtrustCoverages: Array<JSX.Element>()
    };
  })();

  const { eligibleMunichCoverages, ineligibleMunichCoverages } = (() => {
    if (
      munichCoverageInput?.__typename === 'MunichEligibleQuoteResponse' &&
      carrierPartner !== undefined
    ) {
      return {
        eligibleMunichCoverages: coveragesData
          .filter((coverageData) => coverageData.name !== 'additionalInsured') // additonal insured is in another component
          .map((coverageData) => {
            return (
              <MunichCoverageDetail
                coverageData={coverageData}
                key={coverageData.name}
                onUpdateCoverage={onUpdateCoverage}
                region={munichCoverageInput.region}
                quoteId={munichCoverageInput.quoteId}
                applicationId={applicationId}
                carrierPartner={carrierPartner}
              />
            );
          }),
        ineligibleMunichCoverages: []
      };
    }

    if (munichCoverageInput?.__typename === 'MunichIneligibleQuoteResponse') {
      return {
        eligibleMunichCoverages: [],
        ineligibleMunichCoverages: selectedCoverages.map((selectedCoverage) => {
          return (
            <IneligibleMunichQuote
              key={selectedCoverage}
              region={munichCoverageInput.region}
              selectedCoverage={selectedCoverage}
            />
          );
        })
      };
    }
    return {
      eligibleMunichCoverages: Array<JSX.Element>(),
      ineligibleMunichCoverages: Array<JSX.Element>()
    };
  })();

  const eligibleCoverages = eligibleAmtrustCoverages.concat(
    eligibleMunichCoverages
  );
  const ineligibleCoverages = ineligibleAmtrustCoverages.concat(
    ineligibleMunichCoverages
  );

  return (
    <div className="coverages-container mt-2 md:mt-0">
      <div className="coverages-header flex justify-between bg-secondary-light px-8 py-4 font-bold text-2xl text-quartary">
        <p>Recommended Policy:</p>
        <button
          className="text-base text-secondary underline"
          onClick={() => {
            onResetRecommendation();
            if (
              amtrustCoverageInput?.__typename ===
                'AmtrustEligibleQuoteResponse' &&
              amtrustCoverageInput.proposalDownloadLink
            ) {
              window.open(amtrustCoverageInput.proposalDownloadLink);
              window.alert('amtrust proposal document is downloaded');
            }
          }}
          disabled={isEndorsement}
        >
          Reset
        </button>
      </div>
      <div className="coverages-inner-container px-8 pt-4 pb-8 relative md:mb-28 lg:mb-0">
        <div className="coverages-details overflow-hidden lg:overflow-auto max-h-full">
          {eligibleCoverages}
          {ineligibleCoverages}
        </div>
      </div>
    </div>
  );
};

export default Coverages;
