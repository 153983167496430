import React from 'react';
import * as Survey from 'survey-core';

import CoverageOption from './CoverageOption';
import {
  CoverageOptionData,
  CoverageOptionQuestion
} from './CoverageOptionTypes';

const coverageOptionWidget = {
  name: 'coverageoption',
  title: 'Coverage Option',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'coverageoption';
  },
  activatedByChanged: (): void => {
    // add properties
    Survey.Serializer.addProperties('question', [
      {
        name: 'coverageTitle:string',
        default: 'Put your coverage title here',
        category: 'coverageProperties'
      },
      {
        name: 'coverageDescription:string',
        default: 'Put your coverage description here',
        category: 'coverageProperties'
      },
      {
        name: 'coverageOptions:itemvalues',
        default: [],
        category: 'coverageProperties'
      },
      {
        name: 'hideLimit:boolean',
        default: [],
        category: 'coverageProperties'
      }
    ]);

    Survey.JsonObject.metaData.addClass(
      'coverageoption',
      [],
      undefined,
      'multipletext'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  render: (
    question: CoverageOptionQuestion
  ): React.ReactElement<typeof CoverageOption> => {
    const {
      coverageTitle,
      coverageDescription,
      value,
      helperText,
      readOnly
    } = question;

    const handleQuestionUpdate = (newValue: CoverageOptionData) => {
      question.value = newValue;
    };

    return (
      <CoverageOption
        coverageTitle={coverageTitle}
        coverageDescription={coverageDescription}
        value={value}
        onChange={handleQuestionUpdate}
        helperText={helperText}
        readOnly={readOnly}
      />
    );
  }
};

export default coverageOptionWidget;
