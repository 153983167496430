import CancelIcon from '@material-ui/icons/Cancel';
import React, { Fragment, useMemo } from 'react';

import SelectInput from '../../../../components/QuotePageSelectInput/SelectInput';
import { CustomWidth } from '../../../../components/QuotePageSelectInput/SelectInputTypes';
import {
  UpdateCoverageInput,
  WcAvailableLimit
} from '../../../../generated/graphql';
import { FeinInputBox } from './feinInputBox';

const toKey = (limit: WcAvailableLimit) =>
  `${limit.perEmployeeIllness}|${limit.perAccident}|${limit.overallIllness}`;

function getByValue(
  map: Map<string, WcAvailableLimit>,
  searchValue: WcAvailableLimit
) {
  for (const [key, value] of map.entries()) {
    // todo find a better way of this comparation
    if (
      value.overallIllness === searchValue.overallIllness &&
      value.perAccident === searchValue.perAccident &&
      value.perEmployeeIllness === searchValue.perEmployeeIllness
    )
      return key;
  }
}

interface AmtrustCoverageProps {
  availableLimit: Array<WcAvailableLimit>;
  limit: WcAvailableLimit;
  amtrustQuoteId: number;
  onUpdateCoverage: (input: UpdateCoverageInput) => void;
  feinIsValid: boolean;
}
export const AmtrustCoverages: React.FC<AmtrustCoverageProps> = (props) => {
  const {
    availableLimit,
    amtrustQuoteId,
    onUpdateCoverage,
    limit,
    feinIsValid
  } = props;
  limit;
  const limitPairs = availableLimit.map((limitItem): [
    string,
    WcAvailableLimit
  ] => [toKey(limitItem), limitItem]);

  const availableLimitMap = useMemo(() => new Map(limitPairs), [limitPairs]);
  return (
    <div>
      {feinIsValid ? <FeinInputBox /> : null}
      <div className="coverage flex flex-row mb-5">
        <input
          type="checkbox"
          className="bg-gray-500 mt-5 mx-4"
          checked
          disabled={false}
          onChange={() => {
            return;
          }}
        />
        <img
          src={
            'https://foxden-quote-page-images.s3.amazonaws.com/DEVOPS-101_attachments/foxquilt-workers-compensation.png'
          }
          className="w-14 mr-6 ml-4 h-14 sm:w-8"
        />
        <div>
          <p>
            <b className="font-black text-base md:text-2xl ">
              Workers&apos; Compensation (WC)
            </b>
          </p>
          <p className="text-gray-700 mt-2 mb-2 text-xs md:text-base ">
            Protects your business and your workforce by providing{' '}
            <b>benefits to most employees injured on the job</b>. These benefits
            medical care and related medical costs, retraining and lost wages
            until the employee can return to work or compensation for permanent
            disability.
          </p>
          <Fragment>
            <div>
              <Fragment>
                <div className="limit text-sm md:text-base mb-2">
                  <b className="py-4 mr-2 text-sm md:text-base">
                    Coverage Limit:
                  </b>
                  <SelectInput
                    name="name"
                    onChange={(newValue) => {
                      const wcLimitInput = availableLimitMap.get(newValue);
                      if (!wcLimitInput) {
                        throw Error();
                      }
                      onUpdateCoverage({
                        amtrustInput: {
                          wcLimit: {
                            overallIllness: wcLimitInput.overallIllness,
                            perAccident: wcLimitInput.perAccident,
                            perEmployeeIllness: wcLimitInput.perEmployeeIllness
                          },
                          wcQuoteId: amtrustQuoteId
                        }
                      });
                    }}
                    options={limitPairs.map((item) => ({
                      text: item[0],
                      value: item[0]
                    }))}
                    value={getByValue(availableLimitMap, limit)}
                    customWidth={CustomWidth.Small}
                  />{' '}
                </div>
              </Fragment>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export const IneligibleAmtrustQuote: React.FC = () => {
  return (
    <div className="coverage flex flex-row mb-5">
      <CancelIcon className="cancel-icon mt-4 mx-3" />
      <img
        src={
          'https://foxden-quote-page-images.s3.amazonaws.com/DEVOPS-101_attachments/foxquilt-workers-compensation.png'
        }
        className="w-8 md:w-14 mr-6 ml-4 h-14"
      />
      <div>
        <p>
          <b className="font-black text-base md:text-2xl ">
            Workers&apos; Compensation (WC)
          </b>
        </p>
        <p className="text-gray-700 mt-2 mb-2 text-xs md:text-base ">
          Protects your business and your workforce by providing{' '}
          <b>benefits to most employees injured on the job</b>. These benefits
          can address medical care and related medical costs, retraining and
          lost wages until the employee can return to work or compensation for
          permanent disability.
        </p>
        <p className="text-gray-700 font-bold mt-2 mb-2 text-xs md:text-base ">
          A licensed Foxquilt agent will reach out to you shortly regarding your
          interest in{' '}
          <span className="text-primary">
            Workers&apos; Compensation Insurance
          </span>
          .
        </p>
      </div>
    </div>
  );
};
