import React from 'react';

interface BannerProps {
  isVisible: boolean;
  message: string;
}

const Banner: React.FC<BannerProps> = (props) => {
  const { isVisible, message } = props;

  if (isVisible) {
    return (
      <div className="bg-alert fixed w-full text-center z-10">
        <div className="text-center py-3 font-bold text-2xl">{message}</div>
      </div>
    );
  }

  return null;
};

export default Banner;
