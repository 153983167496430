import { Tooltip } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

interface InputProps {
  leftLabel?: string;
  rightLabel?: string;
  description?: string;
  value?: string;
  name?: string;
  placeHolder?: string;
  type?: string;
  onChange: (value: string) => void;
  helperText?: string;
  useSeparator?: boolean;
  allowNegative?: boolean;
  useDollarSignPrefix?: boolean;
  readOnly?: boolean;
  inputClass?: string;
  id?: string;
  testId?: string;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    leftLabel,
    value,
    name,
    placeHolder,
    type,
    onChange,
    helperText,
    useSeparator,
    allowNegative,
    useDollarSignPrefix,
    readOnly,
    inputClass,
    id,
    testId,
    description
  } = props;

  const defaultValue = value === undefined ? '' : value;

  return (
    <span className="input-field-container" data-testid="Input">
      <div className="mb-2 md:mb-4">
        {leftLabel ? <span>{leftLabel}</span> : undefined}
        {helperText ? (
          <Tooltip
            title={helperText}
            arrow
            classes={{
              arrow: 'text-secondary',
              tooltip: 'text-sm bg-secondary'
            }}
            enterTouchDelay={1}
          >
            <div className="help-button">?</div>
          </Tooltip>
        ) : null}
        {description ? (
          <p className="font-semibold text-base">{description}</p>
        ) : null}
      </div>
      {type === 'number' ? (
        <NumberFormat
          className="input-field"
          thousandSeparator={useSeparator}
          prefix={useDollarSignPrefix ? '$' : undefined}
          allowNegative={allowNegative}
          onValueChange={(value) => onChange(value.value)}
          readOnly={readOnly}
          value={defaultValue}
        />
      ) : (
        <input
          id={id}
          data-testid={testId}
          className={`input-field ${inputClass ? inputClass : ''}`}
          name={name}
          placeholder={placeHolder || ''}
          value={defaultValue}
          readOnly={readOnly}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )}
    </span>
  );
};

export default Input;
