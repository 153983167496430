import React from 'react';
import * as Survey from 'survey-core';

import SelectInput from './SearchSelect';
import { SearchSelectQuestionType } from './SearchSelectTypes';

const searchSelecttWidget = {
  name: 'searchselect',
  title: 'Search Select',
  iconName: '',
  widgetIsLoaded: (): boolean => {
    return true;
  },
  isFit: function (question: Survey.IQuestion): boolean {
    return question.getType() === 'searchselect';
  },
  activatedByChanged: (): void => {
    // add properties
    Survey.Serializer.addProperties('question', [
      {
        name: 'placeHolder:string',
        default: '',
        category: 'searchselect'
      }
    ]);

    Survey.JsonObject.metaData.addClass(
      'searchselect',
      [],
      undefined,
      'dropdown'
    );
  },
  isDefaultRender: false,
  htmlTemplate: '<div><select><option></option></select></div>',
  render: (
    question: SearchSelectQuestionType
  ): React.ReactElement<typeof SelectInput> => {
    const onChange = (newValue: string) => {
      question.value = newValue;
    };
    const { leftLabel, value, choices, placeHolder, helperText } = question;
    return (
      <SelectInput
        leftLabel={leftLabel}
        onChange={onChange}
        value={value}
        choices={choices}
        placeHolder={placeHolder}
        helperText={helperText}
      />
    );
  }
};

export default searchSelecttWidget;
