import './SelectInput.css';

import { Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

import { Choices, CustomWidth } from './SelectInputTypes';

interface SelectInputProps {
  leftLabel?: string;
  rightLabel?: string;
  customWidth?: CustomWidth;
  value?: string;
  name: string;
  options: Array<Choices>;
  onChange: (value: string) => void;
  helperText?: string;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    name,
    options,
    onChange,
    value,
    leftLabel,
    rightLabel,
    helperText
  } = props;
  const defaultValue = value || '';
  return (
    <div
      className="input-field-container SelectInput"
      data-testid="SelectInput"
    >
      <div>
        {leftLabel || ''}{' '}
        {helperText ? (
          <Tooltip
            title={helperText}
            arrow
            classes={{
              arrow: 'text-tertiary',
              tooltip: 'text-sm bg-tertiary'
            }}
            enterTouchDelay={1}
          >
            <div className="help-button">?</div>
          </Tooltip>
        ) : null}
      </div>
      <Select
        className="custom-select"
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        name={name}
        value={defaultValue}
        IconComponent={() => <ExpandMore />}
        disableUnderline
      >
        {options
          ? options.map((p) => (
              <MenuItem
                className="SelectInput"
                key={p.text}
                value={p.value}
                style={{
                  paddingTop: 0,
                  borderRadius: 0,
                  backgroundColor: 'white',
                  borderColor: '#403D39'
                }}
              >
                <p className="">{p.text}</p>
              </MenuItem>
            ))
          : null}
      </Select>
      {rightLabel || ''}
    </div>
  );
};

export default SelectInput;
