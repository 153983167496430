/* eslint-disable @typescript-eslint/no-explicit-any */
import { matrixDropdownColumnTypes } from 'survey-core';

const newMatrixDropdownColumnTypes = matrixDropdownColumnTypes as Record<
  string,
  any
>;
newMatrixDropdownColumnTypes.searchselect = {
  properties: ['choices', 'choicesOrder', 'choicesByUrl'],
  onCellQuestionUpdate: function (
    cellQuestion: any,
    column: any,
    question: any,
    data: any
  ) {
    newMatrixDropdownColumnTypes.dropdown.onCellQuestionUpdate(
      cellQuestion,
      column,
      question,
      data
    );
  }
};
