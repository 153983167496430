import './FeinInputBox.css';

import HelpIcon from '@material-ui/icons/Help';
import React, { useState } from 'react';

import {
  AmtrustEligibleQuoteResponse,
  UpdateCoverageInput
} from '../../../../generated/graphql';
import Popup from './Popup';

interface FeinInputBoxProps {
  amtrustQuoteInput: AmtrustEligibleQuoteResponse;
  onUpdateCoverage: (input: UpdateCoverageInput) => void;
}

const FeinInputBox: React.FC<FeinInputBoxProps> = (props) => {
  const { amtrustQuoteInput, onUpdateCoverage } = props;

  const [fein, setFein] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const closePopup = () => {
    setShowPopup(false);
  };

  const {
    limit: { overallIllness, perAccident, perEmployeeIllness },
    amtrustquoteId
  } = amtrustQuoteInput;
  return (
    <div className="test-container text-center mb-4 mt-6">
      <p className="text-tertiary font-bold text-lg sm:text-base">
        Please enter a valid FEIN/SSN to proceed further.
      </p>
      <form
        className="flex flex-row justify-center items-center text-lg sm:text-base sm:flex-col"
        onSubmit={async (e) => {
          e.preventDefault();
          await onUpdateCoverage({
            amtrustInput: {
              wcLimit: {
                overallIllness: overallIllness,
                perAccident: perAccident,
                perEmployeeIllness: perEmployeeIllness
              },
              updatedFein: fein,
              wcQuoteId: amtrustquoteId
            }
          });
        }}
      >
        <div className="flex flex-row mr-5 sm:mr-0">
          <p className="pr-1 font-bold">FEIN/SSN:</p>
          <HelpIcon
            className="cursor-pointer mt-0.5 text-tertiary"
            onClick={() => setShowPopup(true)}
          />
        </div>
        <input
          className="w-64 mt-1 sm:w-30"
          type="text"
          value={fein}
          onChange={(e) => setFein(e.target.value)}
          minLength={9}
          maxLength={9}
          required={true}
        />
        <button
          type="submit"
          value="Submit"
          className="ml-5 mt-1 pl-4 pr-4 pt-1 pb-1 text-white font-semibold bg-tertiary sm:ml-0"
        >
          Update
        </button>
      </form>
      {showPopup ? <Popup closeAction={closePopup} /> : null}
    </div>
  );
};

export default FeinInputBox;
