import './AdditionalInsured.css';

import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Address from '../../../../components/Address/Address';
import Input from '../../../../components/Input/Input';
import {
  AdditionalInsured as AdditionalInsuredInfo,
  UpdateCoverageInput
} from '../../../../generated/rating-quoting-graphql';
import { CoveragesDataInput } from '../..';
import {} from '../../Quote';
import usStateNationalCoveragesDetails from '../Coverages/usStateNationalCoveragesDetails.json';

interface AdditionalInsuredCoverageProps {
  coverageData: CoveragesDataInput;
  onUpdateCoverage: (input: UpdateCoverageInput) => void;
  region: string;
  quoteId: string;
  applicationId: string;
  additionalInsuredInfoList: AdditionalInsuredInfo[] | undefined;
  setAdditionalInsuredInfoList: React.Dispatch<
    React.SetStateAction<AdditionalInsuredInfo[] | undefined>
  >;
  fillAllAdditionalInsuredInfo: boolean | undefined;
  setFillAllAdditionalInsuredInfo: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  disabled: boolean;
}

const AdditionalInsured: React.FC<AdditionalInsuredCoverageProps> = (props) => {
  const {
    coverageData,
    onUpdateCoverage,
    region,
    quoteId,
    applicationId,
    additionalInsuredInfoList,
    setAdditionalInsuredInfoList,
    fillAllAdditionalInsuredInfo,
    setFillAllAdditionalInsuredInfo,
    disabled
  } = props;
  const { chooseCoverage: chooseAdditionalInsured } = coverageData;
  return (
    <div
      className="additional-insured-container mt-2 md:mt-0"
      id="additional-insured-container"
    >
      <div
        className={`${
          chooseAdditionalInsured
            ? 'additional-insured-header-check'
            : 'additional-insured-header-uncheck'
        } flex flox-col bg-secondary-light px-8 py-4 text-2xl text-quartary`}
      >
        <input
          type="checkbox"
          data-name="addtional-insured-checkbox"
          className="flex justify-center items-center w-10 h-10 mt-2 col-span-1"
          checked={chooseAdditionalInsured}
          disabled={disabled}
          onClick={() =>
            onUpdateCoverage({
              munichInput: {
                quoteId,
                applicationId,
                region,
                coverage: {
                  name: 'additionalInsured',
                  chooseCoverage: !chooseAdditionalInsured,
                  limits: { noLimit: 0 }
                }
              }
            })
          }
        />
        <div className="ml-6">
          <p className="font-bold">Additional Insured - Completed Operations</p>
          <p className="text-base">
            Owners, Lessees or Contractors - Completed Operations (CG 20 37)
          </p>
        </div>
      </div>
      {chooseAdditionalInsured ? (
        <div className="additional-insured-innner-container px-8 pt-4 pb-8 md:mb-28 lg:mb-0 flex flex-col gap-6">
          <div className="coverages-details overflow-hidden lg:overflow-auto max-h-full">
            <span className="coverage-description text-gray-700 mt-2 mb-2 text-xs md:text-base inline-block">
              {usStateNationalCoveragesDetails.additionalInsured.subTitle &&
                ReactHtmlParser(
                  usStateNationalCoveragesDetails.additionalInsured.subTitle
                )}
            </span>
          </div>
          {fillAllAdditionalInsuredInfo === false ? (
            <span className="text-red-500 font-bold">
              Additional insured endorsement CG2037 is selected. Please provide
              additional insured information or deselect to proceed.
            </span>
          ) : null}
          {additionalInsuredInfoList?.map((info, infoIndex) => (
            <div key={infoIndex}>
              <Input
                leftLabel="Name of additional insured person or organization"
                onChange={(value) => {
                  setFillAllAdditionalInsuredInfo(undefined);
                  const newList = [...additionalInsuredInfoList];
                  newList[infoIndex].name = value;
                  setAdditionalInsuredInfoList(newList);
                }}
                value={info.name || ''}
              />
              <br />
              <Address
                title="Address"
                onChange={(value) => {
                  setFillAllAdditionalInsuredInfo(undefined);
                  const newList = [...additionalInsuredInfoList];
                  newList[infoIndex].address = value;
                  setAdditionalInsuredInfoList(newList);
                }}
                value={info.address || undefined}
                preventStateOrProvinceChange={false}
                country="US"
                province=""
                filterProvince={false}
              />
              <br />
              <Input
                leftLabel="Additional insured contact email"
                onChange={(value) => {
                  setFillAllAdditionalInsuredInfo(undefined);
                  const newList = [...additionalInsuredInfoList];
                  newList[infoIndex].email = value;
                  setAdditionalInsuredInfoList(newList);
                }}
                value={info.email || ''}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default AdditionalInsured;
