import React from 'react';

interface ProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  // NOTE: When the user first enters the app, we want the progress bar initially filled to 25% since the minimum total page is 4 so it will progress naturally to the next percentage
  // We cannot get the minimum total page on our app so we are hard coding the 25% for now
  const progressBarWidth = percentage == 0 ? 25 : percentage;
  return (
    <div className="flex flex-col w-full sm:order-last">
      <div
        className="border-2 border-tertiary"
        style={{ width: `${progressBarWidth}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
