import './ReturnToQuotePopup.css';

import { addDays, formatISO, startOfDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button/Button';
import CustomDatePicker from '../../../../components/DatePicker/DatePicker';
import Loading from '../../../../components/Loading/Loading';
import Modal from '../../../../components/Modal/Modal';
import { useUpdateEffectiveDateMutation } from '../../../../generated/graphql';
import { GetQuoteExpired } from '../../../../generated/rating-quoting-graphql';
import { ErrorPage } from '../../..';

interface Props {
  isQuoteExpired: boolean;
  applicationId: string;
  returnToQuoteExpired: GetQuoteExpired;
  closeAction: () => void;
}

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);

export const ReturnToQuotePopup: React.FC<Props> = (props) => {
  const {
    isQuoteExpired,
    applicationId,
    closeAction,
    returnToQuoteExpired
  } = props;

  const { quoteExpireDate } = returnToQuoteExpired;

  const { allowDatePickerToPickDateUpToSixtyDays } = useFlags();
  const quoteExpiredMessage = isQuoteExpired
    ? 'Your Quote has expired, please submit a new application'
    : 'The previously requested effective date is no longer valid. \n Please choose a new date:';

  const [effectiveDate, setNewEffectiveDate] = useState<undefined | Date>(
    undefined
  );
  const [isEffectiveDateValid, setisEffectiveDateValid] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [
    isStartNewApplicationDisabled,
    setisStartNewApplicationDisabled
  ] = useState(!isQuoteExpired);

  const [
    updateEffectiveDateMutation,
    { loading: isUpdateEffectiveDateLoading }
  ] = useUpdateEffectiveDateMutation();
  const [isVerifyingEffectiveDate, setIsVerifyingEffectiveDate] = useState(
    false
  );

  const continueAction = async () => {
    // condition to prevent user from clicking continue
    // when they have invalid effective date
    if (effectiveDate && effectiveDate >= today) {
      setIsVerifyingEffectiveDate(true);
      const {
        data: updateEffectiveDateData
      } = await updateEffectiveDateMutation({
        variables: {
          applicationId: applicationId,
          newEffectiveDate: formatISO(startOfDay(effectiveDate), {
            representation: 'date'
          })
        }
      });
      if (!updateEffectiveDateData) {
        throw new Error('updateEffectiveDateData should be ready');
      }
      if (
        updateEffectiveDateData &&
        updateEffectiveDateData.updateEffectiveDate === false
      ) {
        throw new Error('Update not successful');
      }
      closeAction();
    } else if (!effectiveDate) {
      setisEffectiveDateValid(false);
    }
  };

  if (!quoteExpireDate) {
    return <ErrorPage message={'quoteExpireDate does not exist'} />;
  }

  const quoteExpireDateObject = new Date(quoteExpireDate);

  const checkEffectiveDate = (effectiveDate: Date | null) => {
    if (effectiveDate) {
      setNewEffectiveDate(effectiveDate);
      setisEffectiveDateValid(true);
      if (effectiveDate > quoteExpireDateObject) {
        setIsContinueDisabled(true);
        setisStartNewApplicationDisabled(false);
      } else {
        setIsContinueDisabled(false);
        setisStartNewApplicationDisabled(true);
      }
    } else {
      setisEffectiveDateValid(false);
    }
  };

  if (isUpdateEffectiveDateLoading || isVerifyingEffectiveDate) {
    return (
      <div className="modal">
        <div className="modal-content loading">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <Modal closeAction={undefined} onAction={undefined}>
      <div className="ReturnToQuotePopup">
        <p className="quoteExpired mb-4">{quoteExpiredMessage}</p>
        {!isQuoteExpired ? (
          <div className="effectiveDateExpired">
            <CustomDatePicker
              id="SelectEffectiveDate"
              leftLabel={'When would you like your new coverage to start?'}
              rightLabel={''}
              onChange={checkEffectiveDate}
              name={'calendar'}
              value={effectiveDate}
              minDate={today}
              maxDate={
                allowDatePickerToPickDateUpToSixtyDays
                  ? addDays(today, 60)
                  : addDays(today, 30)
              }
              helperText={''}
              readOnly={false}
            />
            {!isEffectiveDateValid ? (
              <p className="text-base text-red-600 mt-2 mb-0">
                Please select a valid date.
              </p>
            ) : null}

            <p className="mt-4 mx-auto">
              If the new requested coverage start date selected is after
              <br></br>{' '}
              {formatISO(quoteExpireDateObject, { representation: 'date' })}, a
              new application must be submitted.
            </p>
          </div>
        ) : null}
        <div className="flex flex-col align-center">
          {!isQuoteExpired ? (
            <Button
              id="ContinueButton"
              className="inline-block bg-tertiary text-base uppercase pointer font-bold mt-2 mx-auto w-2/5 px-6 py-3 font-raleway sm:text-mobileFont rounded text-white"
              onClick={continueAction}
              disabled={isContinueDisabled}
              buttonText="Continue"
            />
          ) : null}
          <Link
            id="NewApplicationButton"
            className={`${
              isStartNewApplicationDisabled ? 'disabled-link' : ''
            } inline-block text-base uppercase pointer mt-4 px-6 py-3 font-raleway sm:text-mobileFont rounded no-underline hover:underline hover:text-blue-700`}
            to={'/'}
          >
            Start New Application
          </Link>
        </div>
      </div>
    </Modal>
  );
};
