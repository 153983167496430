export const formatCADCentsOptional = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  minimumFractionDigits: 0
});

export const formatCADCentsOptionalWithTwoDecimals = new Intl.NumberFormat(
  'en-CA',
  {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2
  }
);
