import { assign, omit } from 'lodash';
import React, { Fragment, useState } from 'react';

import {
  MunichEligibleQuoteResponse,
  MunichIneligibleQuoteResponse
} from '../../../../generated/graphql';
import { formatCADCentsOptional } from '../../../../utils/currencyFormat';
import { limitsLabels } from '../Coverages/CoveragesDetails';
import { coveragesTitles } from './CoveragesTitles';

interface SummaryCoverageParams {
  name: string;
  chooseCoverage: boolean;
  finalPremium: number;
  limits: Record<string, number>;
  munichSummaryInput?:
    | MunichEligibleQuoteResponse
    | MunichIneligibleQuoteResponse;
  zeroPremiumText: string;
  carrierPartner?: string;
}

const SummaryCoverage: React.FC<SummaryCoverageParams> = ({
  name,
  chooseCoverage,
  finalPremium,
  limits,
  munichSummaryInput,
  zeroPremiumText,
  carrierPartner
}) => {
  const [displayLimits, setDisplayLimits] = useState<boolean>(false);

  // TODO: Move some of these hardcoded CGL limit information to the backend
  const additionalCGLLimits =
    name === 'cgl'
      ? {
          productAggregateLimit: limits['aggregateLimit'],
          personalAdvertisingInjury: limits['occurrenceLimit'],
          ...(limits['tenantLegalLiabilityLimit']
            ? { tenantLegalLiabilityLimit: limits['tenantLegalLiabilityLimit'] }
            : undefined),
          ...(limits['premisesRentedToYouLimit']
            ? { premisesRentedToYouLimit: limits['premisesRentedToYouLimit'] }
            : undefined),
          medicalPaymentsLimit: limits['medicalPaymentsLimit'],
          deductible: limits['deductible'],
          blanketAdditionalInsured: 'INCLUDED',
          blanketWaiverOfSubrogation: 'INCLUDED',
          ...(carrierPartner === 'StateNational' && {
            primaryAndNonContributory: 'INCLUDED'
          })
        }
      : {};

  // Re-order structure if CGL
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredSummaryLimitsObject: Record<string, any> =
    name === 'cgl'
      ? assign(
          omit(limits, [
            'tenantLegalLiabilityLimit',
            'premisesRentedToYouLimit',
            'medicalPaymentsLimit',
            'deductible'
          ]),
          additionalCGLLimits
        )
      : limits;

  if (
    !munichSummaryInput ||
    munichSummaryInput?.__typename !== 'MunichEligibleQuoteResponse'
  ) {
    return null;
  }

  return chooseCoverage ? (
    <div>
      {name === 'mobileEquipment' || name === 'contractorsMobileEquipment' ? (
        <div className="mb-4 mt-6">
          <h2 className="text-navy mt-2 text-lg md:text-base">
            <b>{coveragesTitles[name]}</b>
          </h2>
          {finalPremium !== 0 ? (
            <div className="mb-2 ml-4">
              <div className="flex justify-between">
                <b className="text-navy">
                  - Additional Charge to Meet Minimum Premium
                </b>
                <span className="text-xl">
                  <span className="font-black">
                    {formatCADCentsOptional.format(finalPremium)}
                  </span>
                  /year
                </span>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="mb-4">
          <div className="flex justify-between">
            {name === 'tria' ? (
              <b className="text-navy mt-2 text-lg md:text-xl ">
                {coveragesTitles[name]}
              </b>
            ) : (
              <b className="text-navy">{coveragesTitles[name]}</b>
            )}
            <span className="text-xl">
              {finalPremium === 0 ? (
                <Fragment>{zeroPremiumText}</Fragment>
              ) : (
                <Fragment>
                  <span className="font-black">
                    {formatCADCentsOptional.format(finalPremium)}
                  </span>
                  /year
                </Fragment>
              )}
            </span>
          </div>
          {(name === 'tria' || name === 'additionalInsured') &&
          carrierPartner === 'StateNational' ? null : (
            <>
              <div
                className="w-auto inline-block ml-2 cursor-pointer border-black border-b mb-1"
                onClick={() => setDisplayLimits(!displayLimits)}
              >
                <span className="font-semibold">Coverage Limits</span>
                <span className="inline-block fill-secondary">
                  {displayLimits ? (
                    <svg
                      className="MuiSelect-iconOpen h-5 -mb-1"
                      focusable="false"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7 10l5 5 5-5z"></path>
                    </svg>
                  ) : (
                    <svg
                      className="h-5 -mb-1"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M7 10l5 5 5-5z"></path>
                    </svg>
                  )}
                </span>
              </div>
              {displayLimits
                ? Object.keys(filteredSummaryLimitsObject).map((limitName) => (
                    <div key={limitName} className="text-sm md:text-base ml-2">
                      <div className="grid grid-cols-3">
                        <div className="col-span-1">
                          {filteredSummaryLimitsObject[limitName] !== 'INCLUDED'
                            ? formatCADCentsOptional.format(
                                filteredSummaryLimitsObject[limitName]
                              )
                            : filteredSummaryLimitsObject[limitName]}
                        </div>
                        <div className="col-span-2">
                          {name === ('miscellaneousEO' || 'contractorsEO') &&
                          limitName === 'occurrenceLimit'
                            ? 'Each Wrongful Act'
                            : limitsLabels[limitName]}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default SummaryCoverage;
