import './ModalWithHeader.css';

import React from 'react';

interface ModalWithHeaderProps {
  onBackgroundClick?: () => void;
  children?: React.ReactNode;
  headerText?: string;
  additionalRootStyleClasses?: string;
}

const ModalWithHeader: React.FC<ModalWithHeaderProps> = (props) => {
  const {
    onBackgroundClick,
    children,
    headerText,
    additionalRootStyleClasses
  } = props;
  return (
    <div
      data-testid="ModalWithHeader"
      className={`fixed flex items-center justify-center bg-black bg-opacity-50 w-full h-full overflow-auto left-0 top-0 ${
        additionalRootStyleClasses ?? ''
      }`}
      onClick={onBackgroundClick}
    >
      <div
        className="top-1/2 bottom-1/2 w-5/6 md:w-1/2"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-top-corners-rounded bg-secondary-light px-6 py-2 font-semibold text-2xl text-quartary border-b border-faded text-left">
          {headerText}
        </div>
        <div className="modal-bottom-corners-rounded text-mainFont bg-white border-2 border-white text-left p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWithHeader;
