import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React from 'react';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 28,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        background: 'radial-gradient(#46b2bb 2px, transparent 0)',
        backgroundSize: '8px 8px',
        opacity: 1,
        border: `3px solid #46b2bb`
      }
    },
    '&$focusVisible $thumb': {
      color: '#46b2bb',
      border: '6px solid white'
    }
  },
  thumb: {
    width: 26,
    height: 26,
    border: '3px solid #46b2bb'
  },
  track: {
    borderRadius: 28 / 2,
    border: `3px solid #46b2bb`,
    backgroundColor: '#46b2bb',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {
    right: 0
  }
}))(Switch);

interface BooleanProps {
  onChange: (value: boolean) => void;
  value: boolean;
  id?: string;
}
const Boolean: React.FC<BooleanProps> = ({ onChange, value, id }) => {
  return (
    <CustomSwitch
      id={id}
      checked={value}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
    />
  );
};

export default Boolean;
