import { useFlags as useFlagsBase } from 'launchdarkly-react-client-sdk';
import { z } from 'zod';

const flagsSchema = z.object({
  amTrustMaster: z.boolean(),
  amTrustFullFlow: z.boolean(),
  emailQuoteLink: z.boolean(),
  renewalFlow: z.boolean(),
  allowDatePickerToPickDateUpToSixtyDays: z.boolean()
});

export type Flags = z.infer<typeof flagsSchema>;

export const useFlags = (): Flags => {
  const flags = useFlagsBase();
  const parsed = flagsSchema.parse(flags);
  return {
    ...parsed,
    amTrustFullFlow: parsed.amTrustMaster && parsed.amTrustFullFlow
  };
};
