import { formatISO, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import QueryString from 'qs';
import { QuestionSelectBase, SurveyModel } from 'survey-core';

interface Choice {
  value: string;
  text: string;
}

export const prefillValueByQueryString = (
  model: SurveyModel,
  questionName: string,
  label:
    | string
    | QueryString.ParsedQs
    | string[]
    | QueryString.ParsedQs[]
    | undefined
): void => {
  if (label && typeof label === 'string') {
    const question = model.getQuestionByName(questionName);
    if (question) {
      const convertedQuestion = question as QuestionSelectBase;
      let prefillValue: string | Date = convertedQuestion.choices
        ? getValueFromChoicesByLabel(label, convertedQuestion)
        : label;
      if (questionName === 'BusinessInformation_100_EffectiveDate_WORLD_EN') {
        prefillValue = utcToZonedTime(
          label,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
      model.setValue(questionName, prefillValue);
    }
  }
  return;
};

export const getValueFromChoicesByLabel = (
  label: string,
  question: QuestionSelectBase
): string => {
  const choicesList = question.choices;
  const choice = choicesList.find(({ text }: Choice) => {
    return text === label;
  });
  return choice?.value ?? '';
};

export const getLabelFromChoicesByValue = (
  choiceValue: string,
  question: QuestionSelectBase
): string => {
  const choicesList = question.choices;
  const choice = choicesList.find(({ value }: Choice) => value === choiceValue);
  return choice?.text ?? '';
};

export const getLabelFromChoicesByQuestion = (
  question?: QuestionSelectBase
): unknown => {
  if (!question) {
    throw new Error('question not found');
  }
  const value = question.value;
  if (question.choices?.length > 0) {
    return getLabelFromChoicesByValue(value as string, question);
  }
  return value;
};

export const getLabelFromChoices = (
  selectedValue: string,
  choicesList: Choice[]
): string => {
  const choice = choicesList.find(({ value }: Choice) => {
    return selectedValue === value;
  });
  return choice?.text ?? '';
};

export const formatIsoDateString = (isoDateString: string): string => {
  // formatISO will use local timezone, so the return string is locale date string
  return formatISO(parseISO(isoDateString), { representation: 'date' });
};

export const formatDateAnswers = (data: Record<string, unknown>): void => {
  const dateQuestionsList = [
    'BusinessInformation_100_EffectiveDate_WORLD_EN',
    'BusinessInformation_100_ExistingPolicyExpiry_WORLD_EN',
    'Amtrust_PersonalServices_0001_ExpirationDate_WORLD_EN'
  ];
  dateQuestionsList.forEach((questionName: string) => {
    const questionValue = data[questionName];
    if (questionValue) {
      if (typeof questionValue !== 'string') {
        throw Error('Invalid date string format');
      }
      data[questionName] = formatIsoDateString(questionValue);
    }
  });
};
