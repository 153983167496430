import './BrokerComplete.css';

import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router';

import ContactBar from '../../components/ContactBar/ContactBar';
import { Context, ContextValue } from '../../utils/store';

interface AddressParams {
  country: string;
  province: string;
}

const BrokerComplete: React.FC = () => {
  const context: ContextValue | null = useContext(Context);
  context?.setProgress?.(100);

  const location = useLocation();

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (location?.state) {
      const { country, province } = location.state as AddressParams;
      const tagManagerArgs = {
        dataLayer: {
          event: 'form-progress',
          type: 'commercial',
          country: country,
          'state/province': province,
          step: 'Broker_Thankyou'
        }
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [location?.state]);

  return (
    <div className="page-container" data-testid="broker-complete">
      <div className="bubble mt-20 mx-4 text-mobileFont md:text-base">
        <div>
          Thanks for starting an application for business insurance with
          Foxquilt. We&apos;re glad you&apos;re here!
        </div>
        <br />
        <div>
          If you are a business looking for a quote, a Foxquilt Agent is
          reviewing your information and will be in touch within 1 business day
          to talk about your business. Our business hours are Monday to Friday
          8am to 5:30pm Eastern Time.
        </div>
        <br />
        <div>
          If you were referred to Foxquilt from an Insurance Partner, please
          reach out to them directly for help with your quote.
        </div>
        <br />
        <div>
          If you are an Agent Partner of Foxquilt&apos;s, please log in or
          return to Foxden to review this application. If you require further
          assistance, please reach out to the Agent Support team.
        </div>
      </div>
      <ContactBar
        msg="Want to review your quote first with one of our Foxquilt insurance
      professionals?"
      />
    </div>
  );
};

export default BrokerComplete;
