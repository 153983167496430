import { z } from 'zod';

import { WcAvailableLimit } from '../../generated/graphql';

export const limitSchema = z
  .string()
  .regex(/^\d+\/\d+\/\d+$/)
  .transform((arg) => arg.split('/').map((limit) => Number(limit)));

export const translateStringToLimit = (rawLimit: string): WcAvailableLimit => {
  const limits = limitSchema.parse(rawLimit);

  if (limits.length !== 3) {
    throw new Error('Unreachable - unexpected rawLimits');
  }

  return {
    perAccident: limits[0],
    overallIllness: limits[1],
    perEmployeeIllness: limits[2]
  };
};
