import './SearchSelect.css';

import { TextField, Tooltip } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useState } from 'react';

import { getLabelFromChoices } from '../../utils/SurveyJSQuestion';
import { Choice } from './SearchSelectTypes';

export interface SearchSelectProps {
  onChange: (value: string) => void;
  value?: string;
  choices: Array<Choice>;
  leftLabel?: string;
  helperText?: string;
  placeHolder?: string;
}

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
  const {
    onChange,
    value,
    choices,
    leftLabel,
    helperText,
    placeHolder
  } = props;

  const [selectedValue, setSelectedValue] = useState(value || '');

  const sortedChoices = useMemo(() => {
    return sortBy(choices, ['text']);
  }, [choices]);

  const selectedLabel = useMemo(() => {
    if (selectedValue) {
      return getLabelFromChoices(selectedValue, choices);
    }
    return '';
  }, [selectedValue, choices]);

  const selectedChoice = { value: selectedValue, text: selectedLabel };

  return (
    <div
      className="input-field-container SearchSelect"
      data-testid="SearchSelect"
    >
      <div className="mb-2 md:mb-4">
        {leftLabel || ''}
        {helperText ? (
          <Tooltip
            title={helperText}
            arrow
            classes={{
              arrow: 'text-tertiary',
              tooltip: 'text-sm bg-tertiary'
            }}
            enterTouchDelay={1}
          >
            <div className="help-button">?</div>
          </Tooltip>
        ) : null}
      </div>
      <Autocomplete
        id="searchselect"
        options={sortedChoices}
        getOptionLabel={(choice) => choice.text}
        value={selectedChoice}
        popupIcon={<ExpandMore />}
        onChange={(e, newValue) => {
          if (newValue) {
            setSelectedValue(newValue.value);
            onChange(newValue.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={!value ? placeHolder : undefined}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

export default SearchSelect;
